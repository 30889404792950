import { ResponseBase } from '@map/core';

/**
 * A message which can be used to retrieve jwt response from device
 */
export class AuthenticatorResponse extends ResponseBase {

  /**
   * Token that is only decoded in the native layer.
   */
  public jwt!: string;
}
