import { LogLevel } from './LogLevel';
import { BaseLogger } from './BaseLogger';
import { LogMessage } from './LogMessage';
import { IMessenger } from '../Messaging/IMessenger';

/**
 * A logger which sends all events down to the native client.
 */
export class MessageLogger extends BaseLogger {
  constructor(private messenger: IMessenger) {
    super();
  }

  readonly log = (message: string, level: LogLevel) => {
    this.messenger.send(new LogMessage(level, message));
  }
}
