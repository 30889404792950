import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, alpha, Theme, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';
import { selectEbkChService, selectEbkChR20Service } from 'features/entities/entitiesSelectors';
import { openExternalServiceStart } from 'features/extNavigation/extNavigationSlice';

import { scanConnectQRStart } from './paymentScannerSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontStyle: 'normal',
      fontWeight: theme.typography.fontWeightLight,
      fontSize: 24,
      lineHeight: '28px',
      letterSpacing: '-0.2px',
    },

    subtitle: {
      marginTop: theme.spacing(4),
      fontWeight: 'bold',
      fontSize: 14,
      lineHeight: '24px',
    },

    button: {
      marginTop: theme.spacing(4),
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      minWidth: theme.spacing(8),
    },

    list: {
      marginTop: theme.spacing(1),
      marginBottom: 0,
      paddingLeft: theme.spacing(2),
      lineHeight: '20px',
      '& li': {
        marginTop: theme.spacing(2),
      },
    },

    hr: {
      marginTop: theme.spacing(4),
      marginBottom: 0,
      border: 'none',
      borderTop: `1px solid ${alpha(theme.palette.common.white, 0.4)}`,
    },
  })
);

export const PaymentScannerInstructions = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const ebkChService = useSelector(selectEbkChService);
  const ebkChR20Service = useSelector(selectEbkChR20Service);

  const handleScanQRCode = () => {
    dispatch(scanConnectQRStart());
  };

  const handleLoginEbanking = () => {
    const service = ebkChR20Service ?? ebkChService;
    dispatch(openExternalServiceStart(service!));
  };

  return (
    <Box maxWidth={472} mx="auto" mt={2.5} p={3}>
      <Typography variant="h1" className={classes.title}>
        {t('paymentScanner.title')}
      </Typography>

      <Typography variant="h2" className={classes.subtitle}>
        {t('paymentScanner.labels.desktop')}
      </Typography>

      <ol className={classes.list}>
        <li>{t('paymentScanner.steps.desktop.one')}</li>
        <li>{t('paymentScanner.steps.desktop.two')}</li>
        <li>{t('paymentScanner.steps.desktop.three')}</li>
        <li>
          {t('paymentScanner.steps.desktop.four', {
            buttonName: t('paymentScanner.actions.scanQRCode'),
          })}
        </li>
        <li>{t('paymentScanner.steps.desktop.five')}</li>
      </ol>

      <Button variant="outlined" className={classes.button} onClick={handleScanQRCode}>
        {t('paymentScanner.actions.scanQRCode')}
      </Button>

      {(ebkChService || ebkChR20Service) && (
        <>
          <hr className={classes.hr} />

          <Typography variant="h2" className={classes.subtitle}>
            {t('paymentScanner.labels.mobile')}
          </Typography>

          <ol className={classes.list}>
            <li>{t('paymentScanner.steps.mobile.one')}</li>
            <li>{t('paymentScanner.steps.mobile.two')}</li>
            <li>{t('paymentScanner.steps.mobile.three')}</li>
            <li>{t('paymentScanner.steps.mobile.four')}</li>
          </ol>

          <Button variant="outlined" className={classes.button} onClick={handleLoginEbanking}>
            {t('paymentScanner.actions.loginMobileBanking')}
          </Button>
        </>
      )}
    </Box>
  );
};
