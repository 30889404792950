import { AnyAction } from '@reduxjs/toolkit';
import { Epic } from 'redux-observable';
import { filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import { RootState } from 'app/redux/rootReducer';

import { fetchDashboardDataSuccess } from 'features/entities/entitiesSlice';
import { menuGroupsCreated } from './menuSlice';
import { createAccountMenuGroups, createServicesMenuGroups } from './menuGroups';

export const menuFetchDashboardDataSuccessEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(fetchDashboardDataSuccess.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const settingsGroups = createAccountMenuGroups(state);
      const servicesGroups = createServicesMenuGroups(state);

      return [menuGroupsCreated({ settingsGroups, servicesGroups })];
    })
  );
