import { buttonClasses } from '@mui/material';
import { jbColors } from '../jbColors';

const MuiButton = {
  styleOverrides: {
    /* Styles applied to the root element. */
    root: {
      borderRadius: 'initial' as const,
      padding: '11px 36px',
      letterSpacing: '0.15em',
      lineHeight: 1.5,
    },
    /* Styles applied to the root element if `variant="contained"` and `color="primary"`. */
    containedPrimary: {
      [`&.${buttonClasses.disabled}`]: {
        color: jbColors.common.white,
        backgroundColor: jbColors.support.royalBlue,
        opacity: 0.6,
      },
    },
    /* Styles applied to the root element if `variant="contained"` and `color="secondary"`. */
    containedSecondary: {
      [`&.${buttonClasses.disabled}`]: {
        color: jbColors.common.white,
        backgroundColor: jbColors.core.stone,
        opacity: 0.6,
      },
    },
    /* Styles applied to the root element if `size="large"` and `variant="contained"`. */
    containedSizeLarge: {
      padding: '16px 19px',
      fontSize: 12,
    },
    /* Styles applied to the root element if `variant="outlined"`. */
    outlined: {
      padding: '10px 15px',
      minWidth: 216, // 8 * 27
      '@media (min-width: 481px)': {
        minWidth: 312, // 8 * 39
      },
    },
    /* Styles applied to the root element if `variant="outlined"` and `color="secondary"`. */
    outlinedSecondary: {
      border: `1px solid ${jbColors.common.white}`,
    },
    /* Styles applied to the root element if `size="large"` and `variant="outlined"`. */
    outlinedSizeLarge: {
      padding: '15px 19px',
      fontSize: 12,
    },
    /* Styles applied to the root element if `variant="text"`. */
    text: {
      padding: '6px 8px',
    },
  },
};

export { MuiButton };
