import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Button, alpha, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { Toolbar } from 'features/toolbar/Toolbar';
import { jbColors } from 'app/theme/jbColors';
import {
  selectGeneralContactNumber,
  selectServiceInfoGroups,
} from 'features/entities/entitiesSelectors';
import { ServiceInfoGroupTypes } from 'features/entities/entitiesTypes';

import background from 'common/assets/background-mountains.jpg';

import { ReactComponent as DownArrowIcon } from './down-arrow-icon.svg';
import { SupportCenterList } from './SupportCenterList';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
      position: 'relative',
      overflowY: 'auto',
    },

    toolbarContainer: {
      flex: '0 0 auto',
      color: theme.palette.common.white,
    },

    top: {
      backgroundImage: `url(${background})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center',
      backgroundAttachment: 'scroll',
      height: '60vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },

    topOverlay: {
      backgroundColor: alpha(theme.palette.common.black, 0.2),
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },

    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.3,
      textAlign: 'center',
      color: theme.palette.common.white,
      width: 271,
    },

    bottom: {
      backgroundColor: jbColors.app.almostWhite,
      color: theme.palette.primary.main,
    },

    generalLabel: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(12),
      color: theme.palette.text.primary,
      lineHeight: 1.67,
      letterSpacing: 1,
      textTransform: 'uppercase',
    },

    supportButton: {
      '&  .MuiButton-label': {
        opacity: 1,
        fontWeight: theme.typography.fontWeightBold,
        fontSize: theme.typography.pxToRem(12),
        color: theme.palette.text.primary,
        lineHeight: 1.67,
        letterSpacing: 1,
      },
    },

    generalLink: {
      display: 'block',
      marginTop: theme.spacing(0.75),
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.25,
      textAlign: 'center',
      textDecoration: 'none',
      color: theme.palette.primary.main,
    },

    numberLink: {
      color: 'inherit',
      textDecoration: 'none',
      borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.3)}`,
    },
  })
);

export const ProspectContactView = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const supportCenterListRef = useRef<HTMLDivElement>(null);

  // general number has a special style
  const generalContactNumber = useSelector(selectGeneralContactNumber);
  const serviceInfoGroups = useSelector(selectServiceInfoGroups).filter(
    (g) => g.groupType !== ServiceInfoGroupTypes.GENERAL
  );

  const handleSupportClick = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (!isExpanded) {
      containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      supportCenterListRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isExpanded]);

  return (
    <div className={classes.container} ref={containerRef}>
      <div className={classes.top}>
        <div className={classes.topOverlay}>
          <div className={classes.toolbarContainer}>
            <Toolbar />
          </div>

          <Box display="flex" flex="1" justifyContent="center" alignItems="flex-end" pb="12.6vh">
            <Typography variant="h1" className={classes.title}>
              {t('contact.title')}
            </Typography>
          </Box>
        </div>
      </div>

      <div className={classes.bottom}>
        {generalContactNumber && (
          <Box
            textAlign="center"
            height="calc(40vh - 56px)"
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
          >
            <Box>
              <Typography variant="h2" className={classes.generalLabel}>
                {t('contact.labels.contact')}
              </Typography>
              <a className={classes.numberLink} href={`tel:${generalContactNumber}`}>
                {generalContactNumber}
              </a>
            </Box>

            {serviceInfoGroups.length > 0 && (
              <Box textAlign="center">
                <Button
                  variant="text"
                  endIcon={
                    <DownArrowIcon
                      style={{ transform: isExpanded ? 'rotate(180deg)' : 'rotate(0)' }}
                    />
                  }
                  onClick={handleSupportClick}
                  className={classes.supportButton}
                >
                  {t('contact.actions.support')}
                </Button>
              </Box>
            )}
          </Box>
        )}

        <Box mx="auto" maxWidth={695}>
          <SupportCenterList ref={supportCenterListRef} />
        </Box>
      </div>
    </div>
  );
};
