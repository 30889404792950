import {MessageBase} from './MessageBase';

export abstract class RequestMessage extends MessageBase {
  uniqueMessageId: string = RequestMessage.generateUniqueId();

  static readonly generateUniqueId = (): string => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      // tslint:disable-next-line:no-bitwise
      const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}

export function isRequestMessage(message: MessageBase): message is RequestMessage {
  return typeof (message as RequestMessage).uniqueMessageId === 'string';
}