import { RequestMessage } from '@map/core';

export const openThirdPartyApplicationMessageTag = 'OpenThirdPartyApplication';

export class OpenThirdPartyApplicationMessage extends RequestMessage {
  readonly tag: string = openThirdPartyApplicationMessageTag;

  constructor(applicationName: string, payload: string) {
    super();
    this.applicationName = applicationName;
    this.payload = payload;
  }

  /**
   * The application to be opened.
   */
  applicationName: string;

  /**
   * The payload for application data
   */
  payload: string;
}
