/**
 * A basic symbol which identifies a service or object in the IOC.
 */
export class Symbol {
  constructor(tag: string) {
    this.tag = tag;
  }

  toString = (): string => {
    return this.tag;
  }

  private tag: string;
}
