import { Platform } from '@map/core';

import { i18n } from 'app/i18n/i18n';
import {
  ActualSupportedBiometricMethod,
  ActualSupportedBiometricMethods,
  ServiceProvider,
} from 'features/entities/entitiesTypes';
import { EnumLiteralsOf } from 'common/types';
import { selectOSType, selectSupportedBiometricMethod } from 'features/session/sessionSelectors';
import { RootState } from 'app/redux/rootReducer';
import {
  selectBiometricService,
  selectBoundContractsService,
  selectChangePasswordService,
  selectShouldDisplaySupportMenu,
  selectJBWebsiteService,
  selectPaymentScannerService,
} from 'features/entities/entitiesSelectors';

const t = i18n.t.bind(i18n);

export type MenuEntryType = EnumLiteralsOf<typeof MenuEntryTypes>;

export const MenuEntryTypes = Object.freeze({
  Service: 'Service',
  Component: 'Component',
} as const);

export type ServiceSettingEntry = {
  label: string;
  type: MenuEntryType;
  service: ServiceProvider;
};

export type ComponentSettingEntry = {
  label: string;
  type: MenuEntryType;
  value: string;
};

export type SettingEntry = ServiceSettingEntry | ComponentSettingEntry;

export type MenuGroup = {
  title?: string;
  entries: SettingEntry[];
};
export const isServiceSettingEntry = (entry: SettingEntry): entry is ServiceSettingEntry =>
  (entry as ServiceSettingEntry).service !== undefined;

// Initial menu before we get any dashboard data
export const simpleMenuGroups = (): MenuGroup[] => [
  {
    title: t('menu.settings.device.title'),
    entries: [
      {
        label: t('menu.settings.device.labels.deviceProperties'),
        type: MenuEntryTypes.Component,
        value: 'DeviceProperties',
      },
    ],
  },
];

const getBiometricLabel = (
  osType: Platform,
  supportedBiometricMethod: ActualSupportedBiometricMethod
) => {
  if (osType === 'Android') {
    if (supportedBiometricMethod === ActualSupportedBiometricMethods.FaceId) {
      return t('menu.settings.login.labels.manageFaceRecognition');
    } else if (supportedBiometricMethod === ActualSupportedBiometricMethods.TouchId) {
      return t('menu.settings.login.labels.manageFingerprint');
    }
  } else if (osType === 'iOS') {
    if (supportedBiometricMethod === ActualSupportedBiometricMethods.FaceId) {
      return t('menu.settings.login.labels.manageFaceID');
    } else if (supportedBiometricMethod === ActualSupportedBiometricMethods.TouchId) {
      return t('menu.settings.login.labels.manageTouchID');
    }
  }
  return t('menu.settings.login.labels.manageBiometric');
};

/**
 * Creates the menuGroups based on the services
 * we receive from the backend (passed as an argument)
 */
export const createAccountMenuGroups = (state: RootState) => {
  const menuGroups: MenuGroup[] = [];

  const changePasswordService = selectChangePasswordService(state);
  const biometricService = selectBiometricService(state);
  const boundContractsService = selectBoundContractsService(state);

  // order is important in how menu show up in the menu

  if (changePasswordService || biometricService) {
    const entries: SettingEntry[] = [];

    if (biometricService) {
      const supportedBiometricMethod = selectSupportedBiometricMethod(state);
      const osType = selectOSType(state);

      if (
        supportedBiometricMethod !== ActualSupportedBiometricMethods.None &&
        osType !== 'Browser'
      ) {
        const label = getBiometricLabel(osType, supportedBiometricMethod);
        entries.push({
          label,
          type: MenuEntryTypes.Service,
          service: biometricService,
        });
      }
    }

    changePasswordService &&
      entries.push({
        label: t('menu.settings.login.labels.changePassword'),
        type: MenuEntryTypes.Service,
        service: changePasswordService,
      });

    menuGroups.push({
      title: t('menu.settings.login.title'),
      entries,
    });
  }

  // design has the simple menu in the middle
  menuGroups.push(...simpleMenuGroups());

  if (boundContractsService) {
    menuGroups.push({
      title: t('menu.settings.contracts.title'),
      entries: [
        {
          label: t('menu.settings.contracts.labels.boundContracts'),
          type: MenuEntryTypes.Service,
          service: boundContractsService,
        },
      ],
    });
  }

  return menuGroups;
};

/**
 * Creates the menuGroups based on the services
 * we receive from the backend (passed as an argument)
 */
export const createServicesMenuGroups = (state: RootState) => {
  const jbWebsiteService = selectJBWebsiteService(state);
  const paymentScannerService = selectPaymentScannerService(state);
  const shouldDisplaySupportMenu = selectShouldDisplaySupportMenu(state);

  if (jbWebsiteService || paymentScannerService) {
    const menuGroups: MenuGroup[] = [];
    // order is important in how menu show up in the menu
    const entries: SettingEntry[] = [];

    shouldDisplaySupportMenu &&
      entries.push({
        label: t('menu.services.labels.support'),
        type: MenuEntryTypes.Component,
        value: 'SupportMenu',
      });

    paymentScannerService &&
      entries.push({
        label: t('menu.services.labels.paymentScanner'),
        type: MenuEntryTypes.Component,
        value: 'PaymentScanner',
      });

    jbWebsiteService &&
      entries.push({
        label: t('menu.services.labels.jbWebsite'),
        type: MenuEntryTypes.Service,
        service: jbWebsiteService,
      });

    menuGroups.push({
      entries,
    });

    return menuGroups;
  }
};
