import { AnyAction } from '@reduxjs/toolkit';
import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import { RootState } from 'app/redux/rootReducer';

import {
  updateProductTourViewedStart,
  updateProductTourViewedSuccess,
  updateProductTourViewedFailure,
} from './productTourSlice';
import { updateDashboardActivityRequest } from 'features/entities/entitiesRequests';

export const updateProductTourViewedEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(updateProductTourViewedStart.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      from(updateDashboardActivityRequest(action.payload)).pipe(
        mergeMap(() => [updateProductTourViewedSuccess()]),
        catchError((error: Error) => [updateProductTourViewedFailure({ error: error.message })])
      )
    )
  );
