import { IPluginRegistry, Symbol, MessengerModule, IPluginProvider } from '@map/core';
import { IPushTANAuthenticator } from './IPushTANAuthenticator';
import { PushTANAuthenticator } from './PushTANAuthenticator';

/**
 * Provides convenience methods to use the PIN module.
 */
export module PushTANAuthenticatorModule {
  export module Errors {
    const prefix = 'PushTANAuthenticatorError.';

    /**
     * Raised if the binding is missing or invalid.
     */
    export const invalidBinding = prefix + 'InvalidBinding';

    /**
     * Raised if the secure channel encounters an error.
     */
    export const secureChannelError = prefix + 'SecureChannelError';
  }

  /**
   * The symbol that uniquely identifies the IPushTANService.
   */
  export const serviceSymbol = new Symbol('PushTANAuthenticator');

  export const activationMessageTag = 'PushTANActivationMessage';
  export const authenticationMessageTag = 'PushTANAuthenticationMessage';
  export const updateEmCertMessageTag = 'PushTANUpdateEmCertMessage';
  export const authenticatorHasBindingMessageTag = 'PushTANAuthenticatorHasBindingMessage';
  export const hasEmCertChangedMessageTag = 'PushTANHasEmCertChangedMessage';
  export const commitEmCertUpdateMessageTag = 'PushTANCommitEmCertUpdateMessage';
  export const getEmCertIdMessageTag = 'PushTANGetEmCertIdMessage';
  export const generateOTPMessageTag = 'PushTANGenerateOTPMessage';
  export const getBindingsMessageTag = 'PushTANAuthenticatorGetBindingsMessage';

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): IPushTANAuthenticator => {
    return provider.resolve<IPushTANAuthenticator>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);
    registrar.registerSingle(serviceSymbol, (p) => new PushTANAuthenticator(MessengerModule.getService(p)), MessengerModule.serviceSymbol);
  };
}
