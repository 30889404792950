import { createSlice, Action, PayloadAction, AnyAction } from '@reduxjs/toolkit';

import { ErrorPayload } from 'common/types';
import {
  fetchDashboardDataFailure,
  fetchDashboardDataSuccess,
} from 'features/entities/entitiesSlice';
import {
  AcceptTAndCActivity,
  DomicileActivity,
  FirstTimeFeatures,
  orderedFirstTimeFeatures,
  ServiceCodes,
} from 'features/entities/entitiesTypes';
import { openExternalServiceStart } from 'features/extNavigation/extNavigationSlice';

export type Screen = {
  screenType: string;
  screenProps: any;
};

export type ScreenMap = {
  [key in FirstTimeFeatures]: Screen;
};

export type FirstTimeState = {
  isFetching: boolean;
  error?: string;
  features?: FirstTimeFeatures[];
  screen?: Screen;
  shouldDisplayTAndCDialog: boolean;
  needsToAcceptTAndC?: boolean;
  postponedAction?: Action;
  isEnablingProspect?: boolean;
  isEnablingTrial?: boolean;
};

const initialState: FirstTimeState = {
  isFetching: false,
  shouldDisplayTAndCDialog: false,
};

const screens: ScreenMap = {
  molDomicile: {
    screenType: 'SelectDomicile',
    screenProps: {
      key: 'selectDomicile',
    },
  },
  molTerms: {
    screenType: 'TermsAndConditions',
    screenProps: {
      key: 'termsAndConditions',
      cancellable: false,
    },
  },
  molLanding: {
    screenType: 'LandingScreen',
    screenProps: {
      key: 'landingScreen',
    },
  },
};

type DashboardDataRequestCompleteAction =
  | typeof fetchDashboardDataSuccess
  | typeof fetchDashboardDataFailure;

function isDashboardDataRequestCompleteAction(
  action: AnyAction
): action is DashboardDataRequestCompleteAction {
  return (
    action.type === fetchDashboardDataSuccess.type || action.type === fetchDashboardDataFailure.type
  );
}

export const firstTime = createSlice({
  name: 'firstTime',
  initialState,
  reducers: {
    clearPostponedAction: (state, action: Action) => {
      state.postponedAction = undefined;
    },

    displayTermsAndThen: (state, action: PayloadAction<Action>) => {
      state.shouldDisplayTAndCDialog = true;
      // this is the action we will restore when the terms are signed
      state.postponedAction = action.payload;
    },

    cancelTerms: (state, action: Action) => {
      state.shouldDisplayTAndCDialog = false;
      // Clear postponedAction
      state.postponedAction = undefined;
    },

    nextFTScreen: (state, action: Action) => {
      if (state.features && state.features.length > 0) {
        // the exlamation mark is non-null-assertion-operator
        // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-0.html#non-null-assertion-operator
        const currentFeature = state.features.shift()!;
        state.screen = screens[currentFeature];
      }
    },

    updateDomicileStart: (state, action: PayloadAction<DomicileActivity>) => {
      state.isFetching = true;
    },

    updateDomicileSuccess: (state, action: Action) => {
      state.isFetching = false;
    },

    updateDomicileFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },

    updateTermsStart: (state, action: PayloadAction<AcceptTAndCActivity>) => {
      state.isFetching = true;
    },

    updateTermsSuccess: (state, action: Action) => {
      state.isFetching = false;
      state.needsToAcceptTAndC = false;
      state.shouldDisplayTAndCDialog = false;
    },

    updateTermsFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },

    setFirstTimeFeatures: (state, action: PayloadAction<FirstTimeFeatures[]>) => {
      // use ordered features so that we can display the screens in the right order
      state.features = orderedFirstTimeFeatures.filter((feature) =>
        action.payload.includes(feature)
      );
      state.needsToAcceptTAndC = action.payload.some((feature) => feature === 'molTerms');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(openExternalServiceStart, (state, action) => {
        const service = action.payload;
        if (service.code === ServiceCodes.IDP_REG_MEM_PROSPECT) {
          state.isEnablingProspect = true;
        } else if (service.code === ServiceCodes.IDP_REG_MEM_TRIAL) {
          state.isEnablingTrial = true;
        }
      })
      // when we fetch dashboard data we assume that any enabling is complete
      .addMatcher(isDashboardDataRequestCompleteAction, (state, action) => {
        state.isEnablingProspect = false;
        state.isEnablingTrial = false;
      });
  },
});

export const {
  cancelTerms,
  clearPostponedAction,
  displayTermsAndThen,
  nextFTScreen,
  updateDomicileStart,
  updateDomicileSuccess,
  updateDomicileFailure,
  updateTermsStart,
  updateTermsSuccess,
  updateTermsFailure,
  setFirstTimeFeatures,
} = firstTime.actions;

export const firstTimeReducer = firstTime.reducer;

export { initialState as firstTimeInitialState };
