import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { selectIsEnablingProspect } from 'features/firstTime/firstTimeSelectors';
import { MEMBER_ZONE_URL, scenario, TargetEnvItems, TARGET_ENV } from 'app/config';
import {
  initializeInsightsMessaging,
  tearDownInsightsMessaging,
} from './insightsIframeIntegration';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
    },
    iframe: {
      border: 0,
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      overflowY: 'auto',
    },
  })
);

export const InsightsView = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const isEnablingProspect = useSelector(selectIsEnablingProspect);

  let memberZoneUrl = MEMBER_ZONE_URL;

  if (TARGET_ENV === TargetEnvItems.devcloud) {
    memberZoneUrl = `${MEMBER_ZONE_URL}?scenario=${scenario}&lang=${i18n.language}`;
  }

  // communicate with Insights iframe
  useEffect(() => {
    initializeInsightsMessaging();
    return tearDownInsightsMessaging;
  }, []);

  return (
    <div className={classes.container}>
      {/* if we're in the process of converting a trial to a prospect
       we want to refresh when it's over */}
      {!isEnablingProspect && (
        <iframe
          allowFullScreen
          title={t('insights.title')}
          name="insights"
          src={memberZoneUrl}
          className={classes.iframe}
        />
      )}
    </div>
  );
};
