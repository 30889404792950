import { Symbol } from '../Core/Symbol';
import { IPluginRegistry } from './IPluginRegistry';
import { IPluginProvider } from './IPluginProvider';
import { IPluginProviderAware } from './IPluginProviderAware';
import { PluginProvider } from './PluginProvider';
import { PluginRegistration } from './PluginRegistration';
import { PluginLocator } from './PluginLocator';

export class PluginRegistry implements IPluginRegistry {
  /**
   * Register an instance to be constructed in a lazy fashion. The marked dependencies are passed to the callback.
   * You callback should not throw any errors.
   * @param symbol - the identifier of the service. This identifier can be used in the resolve method later.
   * @param createInstance - the callback to create an instance.
   * @param dependencies - the dependencies required by your callback to successfully create an instance.
   */
  registerSingle = <TServiceType extends IPluginProviderAware>(symbol: Symbol, createInstance: (p: IPluginProvider) => TServiceType, ...dependencies: Symbol[]) => {
    const r = this.getOrCreateRegistration(symbol);
    r.createInstance = createInstance;
    r.action = undefined;
    r.dependencies = this.getOrCreateRegistrations(dependencies);
    return this;
  }

  /**
   * @return The provider built from the registrations in this object.
   */
  commit = () => {
    return new Promise<IPluginProvider>((resolve, reject) => {
      const result = new PluginProvider(this.registrations);
      // Assign the global provider since some of the commit actions may rely on it.
      PluginLocator.instance = result;

      const promises: Promise<void>[] = [];
      for (const action of this.commitActions) {
        promises.push(action(result));
      }
      for (const registration of this.registrations) {
        const action = registration.action;
        if (action) {
          promises.push(action(result));
        }
      }
      Promise.all(promises)
        .then(() => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addCommitAction(symbol: Symbol, action: (provider: IPluginProvider) => Promise<void>): IPluginRegistry {
    const registration = this.getRegistration(symbol);
    if (registration) {
      registration.action = action;
    } else {
      throw new Error(`No service registered for symbol [${symbol}] yet.`);
    }
    return this;
  }

  prependCommitAction(action: (provider: IPluginProvider) => Promise<void>): IPluginRegistry {
    this.commitActions.unshift(action);

    return this;
  }

  private getOrCreateRegistrations = (symbols: Symbol[]): PluginRegistration[] => {
    return symbols.map((s) => this.getOrCreateRegistration(s));
  }

  private getOrCreateRegistration = (symbol: Symbol) => {
    const r = this.getRegistration(symbol);
    if (r !== null) {
      return r;
    }

    const result = new PluginRegistration(symbol, undefined, []);

    this.registrations.push(result);

    return result;
  }

  private getRegistration = (symbol: Symbol) => {
    const results = this.registrations.filter((r) => r.symbol === symbol);

    return results.length === 1 ? results[0] : null;
  }

  private registrations: PluginRegistration[] = [];
  private commitActions: ((provider: IPluginProvider) => Promise<void>)[] = [];
}