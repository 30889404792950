import { AnyAction } from '@reduxjs/toolkit';
import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';
import compareVersions from 'compare-versions';

import { RootState } from 'app/redux/rootReducer';
import { nextFTScreen, setFirstTimeFeatures } from 'features/firstTime/firstTimeSlice';

import {
  fetchDashboardDataStart,
  fetchDashboardDataSuccess,
  fetchDashboardDataFailure,
} from './entitiesSlice';
import {
  DashboardData,
  DeviceSettings,
  FirstTimeFeatures,
  orderedFirstTimeFeatures,
} from './entitiesTypes';
import { fetchDashboardDataRequest } from './entitiesRequests';
import { selectDeviceSettings } from 'features/session/sessionSelectors';
import { displayUpdateScreen, MainViewElements, setMainView } from 'features/session/sessionSlice';
import { selectIsEnablingTrial } from 'features/firstTime/firstTimeSelectors';
import { getMapBridge } from 'features/map';

const createFetchDashboardDataSuccessActions = (dashboardData: DashboardData) => {
  // then detect first time features
  const firstTimeFeatures = Object.keys(dashboardData).filter(
    (feature) =>
      (orderedFirstTimeFeatures as string[]).includes(feature) &&
      !!dashboardData[feature as keyof DashboardData]
  ) as FirstTimeFeatures[];

  return firstTimeFeatures.length > 0
    ? [
        fetchDashboardDataSuccess(dashboardData),
        setFirstTimeFeatures(firstTimeFeatures),
        // display first time features
        setMainView(MainViewElements.FirstTimeOrchestrator),
        nextFTScreen(), // kickstart FirstTimeOrchestrator
      ]
    : [fetchDashboardDataSuccess(dashboardData), setMainView(MainViewElements.MainApp)];
};

export const fetchDashboardDataEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(fetchDashboardDataStart.match),
    withLatestFrom(state$),

    mergeMap(([action, state]) => {
      // clone device settings cause we will add a property
      const deviceSettings = { ...selectDeviceSettings(state) } as DeviceSettings;

      if (!deviceSettings) {
        throw new Error(`Expected 'deviceSettings' to exist`);
      }
      // we may need to update service providers after returning from a service registration
      deviceSettings.updateServiceProviders = !!action.payload.shouldUpdateServiceProviders;

      return from(fetchDashboardDataRequest(deviceSettings)).pipe(
        mergeMap((payload) => {
          // check first we satisfy the minRequiredVersion constraints
          if (compareVersions(deviceSettings.appVersion, payload.minRequiredVersion) === -1) {
            return [displayUpdateScreen()];
          }

          const isEnablingTrial = selectIsEnablingTrial(state);
          const molInsights = payload.molInsights;

          // if we enable trial for the first time then we need
          // to save it in MAP configuration
          if (isEnablingTrial && molInsights) {
            const mapBridge = getMapBridge();
            return from(mapBridge.updateIsMemServiceActivated(true)).pipe(
              mergeMap(() => createFetchDashboardDataSuccessActions(payload))
            );
          }

          return createFetchDashboardDataSuccessActions(payload);
        }),
        catchError((error: Error) => [fetchDashboardDataFailure({ error: error.message })])
      );
    })
  );
