import { IMessenger, Symbol } from '@map/core';
import { CloseBrowserMessage } from './CloseBrowserMessage';
import { CloseAllBrowsersMessage } from './CloseAllBrowsersMessage';
import { BrowserModule } from './BrowserModule';
import { IBrowserService } from './IBrowserService';
import { ScreenRotation } from './ScreenRotation';
import { ConfigureRotationMessage } from './ConfigureRotationMessage';
import { OpenPageMessage } from './OpenPageMessage';
import { OpenExternalPageMessage } from './OpenExternalPageMessage';
import { OpenLocalPageMessage } from './OpenLocalPageMessage';
import { DownloadMessage } from './DownloadMessage';

/**
 * The standard implementation of the IBrowserService.
 */
export class BrowserService implements IBrowserService {
  constructor(private messenger: IMessenger) {
  }

  /**
   * Close the open page in the browser.
   */
  readonly openPage = (url: string): void => {
    this.messenger.send(new OpenPageMessage(url));
  }

  /**
   * Close the open page in the browser.
   */
  readonly openExternalPage = (url: string): void => {
    this.messenger.send(new OpenExternalPageMessage(url));
  }

  /**
   * Close the open page in the browser.
   */
  readonly openLocalPage = (path: string): void => {
    this.messenger.send(new OpenLocalPageMessage(path));
  }

  /**
   * Close the open page in the browser.
   */
  readonly closePage = (): void => {
    this.messenger.send(new CloseBrowserMessage());
  }

  /**
   * Close all pages except for the home page.
   */
  readonly closeAllPages = (): void => {
    this.messenger.send(new CloseAllBrowsersMessage());
  }

  /**
   * Set the allowed orientations of the screen to the given rotation.
   */
  readonly configureRotation = (rotation: ScreenRotation): void => {
    this.messenger.send(new ConfigureRotationMessage(rotation));
  }

  /**
   * Download attachment from the given url.
   */
  readonly downloadAttachment = (url: string, mimeType: string, token: string): void => {
    this.messenger.send(new DownloadMessage(url, mimeType, token));
  }

  readonly symbol: Symbol = BrowserModule.serviceSymbol;
}
