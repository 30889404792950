import {RequestMessage} from '@map/core';

export const downloadMessageTag = 'DownloadMessage';

/**
 * A message to download attachment
 */
export class DownloadMessage extends RequestMessage {
  readonly tag: string = downloadMessageTag;

  constructor(url: string, mimeType: string, token: string) {
    super();
    this.url = url;
    this.mimeType = mimeType;
    this.token = token;
  }

  /**
   * The full url to the content that should be downloaded
   */
  url: string;
  /**
   * The mime type of the content reported by the server
   */
  mimeType: string;
  /**
   * Authorization token
   */
  token: string;
}