import { useTranslation } from 'react-i18next';
import { Box, Button, Theme, Typography, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';

import { resetApp } from 'app/redux/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(3),
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.3,
      textAlign: 'center',
    },

    hint: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.3,
      textAlign: 'center',
    },
  })
);

export const ConnectionErrorScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleTryAgain = () => dispatch(resetApp());

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex="1"
      overflow="hidden"
      bgcolor={theme.palette.common.white}
    >
      <Box width={255} textAlign="center">
        <Typography variant="body1" component="div" className={classes.title}>
          {t('error.noConnection.title')}
        </Typography>
        <Typography variant="body2" component="div" className={classes.hint}>
          {t('error.noConnection.labels.hint')}
        </Typography>

        <Box mt={20}>
          <Button variant="contained" color="primary" onClick={handleTryAgain}>
            {t('error.noConnection.actions.tryAgain')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
