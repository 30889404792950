import { ResponseBase } from '../Messaging/ResponseBase';

/**
 * Get the response to a ConfigurationMessage.
 */
export class DevicePropertiesResponse extends ResponseBase {

  public hasCamera!: boolean;

  public isTablet!: boolean;

  public appVersion!: string;

  public mapVersion!: string;

  public deviceId!: string;

  public deviceName!: string;
}
