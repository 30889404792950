import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { alpha, Typography, useTheme } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { jbColors } from 'app/theme/jbColors';
import { ServiceInfoGroupTypes } from 'features/entities/entitiesTypes';
import { selectServiceInfoGroups } from 'features/entities/entitiesSelectors';
import { getGroupLabels, getNameLabels } from 'common/utils/contactsUtils';

type Props = {};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      backgroundColor: jbColors.app.almostWhite,
      color: theme.palette.primary.main,
    },

    groupsList: {
      padding: `${theme.spacing(1)} 0`,
      margin: 0,
      listStyle: 'none',
    },

    groupItem: {
      padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(4)}`,
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.common.white,
    },

    groupTypeLabel: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.333,
      letterSpacing: 0.2,
    },

    numbersList: {
      marginTop: theme.spacing(1),
      padding: 0,
      listStyle: 'none',
    },

    numberInfo: {
      marginTop: theme.spacing(2),
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.43,
      letterSpacing: 0.2,
    },

    numberLink: {
      color: 'inherit',
      textDecoration: 'none',
      borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.3)}`,
      display: 'block',
      marginTop: theme.spacing(0.5),

      '@media (min-width: 481px)': {
        display: 'inline',
        marginTop: 0,
      },
    },

    textLinkContainer: {
      marginTop: theme.spacing(2),
    },

    textLink: {
      color: 'inherit',
      letterSpacing: 0.2,
    },
  })
);

export const SupportCenterList = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const serviceInfoGroups = useSelector(selectServiceInfoGroups).filter(
    (g) => g.groupType !== ServiceInfoGroupTypes.GENERAL
  );

  const groupLabels = getGroupLabels();
  const nameLabels = getNameLabels();

  return (
    <div ref={ref} className={classes.container}>
      {serviceInfoGroups.length > 0 && (
        <ul className={classes.groupsList}>
          {serviceInfoGroups.map((group) => (
            <li key={`${group.groupType}-list`} className={classes.groupItem}>
              <Typography variant="h3" className={classes.groupTypeLabel}>
                {groupLabels[group.groupType]}
              </Typography>

              <ul className={classes.numbersList}>
                {group.serviceInfo.map((supportNumber, idx) => (
                  <Typography
                    key={`${group.groupType}-${supportNumber.name}-${idx}`}
                    component="li"
                    className={classes.numberInfo}
                  >
                    {nameLabels[supportNumber.name]}:{' '}
                    <a className={classes.numberLink} href={`tel:${supportNumber.number}`}>
                      {supportNumber.number}
                    </a>
                  </Typography>
                ))}
              </ul>

              {group.openingHours && (
                <Typography variant="body2" className={classes.numberInfo}>
                  {group.openingHours}
                </Typography>
              )}

              {(group.imprintUrl || group.privacyPolicyUrl) && (
                <div className={classes.textLinkContainer}>
                  {group.imprintUrl && (
                    <Typography
                      component="a"
                      href={group.imprintUrl}
                      target="_blank"
                      variant="body2"
                      className={classes.textLink}
                      color="primary"
                    >
                      {t('common.serviceInfo.actions.imprint')}
                    </Typography>
                  )}

                  {group.privacyPolicyUrl && (
                    <Typography
                      component="a"
                      href={group.privacyPolicyUrl}
                      target="_blank"
                      variant="body2"
                      className={classes.textLink}
                      color="primary"
                      style={{ marginLeft: group.imprintUrl ? theme.spacing(4) : 0 }}
                    >
                      {t('common.serviceInfo.actions.privacyNotice')}
                    </Typography>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
});
