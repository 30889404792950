import { IPluginRegistry } from '../Service/IPluginRegistry';
import { DevicePropertiesModule } from '../Device/DevicePropertiesModule';
import { MockDeviceProperties } from './MockDeviceProperties';
import { MessengerModule } from '../Messaging/MessengerModule';
import { isRequestMessage } from '../Messaging/RequestMessage';
import { ResponseBase } from '../Messaging/ResponseBase';
import { MessageBase } from '../Messaging/MessageBase';
import { isConfigurationMessage } from '../Configuration/ConfigurationMessage';
import { isTranslationMessage } from '../Translation/TranslationMessage';
import { ConfigurationResponse } from '../Configuration/ConfigurationResponse';
import { TranslationResponse } from '../Translation/TranslationResponse';
import { ModuleKeyValueTable } from '../Translation/TranslationProvider';
import { isDevicePropertiesMessage } from '../Device/DevicePropertiesMessage';
import { DevicePropertiesResponse } from '../Device/DevicePropertiesResponse';

/**
 * Provides convenience methods to use mocking in tests.
 */
export module MockModule {
  /**
   * Register the core module components with the given registrar.
   *
   * @param registrar - the registrar to extend.
   * @return the registrar.
   */
  export const use = (registrar: IPluginRegistry) => {
    registrar.registerSingle(DevicePropertiesModule.serviceSymbol, (p) => new MockDeviceProperties());

    MockModule.setConfiguration('{}');
    MockModule.setTranslation({Core: {key: 'value'}});

    registrar.prependCommitAction((p) => {
      const messenger = MessengerModule.getService(p);
      window.portalApp = {
        sendResponse: (r: ResponseBase) => messenger.sendResponse(r),
        sendMessage: (m: MessageBase) => messenger.sendInternal(m)
      };
      setNative({
        postMessage: (message: any) => {
          const obj = JSON.parse(message);

          if (isConfigurationMessage(obj)) {
            const response = new ConfigurationResponse();
            response.configuration = configuration;
            response.uniqueMessageId = obj.uniqueMessageId;
            window.portalApp.sendResponse(response);
          } else if (isTranslationMessage(obj)) {
            const response = new TranslationResponse();
            response.translations = translations;
            response.uniqueMessageId = obj.uniqueMessageId;
            window.portalApp.sendResponse(response);
          } else if (isDevicePropertiesMessage(obj)) {
            const response = new DevicePropertiesResponse();
            response.appVersion = '1';
            response.deviceId = '1';
            response.mapVersion = '1';
            response.hasCamera = true;
            response.isTablet = false;
            response.uniqueMessageId = obj.uniqueMessageId;
            window.portalApp.sendResponse(response);
          } else {
            messages.push(obj);
            messenger.sendInternal(obj);
          }
        }
      });

      return new Promise((resolve) => {
        resolve();
      });
    });
  };

  export const setConfiguration = (config: string) => {
    configuration = config;
  };

  export const setTranslation = (translation: ModuleKeyValueTable) => {
    translations = translation;
  };

  export let translations: ModuleKeyValueTable;

  export let configuration: string;

  export let messages: MessageBase[] = [];

  export const setNative = (native: INative) => {
    window.native = native;
  };

  export const setWebkitNative = (native: INative) => {
    window.webkit = {
      messageHandlers: {
        mapMessageHandler: native
      }
    };
  };

  export const getPortalAppNative = () => {
    return window.portalApp as IPortalApp;
  };

  export const clearMessages = () => {
    messages = [];
  };

  export const respondToLastRequest = (createResponse: () => any) => {
    if (messages.length === 0) {
      setTimeout(() => respondToLastRequest(createResponse), 50);
      return;
    }

    const message = messages.pop();

    if (message !== undefined) {
      if (isRequestMessage(message)) {
        const response = createResponse();
        response.uniqueMessageId = message.uniqueMessageId;

        MockModule.getPortalAppNative().sendResponse(response);
      }
      else {
        throw new Error('Message was not a request');
      }
    } else {
      throw new Error('Message was null');
    }
  };
}

export interface INative {
  postMessage(message: any): void;
}

export interface IPortalApp {
  sendResponse(r: ResponseBase): void;
  sendMessage(m: MessageBase): void;
}
