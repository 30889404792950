import React, { useEffect, ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { BottomNavigation, BottomNavigationAction, alpha } from '@mui/material';

import { jbColors } from 'app/theme/jbColors';
import { updateDashboardActivityRequest } from 'features/entities/entitiesRequests';
import {
  ActivityTypes,
  InternalNavigationScreen,
  InternalNavigationScreens,
} from 'features/entities/entitiesTypes';
import {
  selectShouldDisplayInsights,
  selectShouldDisplayContact,
  selectShouldDisplayOnboarding,
  selectInsightsNotificationCount,
} from 'features/entities/entitiesSelectors';

import { ReactComponent as HomeIcon } from './home-icon.svg';
import { ReactComponent as HomeSelectedIcon } from './home-selected-icon.svg';
import { ReactComponent as ContactIcon } from './contact-icon.svg';
import { ReactComponent as ContactSelectedIcon } from './contact-selected-icon.svg';
import { ReactComponent as InsightsIcon } from './insights-icon.svg';
import { ReactComponent as InsightsSelectedIcon } from './insights-selected-icon.svg';
import { ReactComponent as WelcomeIcon } from './welcome-icon.svg';
import { ReactComponent as WelcomeSelectedIcon } from './welcome-selected-icon.svg';
import { Badge, badgeClasses } from '@mui/material';

export type MainNavigationScreen = Extract<
  InternalNavigationScreen,
  'CONTACT_SCREEN' | 'HOME_SCREEN' | 'INSIGHTS_SCREEN' | 'ONBOARDING_SCREEN'
>;

type ScreenToRoutesMap = {
  [key in MainNavigationScreen]: string;
};

type RoutesToScreenMap = {
  [key: string]: MainNavigationScreen;
};

export const screenToRoutesMap: ScreenToRoutesMap = {
  [InternalNavigationScreens.CONTACT_SCREEN]: '/contact',
  [InternalNavigationScreens.HOME_SCREEN]: '/home',
  [InternalNavigationScreens.INSIGHTS_SCREEN]: '/insights',
  [InternalNavigationScreens.ONBOARDING_SCREEN]: '/welcome',
};

export const routesToScreenMap: RoutesToScreenMap = {
  '/contact': InternalNavigationScreens.CONTACT_SCREEN,
  '/home': InternalNavigationScreens.HOME_SCREEN,
  '/insights': InternalNavigationScreens.INSIGHTS_SCREEN,
  '/welcome': InternalNavigationScreens.ONBOARDING_SCREEN,
};

type Props = {
  notificationCount: number | undefined;
};

const InsightsIconWithBadge = ({ notificationCount }: Props) => {
  if (notificationCount !== undefined && notificationCount > 0) {
    return (
      <Badge
        badgeContent={notificationCount}
        color="primary"
        sx={{
          [`& .${badgeClasses.badge}`]: {
            right: -1,
            padding: '0 4px',
          },
        }}
      >
        <InsightsIcon />
      </Badge>
    );
  } else {
    return <InsightsIcon />;
  }
};

export const MainNavigation = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = useState<string>('');

  const insightsNotificationCount = useSelector(selectInsightsNotificationCount);
  const shouldDisplayInsights = useSelector(selectShouldDisplayInsights);
  const shouldDisplayContact = useSelector(selectShouldDisplayContact);
  const shouldDisplayOnboarding = useSelector(selectShouldDisplayOnboarding);

  // get root path from router and map it to a menu item
  useEffect(() => {
    const rootPath = `/${location.pathname.split('/')[1] ?? ''}`;
    const screen = routesToScreenMap[rootPath];

    if (screen) {
      setValue(screen);
      updateDashboardActivityRequest({
        activityType: ActivityTypes.INTERNAL_NAVIGATION,
        screenName: screen,
      });
    }
  }, [location]);

  return (
    <BottomNavigation
      value={value}
      onChange={(event: ChangeEvent<{}>, newScreen: MainNavigationScreen) => {
        navigate(screenToRoutesMap[newScreen as MainNavigationScreen]);
      }}
      showLabels
      style={{ borderTop: `1px solid ${alpha(jbColors.app.greyLight, 0.5)}` }}
    >
      <BottomNavigationAction
        label={t('mainNavigation.home')}
        value={InternalNavigationScreens.HOME_SCREEN}
        icon={value === InternalNavigationScreens.HOME_SCREEN ? <HomeSelectedIcon /> : <HomeIcon />}
      />

      {shouldDisplayInsights && (
        <BottomNavigationAction
          label={t('mainNavigation.insights')}
          value={InternalNavigationScreens.INSIGHTS_SCREEN}
          icon={
            value === InternalNavigationScreens.INSIGHTS_SCREEN ? (
              <InsightsSelectedIcon />
            ) : (
              <InsightsIconWithBadge notificationCount={insightsNotificationCount} />
            )
          }
        />
      )}

      {shouldDisplayContact && (
        <BottomNavigationAction
          label={t('mainNavigation.contact')}
          value={InternalNavigationScreens.CONTACT_SCREEN}
          icon={
            value === InternalNavigationScreens.CONTACT_SCREEN ? (
              <ContactSelectedIcon />
            ) : (
              <ContactIcon />
            )
          }
        />
      )}

      {shouldDisplayOnboarding && (
        <BottomNavigationAction
          label={t('mainNavigation.welcome')}
          value={InternalNavigationScreens.ONBOARDING_SCREEN}
          icon={
            value === InternalNavigationScreens.ONBOARDING_SCREEN ? (
              <WelcomeSelectedIcon />
            ) : (
              <WelcomeIcon />
            )
          }
        />
      )}
    </BottomNavigation>
  );
};
