import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/redux/rootReducer';

export const selectProductTour = (state: RootState) => state.productTour;

export const selectShouldDisplayProductTour = createSelector(
  selectProductTour,
  (productTour) => productTour.shouldDisplayProductTour
);

export const selectProductTourVersion = createSelector(
  selectProductTour,
  (productTour) => productTour.productTourVersion
);

export const selectServiceToOpenOnHide = createSelector(
  selectProductTour,
  (productTour) => productTour.serviceToOpenOnHide
);
