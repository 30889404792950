import { MessageBase } from './MessageBase';
import { ResponseBase } from './ResponseBase';
import { PluginLocator } from '../Service/PluginLocator';
import { MessengerModule } from './MessengerModule';
import { LoggingModule } from '../Logging/LoggingModule';

/**
 * The method used by native code to initiate a call in TypeScript code.
 *
 * @param message - the message to send; cannot be null.
 */
export function sendMessage(message: MessageBase) {
  const provider = PluginLocator.instance;
  if (message === null) {
    LoggingModule.getService(provider).logError('sendMessage() called with null message.');
  }

  MessengerModule.getService(provider).sendInternal(message);
}

/**
 * The method used by native code to respond to messages originating in TypeScript code.
 *
 * @param response - the response to send; cannot be null.
 */
export function sendResponse(response: ResponseBase) {
  const provider = PluginLocator.instance;
  if (response === null) {
    LoggingModule.getService(provider).logError('sendResponse() called with null response.');
  }

  MessengerModule.getService(provider).sendResponse(response);
}

window.portalApp = { sendMessage: sendMessage, sendResponse: sendResponse };