import { Symbol } from '../Core/Symbol';
import { IPluginRegistry } from '../Service/IPluginRegistry';
import { IPluginProvider } from '../Service/IPluginProvider';
import { DevicePropertiesModule } from '../Device/DevicePropertiesModule';
import { LoggingModule } from '../Logging/LoggingModule';
import { IMessenger } from './IMessenger';
import { Messenger } from './Messenger';

/**
 * Defines the API with which you can include and obtain messaging services.
 */
export module MessengerModule {
  /**
   * The error codes that can occur in the service.
   */
  export module Errors {
    const prefix = 'MessengerError.';

    /**
     * A response was requested from a broadcast message.
     */
    export const broadcastResponseNotSupported = prefix + 'BroadcastResponseNotSupported';
  }

  /**
   * The symbol that uniquely identifies this service.
   */
  export const serviceSymbol = new Symbol('Messenger');

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): IMessenger => {
    return provider.resolve<IMessenger>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    DevicePropertiesModule.use(registrar);
    LoggingModule.use(registrar);

    registrar.registerSingle(serviceSymbol, (p) =>
      new Messenger(DevicePropertiesModule.getService(p)), DevicePropertiesModule.serviceSymbol);
  };
}
