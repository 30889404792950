import { IPluginProviderAware } from '@map/core';

export enum SupportedBiometricMethod {
  None,
  TouchId,
  FaceId
}

/**
 * Interacts with the user to get/set data protected by a fingerprint.
 */
export interface IBiometricStorage extends IPluginProviderAware {
  /**
   * Returns a value indicating whether a fingerprint is registered for use on this device and with this application.
   *
   * @return - A promise that indicates whether a fingerprint is registered for this application.
   */
  isBiometricStorageSet(): Promise<boolean>;

  /**
   * Activate fingerprint identification for this application.
   *
   * Subsequent calls to this method will be rejected until {@link reset} is called.
   *
   * @return - A promise that indicates when the user has finished setting up a fingerprint.
   */
  setupBiometricStorage(): Promise<boolean>;

  /**
   * Deactivate fingerprint-identification.
   *
   * @return - the promise which resolves on success.
   */
  reset(): Promise<any>;

  /**
   * Returns a value indicating whether a value is associated with the given entry.
   *
   * @param entry - the entry to test.
   *
   * @return - A value indicating whether a value is associated with the given entry.
   */
  isValueSet(entry: string): Promise<boolean>;

  /**
   * Sets the data for the given entry to the value.
   *
   * @param entry - the entry to set.
   * @param value - the value to set.
   *
   * @return - A promise that indicates when the value has been set.
   */
  setValue(entry: string, value: string): Promise<boolean>;

  /**
   * Gets the data for the given entry.
   *
   * @param entry - the entry to get.
   * @return - A promise that returns the requested data.
   */
  getValue(entry: string): Promise<string>;

  /**
   * Gets the supported authentication methods.
   *
   * @returns {Promise<SupportedBiometricMethod>}
   */
  getSupportedBiometricMethod(): Promise<SupportedBiometricMethod>;
}
