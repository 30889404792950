import { createSlice, Action, PayloadAction } from '@reduxjs/toolkit';

import { ServiceProvider } from 'features/entities/entitiesTypes';

export type ExtNavigationState = {
  error?: string;
};

const initialState: ExtNavigationState = {};

export const extNavigation = createSlice({
  name: 'extNavigation',
  initialState,
  reducers: {
    // no state change but there's an associated Epic
    appIsVisible: (state, action: Action) => {},
    // no state change but there's an associated Epic
    openExternalServiceStart: (state, action: PayloadAction<ServiceProvider>) => {},
    openExternalServiceSuccess: (state, action: Action) => {},
  },
});

export const {
  appIsVisible,
  openExternalServiceStart,
  openExternalServiceSuccess,
} = extNavigation.actions;

export const extNavigationReducer = extNavigation.reducer;

export { initialState as extNavigationInitialState };
