import { useTranslation } from 'react-i18next';
import { Box, Button, Theme, Typography, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { useDispatch } from 'react-redux';

import { resetApp } from 'app/redux/rootReducer';
import { GENERAL_CONTACT_TEL_NUMBER } from 'app/config';
import { jbColors } from 'app/theme/jbColors';

type Props = {
  onReset?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(3),
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.3,
      textAlign: 'center',
    },

    hint: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.3,
      textAlign: 'center',
    },

    support: {
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.57,
      letterSpacing: -0.2,
    },

    telephone: {
      marginTop: theme.spacing(1.25),
      display: 'block',
      color: jbColors.core.reflexBlue,
      textDecoration: 'none',
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.37,
      letterSpacing: -0.2,
    },
  })
);

export const GeneralErrorScreen = ({ onReset }: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleTryAgain = () => {
    dispatch(resetApp());
    onReset && onReset();
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex="1"
      overflow="hidden"
      bgcolor={theme.palette.common.white}
    >
      <Box width={255} textAlign="center">
        <Typography variant="body1" component="div" className={classes.title}>
          {t('error.general.title')}
        </Typography>
        <Typography variant="body2" component="div" className={classes.hint}>
          {t('error.general.labels.hint')}
        </Typography>

        <Box mt={16}>
          <Button variant="contained" color="primary" onClick={handleTryAgain}>
            {t('error.general.actions.tryAgain')}
          </Button>

          <Box textAlign="center" mt={6.5}>
            <Typography variant="caption" className={classes.support}>
              {t('error.general.labels.support')}
            </Typography>
            <a className={classes.telephone} href={`tel:${GENERAL_CONTACT_TEL_NUMBER}`}>
              {GENERAL_CONTACT_TEL_NUMBER}
            </a>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
