import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/redux/rootReducer';

export const selectFirstTime = (state: RootState) => state.firstTime;

export const selectIsFetchingFirstTime = createSelector(
  selectFirstTime,
  (firstTime) => firstTime.isFetching
);

export const selectCurrentFirstTimeScreen = createSelector(
  selectFirstTime,
  (firstTime) => firstTime.screen
);

export const selectFirstTimeFeatures = createSelector(
  selectFirstTime,
  (firstTime) => firstTime.features
);

export const selectNeedsToAcceptTAndC = createSelector(
  selectFirstTime,
  (firstTime) => firstTime.needsToAcceptTAndC
);

export const selectShouldDisplayTAndCDialog = createSelector(
  selectFirstTime,
  (firstTime) => firstTime.shouldDisplayTAndCDialog
);

export const selectPostponedAction = createSelector(
  selectFirstTime,
  (firstTime) => firstTime.postponedAction
);

export const selectIsEnablingProspect = createSelector(
  selectFirstTime,
  (firstTime) => firstTime.isEnablingProspect
);

export const selectIsEnablingTrial = createSelector(
  selectFirstTime,
  (firstTime) => firstTime.isEnablingTrial
);
