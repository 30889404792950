import { MapImplementationItems, MAP_IMPLEMENTATION } from 'app/config';
import { MapBridge } from './MapBridge';
import { MapBridgeMock } from './MapBridgeMock';

let mapBridge: MapBridge | MapBridgeMock;

export const getMapBridge = () => {
  // singleton
  if (mapBridge) return mapBridge;

  const isValidMapImplementationValue = Object.keys(MapImplementationItems).some(
    (key) =>
      MapImplementationItems[key as keyof typeof MapImplementationItems] === MAP_IMPLEMENTATION
  );

  if (!isValidMapImplementationValue) {
    const msg = 'Expected a valid REACT_APP_MAP_IMPLEMENTATION value';
    throw new Error(msg);
  }

  // IMPORTANT: For production environment it's important
  // to use the MapImplementationItems.device (the default)
  // The mock implementation mocks MAP authentication features
  if (MAP_IMPLEMENTATION === MapImplementationItems.mock) {
    mapBridge = new MapBridgeMock();
  } else if (MAP_IMPLEMENTATION === MapImplementationItems.autoDetect) {
    mapBridge = new MapBridge();
    // if we don't run inside a browser use the shim
    if (!mapBridge.isMapDevice()) {
      mapBridge = new MapBridgeMock();
    }
  } // default to device implementation
  else {
    mapBridge = new MapBridge();
  }

  return mapBridge;
};

// export { mapBridge };
