import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { MIN_SPLASH_MS } from 'app/config';
import { SplashScreen } from 'features/splashScreen/SplashScreen';
import { preloadImages } from 'common/utils/loadUtils';
import { selectMainView } from 'features/session/sessionSelectors';
import { MainView } from 'features/mainView/MainView';
import { MainViewElements } from 'features/session/sessionSlice';
import { selectIsClient } from 'features/entities/entitiesSelectors';

import SelectDomicileBg from 'common/assets/background-mountains.jpg';
import HomeBg from 'common/assets/jb-hq-background.jpg';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      flex: 1,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
    },
  })
);

/**
 * We display the splash screen for at least MIN_SPLASH_MS.
 * Then we have the following cases:
 *
 * a) If we have to display the first time features, we need to
 * make sure that the background images and the FirstTimeOrchestrator
 * component have loaded before transitioning to the FirstTimeOrchestrator
 *
 * b) otherwise we just transition immediately to the MainApp
 */

export const MainViewOrchestrator = () => {
  const classes = useStyles();
  const [hasDisplayedSplashScreenForMinTime, setHasDisplayedSplashScreenForMinTime] =
    useState(false);
  const [shouldHideSplashScreen, setShouldHideSplashScreen] = useState(false);
  const [areAssetsLoaded, setAreAssetsLoaded] = useState(false);

  const isClient = useSelector(selectIsClient);
  const currentView = useSelector(selectMainView);

  const firstTimeOrchestrator = useRef<any>();

  useEffect(() => {
    const loadFirstTimeAssets = async () => {
      // load FirstTimeOrchestrator component
      const firstTimeOrchestratorModule = await import(
        /* webpackChunkName: "first-time" */ 'features/firstTime/FirstTimeOrchestrator'
      );
      firstTimeOrchestrator.current = firstTimeOrchestratorModule.default;

      // load images so that they don't appear in a jerky fashion
      // and deteriorate the user experience
      const imageUrls = [SelectDomicileBg];
      await preloadImages(imageUrls);

      // ready
      setAreAssetsLoaded(true);

      // we will also start preloading the images for main but not act upon completion
      preloadImages([HomeBg]);
    };

    const loadMainAssets = async () => {
      // load images so that they don't appear in a jerky fashion
      // and deteriorate the user experience
      const imageUrls = isClient ? [HomeBg] : [SelectDomicileBg, HomeBg];
      await preloadImages(imageUrls);

      // ready
      setAreAssetsLoaded(true);
    };

    // we only preload assets once on startup
    if (currentView && !areAssetsLoaded) {
      if (
        currentView.screenType === MainViewElements.FirstTimeOrchestrator &&
        !firstTimeOrchestrator.current
      ) {
        loadFirstTimeAssets();
      } else {
        loadMainAssets();
      }
    }
  }, [currentView, areAssetsLoaded, isClient]);

  // Splash screen should be displayed for at least MIN_SPLASH_MS
  useEffect(() => {
    const timeoutId = setTimeout(() => setHasDisplayedSplashScreenForMinTime(true), MIN_SPLASH_MS);

    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    if (!currentView || !hasDisplayedSplashScreenForMinTime) {
      return setShouldHideSplashScreen(false);
    }

    if (areAssetsLoaded) {
      setShouldHideSplashScreen(true);
    }
  }, [hasDisplayedSplashScreenForMinTime, currentView, areAssetsLoaded]);

  return (
    <div className={classes.container}>
      <AnimatePresence>
        {shouldHideSplashScreen ? (
          currentView!.screenType === MainViewElements.FirstTimeOrchestrator ? (
            <firstTimeOrchestrator.current key="firstTimeOrchestrator" />
          ) : (
            <MainView key="mainView" />
          )
        ) : (
          <SplashScreen key="splashScreen" />
        )}
      </AnimatePresence>
    </div>
  );
};
