export const jbColors = {
  core: {
    reflexBlue: '#001489',
    royalBlue30: '#A8B0DE',
    stone: '#B0AA7E',
  },
  support: {
    royalBlue: '#141E55',
    royalBlue700: '#5B6288',
    greenSmoke: '#717C7D',
  },
  technical: {
    carmineRed: '#971B2F',
    forestGreen: '#008675',
  },
  app: {
    greyPrimary: '#333333',
    greyDark: '#828282',
    greyLight: '#BDBDBD',
    almostWhite: '#F6F6F6',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
};
