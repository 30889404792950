import { Symbol, IPluginRegistry, MessengerModule, IPluginProvider } from '@map/core';
import { BiometricStorage } from './BiometricStorage';
import { IBiometricStorage } from './IBiometricStorage';

/**
 * Defines the API with which you can include and obtain fingerprint services.
 */
export module BiometricStorageModule {
  /**
   * The error codes that can occur in the service.
   */
  export module Errors {
    const prefix = 'BiometricStorageError.';

    /**
     * Fingerprint key already exists.
     */
    export const keyExists = prefix + 'KeyExists';

    /**
     * Fingerprint is not supported on this device.
     */
    export const notSupported = prefix + 'NotSupported';

    /**
     * The fingerprint has not been initialized yet.
     */
    export const notInitialized = prefix + 'NotInitialized';

    /**
     * The user has not registered any fingerprints.
     */
    export const noEnrollments = prefix + 'NoEnrollments';

    /**
     * No entry exists for the given key.
     */
    export const noEntry = prefix + 'NoEntry';

    /**
     * The user did not grant access to fingerprint-identification.
     */
    export const permissionDenied = prefix + 'permissionDenied';

    /**
     * The fingerprint entry was canceled.
     */
    export const canceled = prefix + 'Canceled';

    /**
     * The biometric data has been changed. The storage can't be used anymore.
     */
    export const biometricDataInvalidated = prefix + 'BiometricDataInvalidated';

    /**
     * An unknown error occurred. Check the logs for more information.
     */
    export const unknown = prefix + 'Unknown';
  }

  /**
   * The symbol that uniquely identifies this service.
   */
  export const serviceSymbol = new Symbol('BiometricStorage');

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): IBiometricStorage => {
    return provider.resolve<IBiometricStorage>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);
    registrar.registerSingle(serviceSymbol, (p: IPluginProvider) => new BiometricStorage(MessengerModule.getService(p)), MessengerModule.serviceSymbol);
  };
}
