import { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useDispatch, useSelector } from 'react-redux';

import { openExternalServiceStart } from 'features/extNavigation/extNavigationSlice';
import { ActivityTypes } from 'features/entities/entitiesTypes';

import { useProductTour } from './useProductTour';
import { hideProductTour, updateProductTourViewedStart } from './productTourSlice';
import { selectShouldDisplayProductTour, selectServiceToOpenOnHide } from './productTourSelectors';
import { preloadImages } from 'common/utils/loadUtils';
import { theme } from '@el2/theme';
import { ThemeProvider } from '@mui/material';

type Props = {
  version: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      zIndex: theme.zIndex.modal,
    },
  })
);

export const ProductTourView = ({ version }: Props) => {
  const classes = useStyles();
  const [isProductTourLoaded, setIsProductTourLoaded] = useState(false);
  const productTour = useRef<any>();

  const { slideData, error } = useProductTour(version);
  const dispatch = useDispatch();
  const shouldDisplayProductTour = useSelector(selectShouldDisplayProductTour);
  const serviceToOpenOnHide = useSelector(selectServiceToOpenOnHide);

  // load ProductTour component
  useEffect(() => {
    const loadProductTour = async () => {
      const productTourModule = await import(
        /* webpackChunkName: "product-tour" */ '@el2/product-tour'
      );
      productTour.current = productTourModule.ProductTour;
      setIsProductTourLoaded(true);
    };
    loadProductTour();
  }, []);

  // preload gifs
  useEffect(() => {
    if (slideData) {
      try {
        const imageUrls: string[] = slideData.slides
          .filter((s) => !!(s.media && s.media.type === 'image/gif'))
          .map((s) => s.media!.url);

        imageUrls.length > 0 &&
          preloadImages(imageUrls)
            .then(() => console.log('preloaded product tour gifs'))
            .catch((err) => console.error(err));
      } catch (err) {
        console.error(err);
      }
    }
  }, [slideData]);

  // if there's an error with the product tour make sure we display
  // the service the user intended to launch
  useEffect(() => {
    if (shouldDisplayProductTour && error) {
      if (serviceToOpenOnHide) {
        dispatch(openExternalServiceStart(serviceToOpenOnHide));
      }
      dispatch(hideProductTour());
    }
  }, [error, dispatch, serviceToOpenOnHide, shouldDisplayProductTour]);

  // if something goes wrong we don't fail cause product-tour
  // is an optional feature
  if (!slideData || error) {
    return <></>;
  }

  const { introSlide, outroSlide, slides, labels } = slideData;

  const handleClose = () => {
    // let the backend know that the user viewed the product tour
    dispatch(
      updateProductTourViewedStart({
        activityType: ActivityTypes.PRODUCT_TOUR,
        productTourVersion: version,
      })
    );
    // when hiding the product tour we want to continue to the service
    // that corresponds to the card that the user clicked.
    if (serviceToOpenOnHide) {
      dispatch(openExternalServiceStart(serviceToOpenOnHide));
    }
    dispatch(hideProductTour());
  };

  const handleDismiss = () => {
    handleClose();
  };

  return (
    <div className={classes.container}>
      {/* Product tour needs @el2/theme */}
      <ThemeProvider theme={theme}>
        {isProductTourLoaded && (
          <productTour.current
            open={shouldDisplayProductTour}
            introSlide={introSlide}
            outroSlide={outroSlide}
            slides={slides}
            labels={labels}
            onClose={handleClose}
            onDismiss={handleDismiss}
          />
        )}
      </ThemeProvider>
    </div>
  );
};
