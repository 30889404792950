import { RequestMessage } from '../Messaging/RequestMessage';
import { MessageBase } from '../Messaging/MessageBase';

export const configurationMessageTag = 'ConfigurationMessage';

/**
 * A message which can be used to retrieve the current configuration from the native part.
 */
export class ConfigurationMessage extends RequestMessage {
  readonly tag = configurationMessageTag;
}

export const isConfigurationMessage = (message: MessageBase): message is ConfigurationMessage => {
  return message.tag === configurationMessageTag;
};