import { Symbol } from '../Core/Symbol';
import { IPluginRegistry } from '../Service/IPluginRegistry';
import { DialogService } from './DialogService';
import { MessengerModule } from '../Messaging/MessengerModule';
import { IPluginProvider } from '../Service/IPluginProvider';
import { IDialogService } from './IDialogService';

/**
 * Defines the API with which you can include and obtain dialog services.
 */
export module DialogModule {
  /**
   * The symbol that uniquely identifies this service.
   */
  export const serviceSymbol = new Symbol('DialogService');

  /**
   * The tag used to register a listener for confirmation requests.
   */
  export const confirmationMessageTag = 'ConfirmationMessage';

  /**
   * The tag used to register a listener for notification requests.
   */
  export const notificationMessageTag = 'NotificationMessage';

  /**
   * The tag used to register a listener for toast requests.
   */
  export const toastMessageTag = 'ToastMessage';

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): IDialogService => {
    return provider.resolve<IDialogService>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);
    registrar.registerSingle(serviceSymbol, (p) => new DialogService(MessengerModule.getService(p)), MessengerModule.serviceSymbol);
  };
}
