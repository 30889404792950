import { AnyAction } from '@reduxjs/toolkit';
import { Epic } from 'redux-observable';
import { filter, mergeMap } from 'rxjs/operators';

import { RootState } from 'app/redux/rootReducer';

import { store } from 'app/redux/store';
import {
  appIsVisible,
  openExternalServiceStart,
  openExternalServiceSuccess,
} from './extNavigationSlice';
import { fetchDashboardDataStart } from 'features/entities/entitiesSlice';
import { getMapBridge } from 'features/map';
import { updateDashboardActivityRequest } from 'features/entities/entitiesRequests';
import { ActivityTypes } from 'features/entities/entitiesTypes';

const handleVisibilityChange = () => {
  if (document.visibilityState === 'visible') {
    store.dispatch(appIsVisible());
  }
};

export const appIsVisibleEpic: Epic<AnyAction, AnyAction, RootState> = (action$) =>
  action$.pipe(
    filter(appIsVisible.match),
    mergeMap(() => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      // when we return from an SSO window we need to fetch the dashboard data again
      return [fetchDashboardDataStart({ shouldUpdateServiceProviders: true })];
    })
  );

export const openExternalServiceEpic: Epic<AnyAction, AnyAction, RootState> = (action$) =>
  action$.pipe(
    filter(openExternalServiceStart.match),
    mergeMap((action) => {
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // use MAP API to open a new WebView
      const service = action.payload;
      const mapBridge = getMapBridge();
      mapBridge.openPage(service.url);

      // we notify the backend of the activity but we do not
      // track whether this request was successful
      updateDashboardActivityRequest({
        activityType: ActivityTypes.EXTERNAL_NAVIGATION,
        serviceCode: service.code,
      });

      // when we return from an SSO window we need to fetch the dashboard data again
      return [openExternalServiceSuccess()];
    })
  );
