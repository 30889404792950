import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/redux/rootReducer';
import { ActualSupportedBiometricMethods } from 'features/entities/entitiesTypes';

export const selectSession = (state: RootState) => state.session;

export const selectDeviceSettings = createSelector(
  selectSession,
  (session) => session.deviceSettings
);

export const selectCertificateRenewalToken = createSelector(
  selectSession,
  (session) => session.certificateRenewalToken
);

export const selectInitializationToken = createSelector(
  selectSession,
  (session) => session.initializationToken
);

export const selectAuthenticationResult = createSelector(
  selectSession,
  (session) => session.authenticationResult
);

export const selectMainView = createSelector(selectSession, (session) => session.view);

export const selectIsLastVisitedScreenRestored = createSelector(
  selectSession,
  (session) => session.isLastVisitedScreenRestored
);

export const selectOSType = createSelector(
  selectSession,
  (session) => session.deviceSettings?.osType || 'Browser'
);

export const selectShouldDisplayUpdateScreen = createSelector(
  selectSession,
  (session) => !!session.shouldDisplayUpdateScreen
);

export const selectSupportedBiometricMethod = createSelector(
  selectSession,
  (session) => session.supportedBiometricMethod ?? ActualSupportedBiometricMethods.None
);
