import { RequestMessage } from '../Messaging/RequestMessage';
import { MessageBase } from '../Messaging/MessageBase';
import { DialogModule } from './DialogModule';

/**
 * A message to get a confirmation from the user.
 */
export class ConfirmationMessage extends RequestMessage {
  constructor(public title: string, public content: string) {
    super();
  }

  readonly tag = DialogModule.confirmationMessageTag;
}

export const isConfirmationMessage = (message: MessageBase): message is ConfirmationMessage => {
  return message.tag === DialogModule.confirmationMessageTag;
};