import { Symbol, OperationResponse, ValueResponse, IMessenger } from '@map/core';
import {
  IsBiometricSetMessage, SetBiometricValueMessage, GetBiometricValueMessage,
  IsBiometricValueSetMessage, ResetBiometricMessage, SetBiometricMessage, GetSupportedMethodMessage,
  GetSupportedMethodResponse
} from './BiometricStorageMessages';
import { IBiometricStorage, SupportedBiometricMethod } from './IBiometricStorage';
import { BiometricStorageModule } from './BiometricStorageModule';

/**
 * The standard implementation of the IBiometricStorage.
 */
export class BiometricStorage implements IBiometricStorage {
  constructor(private messenger: IMessenger) {
  }

  readonly getSupportedBiometricMethod = async (): Promise<SupportedBiometricMethod> => {
    const response: GetSupportedMethodResponse = await this.messenger.send(new GetSupportedMethodMessage());
    return response.supportedMethod;
  }

  readonly isBiometricStorageSet = async (): Promise<boolean> => {
    const response: OperationResponse = await this.messenger.send(new IsBiometricSetMessage());
    return response.success;
  }

  readonly setupBiometricStorage = async (): Promise<boolean> => {
    const response: OperationResponse = await this.messenger.send(new SetBiometricMessage());
    return response.success;
  }

  readonly reset = (): Promise<any> => {
    return this.messenger.send(new ResetBiometricMessage());
  }

  readonly isValueSet = async (entry: string): Promise<boolean> => {
    const response: OperationResponse = await this.messenger.send(new IsBiometricValueSetMessage(entry));
    return response.success;
  }

  readonly setValue = async (entry: string, value: string): Promise<boolean> => {
    const response: OperationResponse = await this.messenger.send(new SetBiometricValueMessage(entry, btoa(value)));
    return response.success;
  }

  readonly getValue = async (entry: string): Promise<string> => {
    const response: ValueResponse<string> = await this.messenger.send(new GetBiometricValueMessage(entry));
    if (response.value !== null) {
      return atob(response.value.toString());
    }
    return '';
  }

  readonly symbol: Symbol = BiometricStorageModule.serviceSymbol;
}
