import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';

import { ServiceCodes, ServiceProvider } from 'features/entities/entitiesTypes';
import { openExternalServiceStart } from 'features/extNavigation/extNavigationSlice';
import { Dictionary } from 'common/types';
import { selectProductTourVersion } from 'features/productTour/productTourSelectors';
import { displayProductTour } from 'features/productTour/productTourSlice';

import { ReactComponent as GermanyGraphic } from './graphics/germany.svg';
import { ReactComponent as HongKongGraphic } from './graphics/hong-kong.svg';
import { ReactComponent as IndiaGraphic } from './graphics/india.svg';
import { ReactComponent as SingaporeGraphic } from './graphics/singapore.svg';
// lsbApac-BetaApps.svg this SVG is empty since there is no artwork
import { ReactComponent as LsbApacBetaAppGraphic } from './graphics/lsbApac-BetaApps.svg';
import { ReactComponent as SwitzerlandGraphic } from './graphics/switzerland.svg';
import { ReactComponent as EuropeGraphic } from './graphics/europe.svg';
import { ReactComponent as MonacoGraphic } from './graphics/monaco.svg';

import overlayPattern from './graphics/overlay.svg';
import { jbColors } from 'app/theme/jbColors';

type Props = {
  size?: 'normal' | 'small';
  service: ServiceProvider;
  isNew?: boolean;
};

type ServiceCardInfo = {
  Graphic: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  top: number;
  right: number;
  width: number;
  serviceName: string;
  serviceRegion: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      position: 'relative',
      marginBottom: theme.spacing(0.5),
      background: 'linear-gradient(104.22deg, #273065 -11.4%, #141E55 84.45%)',
      boxShadow:
        '0px 6px 10px rgba(20, 30, 85, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px -1px rgba(20, 30, 85, 0.1)',
      borderRadius: 5,
      width: 224,
      '@media (min-width: 360px)': {
        width: 270,
      },
      '@media (min-width: 481px)': {
        width: 360,
      },
    },

    link: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textDecoration: 'none',
      color: 'inherit',
      overflow: 'hidden',
      position: 'relative',
    },

    normal: {
      minHeight: 132,
      backgroundImage: `url("${overlayPattern}")`,
      '@media (min-width: 360px)': {
        minHeight: 160,
      },
      '@media (min-width: 481px)': {
        minHeight: 213,
        padding: `${theme.spacing(3.5)} ${theme.spacing(4)} ${theme.spacing(3)}`,
      },
      padding: theme.spacing(2.5),
    },

    small: {
      minHeight: 56,
      padding: `${theme.spacing(1.25)} ${theme.spacing(2.5)}`,
      '@media (min-width: 360px)': {
        minHeight: 72,
        padding: `${theme.spacing(2.5)} ${theme.spacing(2.5)} ${theme.spacing(2)}`,
      },
      '@media (min-width: 481px)': {
        minHeight: 96,
        padding: `${theme.spacing(3.5)} ${theme.spacing(4)} ${theme.spacing(3)}`,
      },
      '& $serviceName': {
        fontSize: theme.typography.pxToRem(10),
        lineHeight: 1.6,
        letterSpacing: '0.08em',
      },
      '& $serviceRegion': {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 1.43,
        letterSpacing: '0.08em',
        marginTop: 0,
      },
    },

    graphic: {
      position: 'absolute',
      color: '#6672B8',
    },

    tag: {
      position: 'absolute',
      color: theme.palette.common.white,
      background: jbColors.support.royalBlue700,
      borderRadius: '0px 4px 4px 0px',
      padding: `0 ${theme.spacing(1)} 0 ${theme.spacing(2)}`,
    },

    tagLeft: {
      bottom: 32,
      left: 0,
    },

    tagRight: {
      bottom: 14,
      right: 0,
    },

    serviceName: {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.67,
      letterSpacing: '0.08em',
      textTransform: 'uppercase',
    },

    serviceRegion: {
      color: theme.palette.common.white,
      marginTop: theme.spacing(0.25),
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.37,
      letterSpacing: '0.04em',
      textTransform: 'uppercase',
      '@media (min-width: 360px)': {
        fontSize: theme.typography.pxToRem(20),
        lineHeight: 1.3,
      },
    },
  })
);

export const EBankingServiceCard = forwardRef<HTMLLIElement, Props>(
  ({ isNew, service, size = 'normal' }, ref) => {
    const classes = useStyles();
    const isLargeWidth = useMediaQuery('(min-width:360px)');
    const isTabletWidth = useMediaQuery('(min-width:481px)');

    const productTourVersion = useSelector(selectProductTourVersion);

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      // check for Swiss clients if we need to display product tour

      if (
        productTourVersion &&
        (service.code === ServiceCodes.EBK || service.code === ServiceCodes.EBK_R20)
      ) {
        dispatch(displayProductTour(service));
      } else {
        dispatch(openExternalServiceStart(service));
      }
    };

    const serviceInfoList: Dictionary<ServiceCardInfo> = {
      [ServiceCodes.ESE_LU]: {
        Graphic: EuropeGraphic,
        top: 20,
        right: -20,
        width: 99,
        serviceName: t('home.labels.cards.europeLegacy.serviceName'),
        serviceRegion: t('home.labels.cards.europeLegacy.serviceRegion'),
      },
      [ServiceCodes.DIG_HK]: {
        Graphic: HongKongGraphic,
        top: 17,
        right: 22,
        width: 76,
        serviceName: t('home.labels.cards.hongKong.serviceName'),
        serviceRegion: t('home.labels.cards.hongKong.serviceRegion'),
      },
      [ServiceCodes.DIG_HK_LEGACY]: {
        Graphic: HongKongGraphic,
        top: 17,
        right: 22,
        width: 76,
        serviceName: t('home.labels.cards.hongKongLegacy.serviceName'),
        serviceRegion: t('home.labels.cards.hongKongLegacy.serviceRegion'),
      },
      [ServiceCodes.INO2]: {
        Graphic: IndiaGraphic,
        top: 35,
        right: -22,
        width: 146,
        serviceName: t('home.labels.cards.india.serviceName'),
        serviceRegion: t('home.labels.cards.india.serviceRegion'),
      },
      [ServiceCodes.ESE_MC]: {
        Graphic: MonacoGraphic,
        top: 35,
        right: -26,
        width: 167,
        serviceName: t('home.labels.cards.monacoLegacy.serviceName'),
        serviceRegion: t('home.labels.cards.monacoLegacy.serviceRegion'),
      },
      [ServiceCodes.KOS]: {
        Graphic: GermanyGraphic,
        top: 65,
        right: 22,
        width: 86,
        serviceName: t('home.labels.cards.germany.serviceName'),
        serviceRegion: t('home.labels.cards.germany.serviceRegion'),
      },
      [ServiceCodes.EL2_LU]: {
        Graphic: EuropeGraphic,
        top: 19,
        right: 0,
        width: 82,
        serviceName: t('home.labels.cards.europe.serviceName'),
        serviceRegion: t('home.labels.cards.europe.serviceRegion'),
      },
      [ServiceCodes.EL2_MC]: {
        Graphic: MonacoGraphic,
        top: 35,
        right: 0,
        width: 140,
        serviceName: t('home.labels.cards.monaco.serviceName'),
        serviceRegion: t('home.labels.cards.monaco.serviceRegion'),
      },
      [ServiceCodes.EBG_GG]: {
        Graphic: EuropeGraphic, // FIXME needs a new .svg
        top: 35,
        right: 0,
        width: 140,
        serviceName: t('home.labels.cards.guernsey.serviceName'),
        serviceRegion: t('home.labels.cards.guernsey.serviceRegion'),
      },
      [ServiceCodes.EBP_CH]: {
        Graphic: SwitzerlandGraphic,
        top: 35,
        right: 0,
        width: 140,
        serviceName: t('home.labels.cards.switzerland.serviceName'),
        serviceRegion: t('home.labels.cards.switzerland.serviceRegion'),
      },
      [ServiceCodes.DIG_SG]: {
        Graphic: SingaporeGraphic,
        top: 37,
        right: -10,
        width: 127,
        serviceName: t('home.labels.cards.singapore.serviceName'),
        serviceRegion: t('home.labels.cards.singapore.serviceRegion'),
      },
      [ServiceCodes.DIG_SG_LEGACY]: {
        Graphic: SingaporeGraphic,
        top: 37,
        right: -10,
        width: 127,
        serviceName: t('home.labels.cards.singaporeLegacy.serviceName'),
        serviceRegion: t('home.labels.cards.singaporeLegacy.serviceRegion'),
      },
      [ServiceCodes.EBK]: {
        Graphic: SwitzerlandGraphic,
        top: 20,
        right: -18,
        width: 133,
        serviceName: t('home.labels.cards.switzerland.serviceName'),
        serviceRegion: t('home.labels.cards.switzerland.serviceRegion'),
      },
      [ServiceCodes.EBK_R20]: {
        Graphic: SwitzerlandGraphic,
        top: 20,
        right: -18,
        width: 133,
        serviceName: t('home.labels.cards.switzerland.serviceName'),
        serviceRegion: t('home.labels.cards.switzerland.serviceRegion'),
      },
      [ServiceCodes.LSB_APAC_MOBILE]: {
        Graphic: LsbApacBetaAppGraphic,
        top: 35,
        right: -22,
        width: 146,
        serviceName: t('home.labels.cards.lsbApac.serviceName'),
        serviceRegion: t('home.labels.cards.lsbApac.serviceRegion'),
      },
    };

    const { Graphic, top, right, width, serviceName, serviceRegion } =
      serviceInfoList[service.code];
    const scale = isTabletWidth ? 1.333 : isLargeWidth ? 1 : 0.83;

    return (
      <li className={classes.container} ref={ref}>
        <a
          className={clsx(classes.link, size === 'normal' ? classes.normal : classes.small)}
          href={service.url}
          onClick={handleClick}
        >
          {size === 'normal' && (
            <Graphic
              className={classes.graphic}
              style={{
                width: scale * width,
                height: 'auto',
                top: scale * top,
                right: scale * right,
              }}
            />
          )}

          <Typography component="div" className={classes.serviceName}>
            {serviceName}
          </Typography>
          <Typography variant="h2" component="div" className={classes.serviceRegion}>
            {serviceRegion}
          </Typography>
          {isNew && (
            <Typography
              component="div"
              className={clsx(classes.tag, size === 'normal' ? classes.tagLeft : classes.tagRight)}
            >
              {t('home.labels.new')}
            </Typography>
          )}
        </a>
      </li>
    );
  }
);
