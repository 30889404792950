import { IMessenger, Symbol } from '@map/core';
import { QRScanRequestMessage } from './QRScanRequestMessage';
import { QRScanResponse } from './QRScanResponse';
import { IQRScannerService } from './IQRScannerService';
import { QRScannerModule } from './QRScannerModule';

/**
 * A service which scans QR codes.
 */
export class QRScannerService implements IQRScannerService {
  constructor(private messenger: IMessenger) {
  }

  /**
   * Scan the QR code.
   *
   * @return the promise, on what the callbacks about the scan results are delivered.
   */
  readonly scanQRCode = (): Promise<QRScanResponse> => {
    return this.messenger.send(new QRScanRequestMessage());
  }

  readonly symbol: Symbol = QRScannerModule.serviceSymbol;
}
