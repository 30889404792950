// This was the default in MUI v4
// Consider remove it in the future
const MuiCssBaseline = {
  styleOverrides: {
    body: {
      fontSize: '0.875rem',
      lineHeight: 1.43,
    },
  },
};

export { MuiCssBaseline };
