import { ILogger } from './ILogger';
import { LogLevel } from './LogLevel';
import { Symbol } from '../Core/Symbol';
import { LoggingModule } from './LoggingModule';

/**
 * The base class of the loggers which can be used to implement a new default logger.
 */
export abstract class BaseLogger implements ILogger {
  abstract log: (message: string, level: LogLevel) => void;

  readonly symbol: Symbol = LoggingModule.serviceSymbol;

  logDebug = (message: string) => {
    this.log(message, LogLevel.Debug);
  }

  logWarning = (message: string) => {
    this.log(message, LogLevel.Warning);
  }

  logError = (message: string) => {
    this.log(message, LogLevel.Error);
  }
}
