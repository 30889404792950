import React, { forwardRef } from 'react';
import { Theme, Portal } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { motion } from 'framer-motion';
import clsx from 'clsx';

import { EASING_BEZIER } from 'app/config';

import { jbColors } from 'app/theme/jbColors';

type Props = {
  className?: string;
  open?: boolean;
  children: any;
  slideFrom?: 'bottom' | 'right';
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: jbColors.support.royalBlue,
      color: theme.palette.common.white,
      overflowY: 'auto',
      '-webkit-overflow-scrolling': 'touch',
      zIndex: 1,
    },
  })
);

export const FullScreenDialog = forwardRef<HTMLDivElement, Props>(
  ({ className, open = false, slideFrom = 'bottom', children }, ref) => {
    const classes = useStyles();

    const variants = {
      bottom: { show: { translateY: 0 }, hide: { translateY: window.innerHeight } },
      right: { show: { translateX: 0 }, hide: { translateX: window.innerWidth } },
    };

    return (
      <Portal container={document.body}>
        <motion.div
          variants={variants[slideFrom]}
          initial={false}
          animate={open ? 'show' : 'hide'}
          transition={{ ease: EASING_BEZIER, duration: 0.25 }}
          className={clsx(classes.container, className)}
          ref={ref}
        >
          <div>{children}</div>
        </motion.div>
      </Portal>
    );
  }
);
