import { RequestMessage } from '../Messaging/RequestMessage';
import { MessageBase } from '../Messaging/MessageBase';
import { TranslationModule } from './TranslationModule';

/**
 * A message which can be used to retrieve all translations.
 */
export class TranslationMessage extends RequestMessage {
  readonly tag = TranslationModule.translationMessageTag;
}

export const isTranslationMessage = (message: MessageBase): message is TranslationMessage => {
  return message.tag === TranslationModule.translationMessageTag;
};