import { Symbol, IMessenger, EmptyResponse, ValueResponse } from '@map/core';
import { DeviceAuthenticatorModule } from './DeviceAuthenticatorModule';
import { IDeviceAuthenticator } from './IDeviceAuthenticator';
import { DeviceAuthenticationMessage } from './DeviceAuthenticationMessage';
import { DeviceActivationMessage } from './DeviceActivationMessage';
import { AuthenticatorResponse } from './AuthenticatorResponse';
import { DeleteBindingsMessage } from './DeleteBindingsMessage';
import { DeviceAuthenticatorHasBindingMessage } from './DeviceAuthenticatorHasBindingMessage';
import { ServerKeyRenewMessage } from './ServerKeyRenewMessage';

export class DeviceAuthenticator implements IDeviceAuthenticator {
  constructor(private messenger: IMessenger) {
  }

  readonly authenticate = (jwt: string): Promise<AuthenticatorResponse> => {
    return this.messenger.send(new DeviceAuthenticationMessage(jwt));
  }

  readonly renewServerKey = (jwt: string): Promise<AuthenticatorResponse> => {
    return this.messenger.send(new ServerKeyRenewMessage(jwt));
  }

  readonly activate = (jwt: string): Promise<AuthenticatorResponse> => {
    return this.messenger.send(new DeviceActivationMessage(jwt));
  }

  readonly deleteBindings = (): Promise<EmptyResponse> => {
    return this.messenger.send(new DeleteBindingsMessage());
  }

  readonly hasBinding = async (): Promise<boolean> => {
    const response: ValueResponse<boolean> = await this.messenger.send(new DeviceAuthenticatorHasBindingMessage());
    return response.value;
  }

  readonly symbol: Symbol = DeviceAuthenticatorModule.serviceSymbol;
}
