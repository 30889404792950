import { Dictionary } from 'common/types';

export const contactFormLinks: Dictionary<string> = {
  en:
    'https://www.juliusbaer.com/en/contact/forms/general-enquiries/?utm_source=member-zone&utm_medium=app&utm_campaign=app-visits',
  de:
    'https://www.juliusbaer.com/de/kontakt/forms/general-enquiries/?utm_source=member-zone&utm_medium=app&utm_campaign=app-visits',
  fr:
    'https://www.juliusbaer.com/fr/contacts/forms/general-enquiries/?utm_source=member-zone&utm_medium=app&utm_campaign=app-visits',
  it:
    'https://www.juliusbaer.com/it/contatti/forms/general-enquiries/?utm_source=member-zone&utm_medium=app&utm_campaign=app-visits',
  es:
    'https://www.juliusbaer.com/es/contacto/forms/general-enquiries/?utm_source=member-zone&utm_medium=app&utm_campaign=app-visits',
};
