import { Symbol } from '../Core/Symbol';
import { ITranslationProvider } from './ITranslationProvider';
import { TranslationModule } from './TranslationModule';

export type ModuleKeyValueTable = { [module: string]: { [key: string]: string } };

/**
 * A service that provides translations obtained from the native layer.
 */
export class TranslationProvider implements ITranslationProvider {
  /**
   * Gets the current language.
   *
   * @returns {string} the current language.
   */
  readonly getActiveLanguage = () => {
    return this.activeLanguage;
  }

  /**
   * Get a translation for a key.
   *
   * @param module - the name of the module within which to find the key
   * @param key - the key to translate.
   * @returns {string} - the translated value.
   */
  readonly get = (module: string, key: string) => {
    return this.translations[module][key];
  }

  readonly set = (data: ModuleKeyValueTable, activeLanguage: string) => {
    this.translations = data;
    this.activeLanguage = activeLanguage;
  }

  readonly symbol: Symbol = TranslationModule.serviceSymbol;

  private activeLanguage!: string;
  private translations!: ModuleKeyValueTable;
}
