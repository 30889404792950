import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, alpha, IconButton, Theme, Typography, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { motion } from 'framer-motion';

import { selectServiceInfoGroups } from 'features/entities/entitiesSelectors';
import { getGroupLabels, getNameLabels } from 'common/utils/contactsUtils';
import { EASING_BEZIER } from 'app/config';
import { jbColors } from 'app/theme/jbColors';

import { ReactComponent as ForwardArrowIcon } from 'common/assets/forward-arrow-icon.svg';
import { ReactComponent as CloseIcon } from 'common/assets/close-icon.svg';

type Props = {
  onBack?: () => void;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      width: '100%',
      minHeight: '100%',
      backgroundColor: jbColors.support.royalBlue,
      color: theme.palette.common.white,
      top: 0,
      left: 0,
      zIndex: 2,
      textAlign: 'initial',
    },

    toolbar: {
      marginTop: theme.spacing(0.5),
      display: 'grid',
      gridTemplateColumns: '60px 1fr 60px',
      alignItems: 'center',
    },

    backIconButton: {
      gridColumn: '1 / 2',
      gridRowStart: 1,
      width: 48,
      justifySelf: 'flex-end',
    },

    backIcon: {
      transform: 'rotate(180deg)',
      justifySelf: 'flex-end',
    },

    menuTitle: {
      gridColumn: '2 / 3',
      gridRowStart: 1,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.5,
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'center',
    },

    closeIconButton: {
      gridColumn: '3 / 4',
      gridRowStart: 1,
      width: 48,
    },

    title: {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1,
    },

    groupsList: {
      marginTop: theme.spacing(1.5),
      padding: 0,
      listStyle: 'none',
    },

    groupItem: {
      padding: `${theme.spacing(3)} 0 ${theme.spacing(4)}`,
      borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.4)}`,
    },

    groupTypeLabel: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.333,
      letterSpacing: 0.2,
    },

    numbersList: {
      marginTop: theme.spacing(1),
      padding: 0,
      listStyle: 'none',
    },

    numberInfo: {
      marginTop: theme.spacing(2),
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.43,
      letterSpacing: 0.2,
    },

    numberLink: {
      color: 'inherit',
      textDecoration: 'none',
      borderBottom: `1px solid ${alpha(theme.palette.common.white, 0.3)}`,
    },

    textLinkContainer: {
      marginTop: theme.spacing(2),
    },

    textLink: {
      color: 'inherit',
      letterSpacing: 0.2,
    },
  })
);

export const SupportMenu = ({ onBack, onClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();

  const serviceInfoGroups = useSelector(selectServiceInfoGroups);

  const groupLabels = getGroupLabels();
  const nameLabels = getNameLabels();

  return (
    // TODO refactor the motion.div of each menu to a separate component
    <motion.div
      className={classes.container}
      key="SupportMenu"
      initial={{ x: window.innerWidth }}
      animate={{ x: 0 }}
      exit={{ x: window.innerWidth }}
      transition={{ ease: EASING_BEZIER, duration: 0.2 }}
    >
      <div className={classes.toolbar}>
        {onBack && (
          <IconButton onClick={onBack} className={classes.backIconButton} size="large">
            <ForwardArrowIcon className={classes.backIcon} />
          </IconButton>
        )}

        <Typography variant="h1" component="div" className={classes.menuTitle}>
          {t('menu.title')}
        </Typography>
        <IconButton onClick={onClose} className={classes.closeIconButton} size="large">
          <CloseIcon />
        </IconButton>
      </div>

      <Box maxWidth={472} mx="auto" mt={2.5} p={3} bgcolor={jbColors.support.royalBlue}>
        <Typography variant="h1" className={classes.title}>
          {t('supportMenu.title')}
        </Typography>

        <ul className={classes.groupsList}>
          {serviceInfoGroups.map((group) => (
            <li key={`${group.groupType}-list`} className={classes.groupItem}>
              <Typography variant="h2" className={classes.groupTypeLabel}>
                {groupLabels[group.groupType]}
              </Typography>

              <ul className={classes.numbersList}>
                {group.serviceInfo.map((supportNumber, idx) => (
                  <Typography
                    key={`${group.groupType}-${supportNumber.name}-${idx}`}
                    component="li"
                    className={classes.numberInfo}
                  >
                    {nameLabels[supportNumber.name]}:{' '}
                    <a className={classes.numberLink} href={`tel:${supportNumber.number}`}>
                      {supportNumber.number}
                    </a>
                  </Typography>
                ))}
              </ul>

              {group.openingHours && (
                <Typography variant="body2" className={classes.numberInfo}>
                  {group.openingHours}
                </Typography>
              )}

              {(group.imprintUrl || group.privacyPolicyUrl) && (
                <div className={classes.textLinkContainer}>
                  {group.imprintUrl && (
                    <Typography
                      component="a"
                      href={group.imprintUrl}
                      target="_blank"
                      variant="body2"
                      className={classes.textLink}
                      color="primary"
                    >
                      {t('common.serviceInfo.actions.imprint')}
                    </Typography>
                  )}

                  {group.privacyPolicyUrl && (
                    <Typography
                      component="a"
                      href={group.privacyPolicyUrl}
                      target="_blank"
                      variant="body2"
                      className={classes.textLink}
                      color="primary"
                      style={{ marginLeft: group.imprintUrl ? theme.spacing(4) : 0 }}
                    >
                      {t('common.serviceInfo.actions.privacyNotice')}
                    </Typography>
                  )}
                </div>
              )}
            </li>
          ))}
        </ul>
      </Box>
    </motion.div>
  );
};
