import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, List, ListItem, Typography, Theme, alpha } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import { AnimatePresence, motion } from 'framer-motion';

import { jbColors } from 'app/theme/jbColors';
import { EASING_BEZIER } from 'app/config';
import { ReactComponent as CloseIcon } from 'common/assets/close-icon.svg';
import { openExternalServiceStart } from 'features/extNavigation/extNavigationSlice';
import { PaymentScanner } from 'features/paymentScanner/PaymentScanner';

import { DeviceProperties } from './DeviceProperties';
import { SupportMenu } from './SupportMenu';
import { selectSettingsGroups, selectServicesGroups } from './menuSelectors';
import { isServiceSettingEntry } from './menuGroups';

import { ReactComponent as RightArrowIcon } from './right-arrow-icon.svg';

type Props = {
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    nav: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: jbColors.support.royalBlue,
      top: 0,
      left: 0,
      textAlign: 'initial',
    },

    toolbar: {
      marginTop: theme.spacing(0.5),
      display: 'grid',
      gridTemplateColumns: '1fr 60px',
      alignItems: 'center',
    },

    title: {
      gridColumn: '1 / 3',
      gridRowStart: 1,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.5,
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'center',
    },

    closeIconButton: {
      gridColumn: '2 / 3',
      gridRowStart: 1,
      width: 48,
    },

    content: {
      marginTop: theme.spacing(6),
    },

    sectionTitle: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 2,
      letterSpacing: 1.5,
      textTransform: 'uppercase',
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(3),
      color: '#EEEEE5',
      opacity: 0.7,
    },
    groupContainer: {
      marginTop: theme.spacing(2),
    },
    groupTitle: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      padding: `${theme.spacing(1.25)} ${theme.spacing(3)}`,
      color: '#EEEEE5',
    },
    list: {
      padding: 0,
    },
    entry: {
      display: 'flex',
      justifyContent: 'space-between',
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      letterSpacing: -0.2,
      padding: `${theme.spacing(1.25)} ${theme.spacing(3)}`,
    },
    arrow: {
      color: alpha(theme.palette.common.white, 0.7),
    },
    link: {
      color: 'inherit',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
    },
    hr: {
      marginTop: theme.spacing(2),
      marginBottom: 0,
      marginLeft: theme.spacing(3),
      border: 'none',
      borderTop: `1px solid ${alpha(theme.palette.common.white, 0.4)}`,
    },
  })
);

const settingsGroupComponentLookupTable: any = {
  DeviceProperties,
  PaymentScanner,
  SupportMenu,
};

export const Menu = ({ onClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const settingsGroups = useSelector(selectSettingsGroups);
  const servicesGroups = useSelector(selectServicesGroups);
  const [settingsGroupComponent, setSettingsGroupComponent] = useState<string | null>();

  // check if we need to display a setting submenu
  const SettingsView = settingsGroupComponent
    ? settingsGroupComponentLookupTable[settingsGroupComponent]
    : null;

  return (
    <AnimatePresence initial={false}>
      {SettingsView ? (
        <SettingsView
          onBack={() => setSettingsGroupComponent(null)}
          onClose={() => {
            // TODO make exit animation better
            setSettingsGroupComponent(null);
            onClose();
          }}
        />
      ) : (
        <motion.nav
          key="Settings"
          initial={{ x: -80 }}
          animate={{ x: 0 }}
          exit={{ x: -80 }}
          transition={{ ease: EASING_BEZIER, duration: 0.2 }}
          className={classes.nav}
        >
          <div className={classes.toolbar}>
            <Typography variant="h1" component="div" className={classes.title}>
              {t('menu.title')}
            </Typography>
            <IconButton onClick={onClose} className={classes.closeIconButton} size="large">
              <CloseIcon />
            </IconButton>
          </div>

          <Box maxWidth={472} mx="auto">
            {/* Services Section */}
            {servicesGroups && (
              <Box mt={6}>
                <Typography variant="h2" className={classes.sectionTitle}>
                  {t('menu.services.title')}
                </Typography>

                {servicesGroups.map((group, idx) => (
                  <div className={classes.groupContainer} key={`settings-group-${idx}`}>
                    {group.title && (
                      <Typography variant="h2" className={classes.groupTitle}>
                        {group.title}
                      </Typography>
                    )}

                    <List aria-label={t('menu.labels.servicesMenu')} className={classes.list}>
                      {group.entries.map((entry, idx) =>
                        isServiceSettingEntry(entry) ? (
                          <ListItem
                            key={`service-entry-${idx}`}
                            className={classes.entry}
                            button
                            component="li"
                            disableGutters
                          >
                            <a
                              className={classes.link}
                              href={entry.service.url}
                              rel="noreferrer"
                              target="_blank"
                              onClick={(e) => {
                                e.preventDefault();
                                dispatch(openExternalServiceStart(entry.service));
                              }}
                            >
                              {entry.label}
                            </a>
                          </ListItem>
                        ) : (
                          <ListItem
                            key={`service-entry-${idx}`}
                            className={classes.entry}
                            button
                            component="li"
                            disableGutters
                            onClick={() => setSettingsGroupComponent(entry.value)}
                          >
                            <span>{entry.label}</span>
                            <RightArrowIcon className={classes.arrow} />
                          </ListItem>
                        )
                      )}
                    </List>
                  </div>
                ))}
                <hr className={classes.hr} />
              </Box>
            )}

            {/* Settings Section */}

            <Typography variant="h2" className={classes.sectionTitle}>
              {t('menu.settings.title')}
            </Typography>

            {settingsGroups.map((group, idx) => (
              <div className={classes.groupContainer} key={`settings-group-${idx}`}>
                {group.title && (
                  <Typography variant="h2" className={classes.groupTitle}>
                    {group.title}
                  </Typography>
                )}

                <List aria-label={t('menu.labels.settingsMenu')}>
                  {group.entries.map((entry, idx) =>
                    isServiceSettingEntry(entry) ? (
                      <ListItem
                        key={`settings-entry-${idx}`}
                        className={classes.entry}
                        button
                        component="li"
                        disableGutters
                      >
                        <a
                          className={classes.link}
                          href={entry.service.url}
                          rel="noreferrer"
                          target="_blank"
                          onClick={(e) => {
                            e.preventDefault();
                            dispatch(openExternalServiceStart(entry.service));
                          }}
                        >
                          {entry.label}
                        </a>
                      </ListItem>
                    ) : (
                      <ListItem
                        key={`settings-entry-${idx}`}
                        className={classes.entry}
                        button
                        component="li"
                        disableGutters
                        onClick={() => setSettingsGroupComponent(entry.value)}
                      >
                        <span>{entry.label}</span>
                        <RightArrowIcon className={classes.arrow} />
                      </ListItem>
                    )
                  )}
                </List>
              </div>
            ))}
          </Box>
        </motion.nav>
      )}
    </AnimatePresence>
  );
};
