import { Symbol } from '../Core/Symbol';
import { IPluginRegistry } from '../Service/IPluginRegistry';
import { IPluginProvider } from '../Service/IPluginProvider';
import { MessengerModule } from '../Messaging/MessengerModule';
import { INetworkService } from './INetworkService';
import { NetworkService } from './NetworkService';

/**
 * Defines the API with which you can include and obtain a translation provider.
 */
export module NetworkModule {
  /**
   * The symbol that uniquely identifies this service.
   */
  export const serviceSymbol = new Symbol('NetworkService');

  /**
   * The tag used to register a listener for when the network is tested.
   */
  export const checkNetworkMessageTag = 'CheckNetworkMessage';

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): INetworkService => {
    return provider.resolve<INetworkService>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);

    registrar
      .registerSingle(serviceSymbol, (p) => new NetworkService(MessengerModule.getService(p)));
  };
}