import { RequestMessage } from '@map/core';
import { DeviceAuthenticatorModule } from './DeviceAuthenticatorModule';

/**
 * Device activation message
 */
export class DeviceActivationMessage extends RequestMessage {
  constructor(public jwt: string) {
    super();
  }

  readonly tag: string = DeviceAuthenticatorModule.activationRequestMessageTag;
}
