import { MessageBase } from '../Messaging/MessageBase';
import { RequestMessage } from '../Messaging/RequestMessage';
import { DialogModule } from './DialogModule';

/**
 * Message to display a notification message.
 */
export class NotificationMessage extends RequestMessage {
  constructor(public title: string, public content: string) {
    super();
  }
  readonly tag: string = DialogModule.notificationMessageTag;
}

export const isNotificationMessage = (message: MessageBase): message is NotificationMessage => {
  return message.tag === DialogModule.notificationMessageTag;
};