import { DeviceProperties } from '../Device/DeviceProperties';
import { Platform } from '../Device/IDeviceProperties';

export class MockDeviceProperties extends DeviceProperties {
  readonly getPlatform: () => Platform = () => {
    return this.platform;
  }

  platform: Platform = 'Android';
}
