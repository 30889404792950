import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Box, Theme, Typography, useTheme } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { appStoreLinks } from 'app/config';
import { selectOSType } from 'features/session/sessionSelectors';

import { ReactComponent as UpdateScreenArtwork } from './udpate-screen-artwork.svg';
import { ReactComponent as GooglePlayStoreGraphic } from './google-play-store-graphic.svg';
import { ReactComponent as AppleAppStoreGraphic } from './apple-app-store-graphic.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(3),
      fontSize: theme.typography.pxToRem(16),
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1.3,
      textAlign: 'center',
    },

    hint: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(14),
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: 1.3,
      textAlign: 'center',
    },

    storeLink: {
      display: 'block',
      textDecoration: 'none',
    },

    storeGraphic: {
      height: 40,
      width: 'auto',
    },
  })
);

export const UpdateScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const osType = useSelector(selectOSType);

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex="1"
      overflow="hidden"
      bgcolor={theme.palette.common.white}
    >
      <Box width={255} textAlign="center">
        <UpdateScreenArtwork />
        <Typography variant="body1" component="div" className={classes.title}>
          {t('update.title')}
        </Typography>
        <Typography variant="body2" component="div" className={classes.hint}>
          {t('update.labels.hint')}
        </Typography>

        <Box mt={13}>
          {osType === 'Android' ? (
            <a className={classes.storeLink} href={appStoreLinks.Android}>
              <GooglePlayStoreGraphic className={classes.storeGraphic} />
            </a>
          ) : (
            osType === 'iOS' && (
              <a className={classes.storeLink} href={appStoreLinks.iOS}>
                <AppleAppStoreGraphic className={classes.storeGraphic} />
              </a>
            )
          )}
        </Box>
      </Box>
    </Box>
  );
};
