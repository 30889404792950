import React from 'react';
import { Box } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { Toolbar } from 'features/toolbar/Toolbar';

import { SupportCenterList } from './SupportCenterList';
import { jbColors } from 'app/theme/jbColors';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      height: '100%',
      position: 'relative',
      overflowY: 'auto',
      backgroundColor: jbColors.app.almostWhite,
    },

    toolbarContainer: {
      flex: '0 0 auto',
      color: jbColors.core.reflexBlue,
      borderBottom: '0.5px solid rgba(135, 149, 161, 0.78)',
      backgroundColor: theme.palette.common.white,
    },
  })
);

export const ClientContactView = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.toolbarContainer}>
        <Toolbar />
      </div>

      <Box mx="auto" maxWidth={695}>
        <SupportCenterList />
      </Box>
    </div>
  );
};
