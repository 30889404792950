import { RequestMessage } from '@map/core';
import { PushTANAuthenticatorModule } from './PushTANAuthenticatorModule';
import { ResponseBase } from '@map/core';

/**
 * Activation message required to activate a device with push TAN.
 */
export class PushTANActivationMessage extends RequestMessage {
  constructor(public jwt: string) {
    super();
  }

  readonly tag: string = PushTANAuthenticatorModule.activationMessageTag;
}

/**
 * Authentication message required to authenticate a device with push TAN.
 */
export class PushTANAuthenticationMessage extends RequestMessage {
  constructor(public jwt: string) {
    super();
  }

  readonly tag: string = PushTANAuthenticatorModule.authenticationMessageTag;
}

export class PushTANAuthenticatorHasBindingMessage extends RequestMessage {
  readonly tag: string = PushTANAuthenticatorModule.authenticatorHasBindingMessageTag;
}

export class PushTANHasEmCertChangedMessage extends RequestMessage {
  readonly tag: string = PushTANAuthenticatorModule.hasEmCertChangedMessageTag;
}

export class PushTANHasEmCertChangedResponse extends ResponseBase {
  public hasChanged!: boolean;
}

export class PushTANCommitEmCertUpdateMessage extends RequestMessage {
  readonly tag: string = PushTANAuthenticatorModule.commitEmCertUpdateMessageTag;
}

export class PushTANUpdateEmCertMessage extends RequestMessage {
  constructor(public jwt: string) {
    super();
  }

  readonly tag: string = PushTANAuthenticatorModule.updateEmCertMessageTag;
}

export class PushTANGetEmCertIdMessage extends RequestMessage {
  readonly tag: string = PushTANAuthenticatorModule.getEmCertIdMessageTag;
}

export class PushTANGenerateOTPMessage extends RequestMessage {
  readonly tag: string = PushTANAuthenticatorModule.generateOTPMessageTag;
}

export class PushTANAuthenticatorGetBindingsMessage extends RequestMessage {
  readonly tag: string = PushTANAuthenticatorModule.getBindingsMessageTag;
}

export class PushTANAuthenticatorGetBindingsResponse extends ResponseBase {
  readonly bindings!: string[];
}