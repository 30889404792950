import { IMessenger, Symbol, EmptyResponse, ValueResponse } from '@map/core';
import { AuthenticatorResponse } from '@map/device-authenticator';
import { IPushTANAuthenticator } from './IPushTANAuthenticator';
import { PushTANAuthenticatorModule } from './PushTANAuthenticatorModule';
import {
  PushTANActivationMessage, PushTANAuthenticationMessage,
  PushTANAuthenticatorHasBindingMessage, PushTANCommitEmCertUpdateMessage, PushTANHasEmCertChangedMessage,
  PushTANHasEmCertChangedResponse, PushTANUpdateEmCertMessage, PushTANGetEmCertIdMessage,
  PushTANGenerateOTPMessage, PushTANAuthenticatorGetBindingsMessage, PushTANAuthenticatorGetBindingsResponse
} from './PushTANAuthenticatorMessages';

/**
 * The standard implementation of the IPushTANService.
 */
export class PushTANAuthenticator implements IPushTANAuthenticator {
  constructor(private messenger: IMessenger) {
  }

  readonly activate = (jwt: string): Promise<AuthenticatorResponse> => {
    return this.messenger.send(new PushTANActivationMessage(jwt));
  }

  readonly authenticate = (jwt: string): Promise<AuthenticatorResponse> => {
    return this.messenger.send(new PushTANAuthenticationMessage(jwt));
  }

  readonly hasBinding = async (): Promise<boolean> => {
    const response: ValueResponse<boolean> = await this.messenger.send(new PushTANAuthenticatorHasBindingMessage());
    return response.value;
  }

  readonly hasEmCertChanged = async (): Promise<boolean> => {
    const result: PushTANHasEmCertChangedResponse = await this.messenger.send(new PushTANHasEmCertChangedMessage());
    return result.hasChanged;
  }

  readonly getEmCert = (jwt: string): Promise<AuthenticatorResponse> => {
    return this.messenger.send(new PushTANUpdateEmCertMessage(jwt));
  }

  readonly getEmCertId = async (): Promise<string> => {
    const response: ValueResponse<string> = await this.messenger.send(new PushTANGetEmCertIdMessage());
    return typeof response.value === 'undefined' || response.value === null ? '' : response.value;
  }

  readonly commitEmCertUpdate = (): Promise<EmptyResponse> => {
    return this.messenger.send(new PushTANCommitEmCertUpdateMessage());
  }

  readonly generateOTP = (): Promise<EmptyResponse> => {
    return this.messenger.send(new PushTANGenerateOTPMessage());
  }

  readonly getBindings = async (): Promise<string[]> => {
    const value: PushTANAuthenticatorGetBindingsResponse = await this.messenger.send(new PushTANAuthenticatorGetBindingsMessage());
    return value.bindings;
  }

  readonly symbol: Symbol = PushTANAuthenticatorModule.serviceSymbol;
}
