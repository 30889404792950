import { Symbol } from '../Core/Symbol';
import { IDeviceProperties } from '../Device/IDeviceProperties';
import { MessengerModule } from './MessengerModule';
import { IMessenger } from './IMessenger';
import { MessageBase } from './MessageBase';
import { ResponseBase } from './ResponseBase';
import { ErrorResponse, isErrorResponse } from './ErrorResponse';
import { isRequestMessage } from './RequestMessage';

/**
 * A Messenger which can be used to loosely couple components and send messages around.
 */
export class Messenger implements IMessenger {
  constructor(deviceProperties: IDeviceProperties) {
    this.deviceProperties = deviceProperties;
  }

  readonly symbol: Symbol = MessengerModule.serviceSymbol;

  /**
   * Send a message of type TMessage to all registered recipients.
   *
   * @param message - the message to send.
   * @param timeout - the timeout for the message.
   */
  readonly send = <TResponse extends ResponseBase, TMessage extends MessageBase>(message: TMessage, timeout: number = -1): Promise<TResponse> => {
    // tslint:disable-next-line
    if (isRequestMessage(message)) {
      const promise = new Promise<TResponse>((resolve: (result: TResponse) => void, reject: (error: any) => void) => {
        if (timeout !== -1) {
          setTimeout(() => {
            reject(new ErrorResponse('Timeout occurred. No response from the native part.'));
          }, timeout);
        }

        this.responseMappings.push({
          uniqueId: message.uniqueMessageId,
          resolve: resolve,
          reject: reject
        });
        this.sendToNative(message);
      });

      // Remove callbacks when promise is resolved or rejected
      promise.then((result) => this.removeCallback(message.uniqueMessageId))
        .catch((error) => this.removeCallback(message.uniqueMessageId));

      return promise;
    }
    else {
      // Resolve all other sort of messages immediately.
      this.sendToNative(message);

      // @ts-ignore
      return Promise.resolve();
    }
  }

  // Register remove callback to make sure we clean our mappings.
  private readonly removeCallback = (uniqueMessageId: string) => {
    let index = -1;

    for (const mapping of this.responseMappings) {
      if (mapping.uniqueId === uniqueMessageId) {
        index = this.responseMappings.indexOf(mapping);
      }
    }

    this.responseMappings.splice(index, 1);
  }

  /**
   * Send the response to the messenger to allow processing it.
   *
   * @param response - the response to send.
   */
  readonly sendResponse = (response: ResponseBase) => {
    if (typeof response.uniqueMessageId === 'string') {
      for (const mapping of this.responseMappings) {
        if (mapping.uniqueId === response.uniqueMessageId) {
          if (isErrorResponse(response)) {
            mapping.reject(response.code);
          } else {
            mapping.resolve(response);
          }
        }
      }
    }
  }

  /**
   * Distribute a message only internal inside the TypeScript environment.
   *
   * @param message
   */
  readonly sendInternal = <TMessage extends MessageBase>(message: TMessage) => {
    // Call all listeners.
    this.registrations.forEach((registration: Registration) => {
      if (registration.tag === message.tag) {
        registration.callback(message);
      }
    });
  }

  /**
   * Register a callback to a given TMessage.
   *
   * @param messageTag - the tag of the message to listen to.
   * @param callback - the callback to execute.
   */
  readonly registerListener = <TMessage extends MessageBase>(messageTag: string, callback: (message: TMessage) => void): void => {
    this.registrations.push({tag: messageTag, callback: callback});
  }

  /**
   * Remove the given callback with the provided tag from the registrations.
   *
   * @param messageTag - the tag to remove.
   * @param callback - the callback to remove.
   */
  readonly unregisterListener = <TMessage extends MessageBase>(messageTag: string, callback: (message: TMessage) => void): void => {
    const toRemove: Registration[] = [];

    for (const registration of this.registrations) {
      if (registration.tag === messageTag && registration.callback === callback) {
        toRemove.push(registration);
      }
    }

    toRemove.forEach((x) => {
      const index = this.registrations.indexOf(x);
      this.registrations.splice(index, 1);
    });
  }

  /**
   * Send a message TMessage to the native client.
   * @param message - the message to send.
   */
  private readonly sendToNative = <TMessage extends MessageBase>(message: TMessage): void => {
    const platform = this.deviceProperties.getPlatform();
    if (platform === 'Android' || platform === 'Browser') {
      const jsonMessage = JSON.stringify(message);
      window.native.postMessage(jsonMessage);
    } else {
      window.webkit.messageHandlers.mapMessageHandler.postMessage(message);
    }
  }

  private responseMappings: ResponseMapping[] = [];
  private deviceProperties: IDeviceProperties;
  private registrations: Registration[] = [];
}

type ResponseMapping = {
  uniqueId: string;
  resolve: any;
  reject: any;
};

type Registration = {
  tag: string;

  callback: (message: any) => void;
};
