
import { RequestMessage } from '@map/core';
import { ResponseBase } from '@map/core';
import { SupportedBiometricMethod } from './IBiometricStorage';

export const getSupportedMethodTag = 'GetSupportedBiometricMethodMessage';
export const isFingerprintSetTag = 'IsBiometricSetMessage';
export const isFingerprintValueSetTag = 'IsBiometricValueSetMessage';
export const setFingerprintTag = 'SetBiometricMessage';
export const setFingerprintValueTag = 'SetBiometricValueMessage';
export const getFingerprintValueTag = 'GetBiometricValueMessage';
export const resetFingerprintTag = 'ResetBiometricMessage';

export class SetBiometricMessage extends RequestMessage {
  readonly tag: string = setFingerprintTag;
}

export class ResetBiometricMessage extends RequestMessage {
  readonly tag: string = resetFingerprintTag;
}

export class IsBiometricSetMessage extends RequestMessage {
  readonly tag: string = isFingerprintSetTag;
}

export class GetSupportedMethodMessage extends RequestMessage {
  readonly tag: string = getSupportedMethodTag;
}

export class GetSupportedMethodResponse extends ResponseBase {
  public supportedMethod!: SupportedBiometricMethod;
}

export class IsBiometricValueSetMessage extends RequestMessage {
  readonly tag: string = isFingerprintValueSetTag;

  constructor(public key: string) {
    super();
  }
}

export class GetBiometricValueMessage extends RequestMessage {
  readonly tag: string = getFingerprintValueTag;

  constructor(public key: string) {
    super();
  }
}

export class SetBiometricValueMessage extends RequestMessage {
  readonly tag: string = setFingerprintValueTag;

  constructor(public key: string, public value: string) {
    super();
  }
}
