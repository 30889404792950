import { Symbol } from '../Core/Symbol';
import { IDeviceProperties, Platform } from './IDeviceProperties';
import { DevicePropertiesModule } from './DevicePropertiesModule';
import { DevicePropertiesMessage } from './DevicePropertiesMessage';
import { DevicePropertiesResponse } from './DevicePropertiesResponse';
import { IPluginProvider } from '../Service/IPluginProvider';
import { MessengerModule } from '../Messaging/MessengerModule';

/**
 * Default implementation for detecting device properties.
 */
export class DeviceProperties implements IDeviceProperties {
  readonly symbol: Symbol = DevicePropertiesModule.serviceSymbol;

  private response!: DevicePropertiesResponse;

  readonly getPlatform = (): Platform => {
    if (window.native && window.native.postMessage) {
      return 'Android';
    } else if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.mapMessageHandler) {
      return 'iOS';
    } else {
      return 'Browser';
    }
  }

  private loadedDeviceProperties = (): DevicePropertiesResponse => {
    if (!this.response) {
      throw new Error('Device properties not loaded yet, wait for MapBase.ready()');
    }
    return this.response;
  }

  readonly loadDeviceProperties = async (provider: IPluginProvider): Promise<any> => {
    const messenger = MessengerModule.getService(provider);

    try {
      this.response = await messenger.send(new DevicePropertiesMessage());
    }
    catch (e) {
      throw new Error('What\'s going on? ' + e);
    }
  }

  readonly isTablet = (): boolean => {
    return this.loadedDeviceProperties().isTablet;
  }

  readonly hasCamera = (): boolean => {
    return this.loadedDeviceProperties().hasCamera;
  }

  readonly appVersion = (): string => {
    return this.loadedDeviceProperties().appVersion;
  }

  readonly mapVersion = (): string => {
    return this.loadedDeviceProperties().mapVersion;
  }

  readonly deviceId = (): string => {
    return this.loadedDeviceProperties().deviceId;
  }

  readonly deviceName = (): string => {
    return this.loadedDeviceProperties().deviceName;
  }
}
