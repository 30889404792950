import { RequestMessage } from '../Messaging/RequestMessage';
import { MessageBase } from '../Messaging/MessageBase';

export const devicePropertiesMessageTag = 'DevicePropertiesMessage';

/**
 * A message which can be used to retrieve the current device properties
 */
export class DevicePropertiesMessage extends RequestMessage {
  readonly tag: string = devicePropertiesMessageTag;
}

export const isDevicePropertiesMessage = (message: MessageBase): message is DevicePropertiesMessage => {
  return message.tag === devicePropertiesMessageTag;
};