import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { motion } from 'framer-motion';

import { EASING_BEZIER } from 'app/config';
import { jbColors } from 'app/theme/jbColors';

import { ReactComponent as ForwardArrowIcon } from 'common/assets/forward-arrow-icon.svg';
import { ReactComponent as CloseIcon } from 'common/assets/close-icon.svg';
import { selectScanKey } from './paymentScannerSelectors';
import { PaymentScannerInstructions } from './PaymentScannerInstructions';
import { stopScanProcess } from './paymentScannerSlice';
import { PaymentScannerScanPayment } from './PaymentScannerScanPayment';

type Props = {
  onBack: () => void;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: jbColors.support.royalBlue,
      top: 0,
      left: 0,
      zIndex: 2,
    },

    toolbar: {
      marginTop: theme.spacing(0.5),
      display: 'grid',
      gridTemplateColumns: '60px 1fr 60px',
      alignItems: 'center',
    },

    backIconButton: {
      gridColumn: '1 / 2',
      gridRowStart: 1,
      width: 48,
      justifySelf: 'flex-end',
    },

    backIcon: {
      transform: 'rotate(180deg)',
      justifySelf: 'flex-end',
    },

    closeIconButton: {
      gridColumn: '3 / 4',
      gridRowStart: 1,
      width: 48,
    },
  })
);

export const PaymentScanner = ({ onBack, onClose }: Props) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const scanKey = useSelector(selectScanKey);

  const handleBack = () => {
    dispatch(stopScanProcess());
    onBack();
  };
  const handleClose = () => {
    dispatch(stopScanProcess());
    onClose();
  };

  return (
    <motion.div
      className={classes.container}
      key="PaymentScanner"
      initial={{ x: window.innerWidth }}
      animate={{ x: 0 }}
      exit={{ x: window.innerWidth }}
      transition={{ ease: EASING_BEZIER, duration: 0.2 }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={handleBack} className={classes.backIconButton} size="large">
          <ForwardArrowIcon className={classes.backIcon} />
        </IconButton>
        <IconButton onClick={handleClose} className={classes.closeIconButton} size="large">
          <CloseIcon />
        </IconButton>
      </div>

      {scanKey ? <PaymentScannerScanPayment /> : <PaymentScannerInstructions />}
    </motion.div>
  );
};
