import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ErrorPayload } from 'common/types';
import { EntitiesState, DashboardData, FetchDashboardDataStartPayload } from './entitiesTypes';

const initialState: EntitiesState = {
  isFetching: false,
};

export const entities = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    fetchDashboardDataStart: (state, action: PayloadAction<FetchDashboardDataStartPayload>) => {
      state.isFetching = true;
    },
    fetchDashboardDataSuccess: (state, action: PayloadAction<DashboardData>) => {
      state.isFetching = false;
      state.dashboardData = action.payload;
    },
    fetchDashboardDataFailure: (state, action: PayloadAction<ErrorPayload>) => {
      state.isFetching = false;
      state.error = action.payload.error;
    },
  },
});

export const {
  fetchDashboardDataStart,
  fetchDashboardDataSuccess,
  fetchDashboardDataFailure,
} = entities.actions;

export const entitiesReducer = entities.reducer;

export { initialState as entitiesInitialState };
