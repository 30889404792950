import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'app/redux/rootReducer';

export const selectPaymentScanner = (state: RootState) => state.paymentScanner;

export const selectScanKey = createSelector(
  selectPaymentScanner,
  (paymentScanner) => paymentScanner.scanKey
);

export const selectNumScans = createSelector(
  selectPaymentScanner,
  (paymentScanner) => paymentScanner.numScans
);
