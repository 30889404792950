import { RequestMessage } from '@map/core';
import { DeviceAuthenticatorModule } from './DeviceAuthenticatorModule';

/**
 * Device authentication message
 */
export class DeviceAuthenticationMessage extends RequestMessage {
  constructor(public jwt: string) {
    super();
  }

  readonly tag: string = DeviceAuthenticatorModule.authenticationRequestMessageTag;
}
