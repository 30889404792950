import { Symbol } from '../Core/Symbol';
import { IPluginRegistry } from '../Service/IPluginRegistry';
import { ConfigurationProvider } from './ConfigurationProvider';
import { ConfigurationMessage } from './ConfigurationMessage';
import { ConfigurationResponse } from './ConfigurationResponse';
import { MessengerModule } from '../Messaging/MessengerModule';
import { IConfigurationProvider } from './IConfigurationProvider';
import { IPluginProvider } from '../Service/IPluginProvider';
import { ConfigurationChangedMessage } from './ConfigurationChangedMessage';

/**
 * Provides convenience methods to use the configuration module.
 */
export module ConfigurationModule {
  /**
   * The error codes that can occur in the service.
   */
  export module Errors {
    const prefix = 'ConfigurationError.';

    /**
     * The given section cannot be updated.
     */
    export const updateNotAllowed = prefix + 'UpdateNotAllowed';
  }

  /**
   * The symbol that uniquely identifies the service.
   */
  export const serviceSymbol = new Symbol('ConfigurationProvider');

  /**
   * The tag used to register a listener for changes to the configuration.
   */
  export const configurationChangedMessageTag = 'ConfigurationChangedMessage';

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): IConfigurationProvider => {
    return provider.resolve<IConfigurationProvider>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);
    registrar.registerSingle(serviceSymbol, (p) => new ConfigurationProvider(MessengerModule.getService(p)));

    registrar.addCommitAction(serviceSymbol, async (provider) => {
      const messenger = MessengerModule.getService(provider);
      const configurationProvider = provider.resolve<IConfigurationProvider>(serviceSymbol);

      messenger.registerListener(configurationChangedMessageTag, (message: ConfigurationChangedMessage) => {
        configurationProvider.setNodeContent(message.nodeName, message.nodeContent);
      });

      const response: ConfigurationResponse = await messenger.send(new ConfigurationMessage(), -1);
      configurationProvider.set(response.configuration);
    });
  };
}
