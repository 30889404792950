import React from 'react';
import { Button, CircularProgress, ButtonProps } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';

const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'inline-flex',
      position: 'relative',
    },

    button: {
      flex: 1,
    },

    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

type Props = {
  loading?: boolean;
  progressColor?: 'inherit' | 'primary' | 'secondary' | undefined;
} & ButtonProps;

export const LoadingButton = ({
  disabled = false,
  loading = false,
  className,
  children,
  variant,
  color,
  progressColor,
  ...props
}: Props) => {
  const classes = useStyles();
  let loaderColor = progressColor;

  if (!loaderColor) {
    loaderColor =
      variant === 'outlined' ? 'primary' : color === 'primary' ? 'secondary' : 'primary';
  }

  return (
    <div className={clsx(classes.wrapper, className)}>
      {/* loading overrides disabled */}
      <Button
        {...props}
        color={color}
        variant={variant}
        disabled={loading || disabled}
        className={classes.button}
      >
        {children}
      </Button>
      {loading && (
        <CircularProgress size={24} color={loaderColor} className={classes.buttonProgress} />
      )}
    </div>
  );
};
