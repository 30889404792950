import { Symbol } from '../Core/Symbol';
import { IConfigurationProvider } from './IConfigurationProvider';
import { IMessenger } from '../Messaging/IMessenger';
import { ConfigurationModule } from './ConfigurationModule';
import { ChangeConfigurationMessage } from './ChangeConfigurationMessage';
import { ResponseBase } from '../Messaging/ResponseBase';

export class ConfigurationProvider implements IConfigurationProvider {

  constructor(private messenger: IMessenger) {
  }

  getOnReady<TConfiguration>(name: string): Promise<TConfiguration> {
    const resolveOrSetTimeout = (resolve: any) => {
      if (this.isReady) {
        resolve(this.get<TConfiguration>(name));
      } else {
        setTimeout(() => resolveOrSetTimeout(resolve), 50);
      }
    };

    return new Promise<TConfiguration>((resolve, reject) => {
      resolveOrSetTimeout(resolve);
    });
  }

  readonly get = <TConfiguration>(name: string): TConfiguration => {
    return this.data[name] as TConfiguration;
  }

  readonly update = <TConfiguration>(name: string, configuration: TConfiguration): Promise<ResponseBase> => {
    const message = new ChangeConfigurationMessage();
    if (typeof configuration === 'string' || configuration instanceof String) {
      message.content = configuration.toString();
    } else {
      message.content = JSON.stringify(configuration);
    }
    message.nodeName = name;
    return this.messenger.send(message);
  }

  readonly set = (jsonData: string) => {
    this.data = JSON.parse(jsonData);
    this.isReady = true;
  }

  readonly setNodeContent = (node: string, jsonData: string) => {
    this.data[node] = JSON.parse(jsonData);
  }

  readonly symbol: Symbol = ConfigurationModule.serviceSymbol;

  private isReady = false;

  private data: any = {};
}
