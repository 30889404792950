import {
  PluginRegistry,
  IPluginRegistry,
  IPluginProvider,
  ConfigurationModule,
  MessengerModule,
  TranslationModule,
  DevicePropertiesModule
} from '@map/core';

export abstract class MapBase {
  constructor() {
    const registrar = new PluginRegistry();

    if (!DevicePropertiesModule.isMAPDevice()) {
      this.ready = () => (
        new Promise<IPluginProvider>((resolve, reject) => {
          reject('Not running on a MAP device');
        })
      );
      return;
    }

    MessengerModule.use(registrar);
    ConfigurationModule.use(registrar);
    TranslationModule.use(registrar);

    this.configureRegistrar(registrar);

    const readyPromise = registrar.commit();
    this.ready = () => (readyPromise);
    readyPromise.then((provider) => {
      this.onReady(provider);
    });
  }

  public ready: () => Promise<IPluginProvider>;

  protected abstract configureRegistrar(registrar: IPluginRegistry): void;

  protected onReady(provider: IPluginProvider) {
    // NOP
  }
}
