import { Symbol } from '../Core/Symbol';
import { IPluginRegistry } from '../Service/IPluginRegistry';
import { DeviceProperties } from './DeviceProperties';
import { IDeviceProperties } from './IDeviceProperties';
import { IPluginProvider } from '../Service/IPluginProvider';

/**
 * Provides convenience methods to use the device properties module.
 */
export module DevicePropertiesModule {
  /**
   * The symbol that uniquely identifies the IDeviceProperties.
   */
  export const serviceSymbol = new Symbol('DeviceProperties');

  /**
   * Gets the I service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): IDeviceProperties => {
    return provider.resolve<IDeviceProperties>(serviceSymbol);
  };

  /**
   * Register the core module components with the given registrar.
   *
   * @param registrar - the registrar to extend.
   * @return the registrar.
   */
  export const use = (registrar: IPluginRegistry) => {
    const deviceProperties = new DeviceProperties();
    registrar.registerSingle(serviceSymbol, (p) => deviceProperties);

    registrar.addCommitAction(serviceSymbol, deviceProperties.loadDeviceProperties);
  };

  /**
   * Determine if we are currently running in an MAP enabled device.
   *
   * @return true if the current page is running on a MAP device, false otherwise.
   */
  export const isMAPDevice = (): boolean => {
    const isAndroidDevice = window.native && window.native.postMessage;
    const isiOSDevice = window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.mapMessageHandler;

    return isAndroidDevice !== undefined || isiOSDevice !== undefined;
  };
}
