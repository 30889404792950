/**
 * This is a mock of the MapBridge with reduced methods.
 * It's intended to be used as to replace MAP functionality
 * whenever the app does not run inside an actual MAP
 * application container.
 */

import { SupportedBiometricMethod } from '@map/biometric-storage';
import { AuthenticatorResponse } from '@map/device-authenticator';

import { scenarios, scenario, Scenario } from 'app/config';
import { supportedLanguages } from 'app/i18n/i18n';
import { DeviceSettings, DeviceTypes } from 'features/entities/entitiesTypes';
import {
  ConfigurationProviderMemberZone,
  initialMemberZoneConf,
  PublicationContent,
} from './MapBridge';

export const getJWTFromQueryString = (): string => {
  // default "user"
  let JWT =
    'eyJjdHkiOiJKV1QiLCJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiUlNBMV81In0.eSVBmFFkpr58uCcMDjHPl0QVakhocU7cuTr99Rs13qiSXwfwK9GpFBCpmrKOf324lpc0DSOsVMRDlD7QFdzBoZ6hkHq0tcRlPn0vzpM08CZlImae0MTCGFtx2Zg00f1FNtT-P7U3dcJ_vAo1hsesL0wYgc0yyOYkk234WZ9ZmsqeosoJnnXGlmENQIKRH_qyz7VSyBghmJv1TxXGLujIvgFgltfcKinz8yZmnngMkDSa8wm9mNVFmMWcc2z-wLbYR0VofiyISLc34NYeo_fdiptwlHv47mqB5mXeObST4p5ootOTsRZoBVAI9MTPTWAMQklxARDz00G3xEHqcPoVoA.KBUQudnwfMkLzHEOan4aPA.vmSp0VUyxHXBZ5clxkvC4ts6Eo9VRIHQplVz_kyQWUEQebdz9TBqApusrz-4ItvwGBicPcgemu4uNnHt3GWRCU-AdvfS5dCqcLYB6XHdG-qmHIT_Vy0MFxWJQbrb4HpdD9oGv4p6vInOLs8j61nyUyjMGMlGHjvtIpyDeOWpBJYfnV-Ic7W87eGJXjINueXR1JyFXNPKc7aiMVRmh94E0_le0PleNUcTXuW660HlLCK_E_y0oFLhAPECV1NNY5PHj0-wp7PVzAqZkKsvvnmdqCyA5ZenYnTePU2fhYRVpP6K2QSgG0pkmEUf93OxFl72freGOFX9YjVUNHfC4c_9otw6ezGLREU-8BiGIX6SkxXgaODHoXAuPe2AtCEgyoakkzc1SJMdql1aez4F06UmhFqz_b5OxmNBo70cXh-tI2RM3hsZUYXj5EXA3KO8nI51-rtcv58_bL_0o_zLniB07D3qtn5Bx6QoQhe8ngdveQud5ZIIb-O66WfuibFYHOVib_UJ7cBGOpTP3DJqq1U7eBzjKo1McNiSS2rGUFwoiFQupo9_7EC25NCmuMGD9jGkd88tq_S-NGol1Db6hvw0MQP5O0eQVadm6ArYwLz8nSRP0Ozz3M51n6XCFiMx0a5Q5cszHpzBoOENa2OyqthtcoRQBU6ha3Eo39qy-bOQB2T99bXUWB86UBTl1dFXQFiyO5U3i9HbF0lOtV8f7ktzokbXIgAjXU6GdH8AjpImt0UFGvzpWq6_xtuClsjxLSk9y7wAdb6fcmjUcFUBu7uUKakrrM8ZwXHOGL4T8zqgxK2u5Y07AmHqNsR39s1X2qsjYoJ-XW9clQkMqqFvOnFYWLY8-h36rX6t3NcUVqCZQ9l-QUx7_4_RF97I2nqghrlbzPRqVX2ekIctfydDdscUk5HRE2ocCmv8L7KFVAClcY0KICu98TTCMigsWV4jwqVf6PEYEnyumV7AGjL6fyELOsE6W9ZuQY4cBdPSYl0McWInjw33zweV7De5GlZXA7_VPi349GE9-9YqvEwwl-j1aDmxmoCBtXo86UUOn3o2fDDSaBB8crNfFXtOlfExe0_kXV6QezGQGgEri8YE2JeoGO2X0qQjTEEOQspkWp-kylEr-GXalRDCxLdO7bMDWHT5ECMCypztjfz3bxJrmpIzb6r9DCztRZJe2MCqtklss16TAVs3lzTkh2iGoEtZQ055biVP7w-ms9ZG5xV6GC0HtKlFb815rl4xVGLlsp2-CcCK08KZksDT4b0fEDjojg_4qWpW_tAMfGsHfTvXOyi42HtB7hwLb-a11g9tWGdqmegNpDpdnwNqILlkiGKLR0mw.YXV0aA';

  if (scenario && scenarios.includes(scenario)) {
    JWT = scenario;
  }
  return JWT;
};

type DeviceSettingsScenarios = {
  [key in Scenario]: Pick<DeviceSettings, 'isTermsAccepted' | 'selectedDomicile'> &
    Partial<Pick<DeviceSettings, 'appVersion'>>;
};

const deviceSettingsScenarios: DeviceSettingsScenarios = {
  '@landing-domicile:CH-terms': { isTermsAccepted: false, selectedDomicile: 'CH' },
  '@landing-domicile:CH': { isTermsAccepted: false, selectedDomicile: 'CH' },
  '@landing-domicile:ES': { isTermsAccepted: false, selectedDomicile: 'ES' },
  '@landing-domicile:IL': { isTermsAccepted: false, selectedDomicile: 'IL' },
  '@landing-domicile:US': { isTermsAccepted: false, selectedDomicile: 'US' },
  'client-domicile:CH-ebk-new-terms': { isTermsAccepted: false, selectedDomicile: 'CH' },
  'client-domicile:CH-legacy-sg-hk': { isTermsAccepted: false, selectedDomicile: 'CH' },
  'client-domicile:CH-ebk': { isTermsAccepted: false, selectedDomicile: 'CH' },
  'client-domicile:CH-kos': { isTermsAccepted: false, selectedDomicile: 'CH' },
  'client-domicile:LU-el2': { isTermsAccepted: false, selectedDomicile: 'LU' },
  'client-domicile:MC-elm': { isTermsAccepted: false, selectedDomicile: 'MC' },
  'client-domicile:GG-ebg': { isTermsAccepted: false, selectedDomicile: 'GG' },
  'client-domicile:CH-ebp': { isTermsAccepted: false, selectedDomicile: 'CH' },
  'client-domicile:LU-eselu-r20': { isTermsAccepted: false, selectedDomicile: 'LU' },
  'client-domicile:LU-el2-elm': { isTermsAccepted: false, selectedDomicile: 'LU' },
  'client-domicile:CH-ebk-hk-ino2': { isTermsAccepted: false, selectedDomicile: 'CH' },
  'client-domicile:CH-ebk-sg-hk-ino2': { isTermsAccepted: false, selectedDomicile: 'CH' },
  'client-domicile:SG-betapps-hk': { isTermsAccepted: false, selectedDomicile: 'SG' },
  'expired-trial-domicile:CH': { isTermsAccepted: false, selectedDomicile: 'CH' },
  firstTimeUser: { isTermsAccepted: false, selectedDomicile: undefined },
  'prospect-domicile:CH-mem': { isTermsAccepted: false, selectedDomicile: 'CH' },
  'trial-domicile:CH-mem': { isTermsAccepted: false, selectedDomicile: 'CH' },
  forceUpdate: { isTermsAccepted: false, selectedDomicile: 'CH', appVersion: '0.0.0' },
};

// set language from query parameter
const detectLanguage = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const lang = searchParams.get('lang') || 'en';
  if (supportedLanguages.includes(lang)) {
    return lang;
  } else {
    return 'en';
  }
};

export class MapBridgeMock {
  ready(): Promise<boolean> {
    return new Promise((resolve) => resolve(true));
  }

  openPage(url: string) {
    window.open(url, '_blank');
  }

  getDeviceSettings(): DeviceSettings {
    return {
      isMemServiceActivated: this.getIsMemServiceActivated(),
      language: detectLanguage(),
      osType: 'iOS',
      deviceModel: 'iPhone',
      deviceType: DeviceTypes.MOBILE,
      appVersion: '1.0.0',
      // selectedDomicile, isTermsAccepted and optionally appVersion will come from the
      // deviceSettingsScenario
      userAgent: navigator.userAgent,
      ...((scenario && deviceSettingsScenarios[scenario]) || {}),
    };
  }

  ensureMemberZoneConfiguration(): Promise<void> {
    return new Promise((resolve) => {
      // if we have more properties in the future consider using a deep merge library
      const confJsonString = localStorage.getItem('MemberZone') || '{}';
      const conf = JSON.parse(confJsonString) as ConfigurationProviderMemberZone;

      if (!conf.isMemServiceActivated) {
        conf.isMemServiceActivated = initialMemberZoneConf.isMemServiceActivated;
      }
      if (!conf.financialNews) {
        conf.financialNews = initialMemberZoneConf.financialNews;
      }
      if (!conf.financialNews?.readItems) {
        conf.financialNews!.readItems = initialMemberZoneConf.financialNews?.readItems;
      }
      if (!conf.financialNews?.searchHistory) {
        conf.financialNews!.searchHistory = initialMemberZoneConf.financialNews?.searchHistory;
      }

      localStorage.setItem('MemberZone', JSON.stringify(conf));

      return resolve();
    });
  }

  getIsMemServiceActivated() {
    const confJsonString = localStorage.getItem('MemberZone');

    // we don't check for existence of confJsonString because of
    // ensureMemberZoneConfiguration hat needs to be called at initialization
    const conf = JSON.parse(confJsonString!) as ConfigurationProviderMemberZone;

    return conf.isMemServiceActivated;
  }

  updateIsMemServiceActivated(value: boolean): Promise<void> {
    return new Promise((resolve) => {
      const confJsonString = localStorage.getItem('MemberZone');

      // we don't check for existence of confJsonString because of ensureMemberZoneConfiguration
      const conf = JSON.parse(confJsonString!) as ConfigurationProviderMemberZone;

      conf.isMemServiceActivated = value;

      localStorage.setItem('MemberZone', JSON.stringify(conf));

      return resolve();
    });
  }

  getInsightsConfiguration() {
    const confJsonString = localStorage.getItem('MemberZone');

    // we don't check for existence of confJsonString because of
    // ensureMemberZoneConfiguration hat needs to be called at initialization
    const conf = JSON.parse(confJsonString!) as ConfigurationProviderMemberZone;

    return conf.financialNews;
  }

  updateInsightsSearchHistory(searchHistory: string[]): Promise<void> {
    return new Promise((resolve) => {
      const confJsonString = localStorage.getItem('MemberZone');

      // we don't check for existence of confJsonString because of ensureMemberZoneConfiguration
      const conf = JSON.parse(confJsonString!) as ConfigurationProviderMemberZone;

      conf.financialNews!.searchHistory = searchHistory;

      localStorage.setItem('MemberZone', JSON.stringify(conf));

      return resolve();
    });
  }

  updateInsightsReadItems(readItems: number[]): Promise<void> {
    return new Promise((resolve) => {
      const confJsonString = localStorage.getItem('MemberZone');

      // we don't check for existence of confJsonString because of ensureMemberZoneConfiguration
      const conf = JSON.parse(confJsonString!) as ConfigurationProviderMemberZone;

      conf.financialNews!.readItems = readItems;

      localStorage.setItem('MemberZone', JSON.stringify(conf));

      return resolve();
    });
  }

  updateInsightsLastSyncDate(lastSyncDate: string): Promise<void> {
    return new Promise((resolve) => {
      const confJsonString = localStorage.getItem('MemberZone');

      // we don't check for existence of confJsonString because of ensureMemberZoneConfiguration
      const conf = JSON.parse(confJsonString!) as ConfigurationProviderMemberZone;

      conf.financialNews!.lastSyncDate = lastSyncDate;

      localStorage.setItem('MemberZone', JSON.stringify(conf));

      return resolve();
    });
  }

  updateInsightsPublications(publications: PublicationContent): Promise<void> {
    return new Promise((resolve) => {
      const confJsonString = localStorage.getItem('MemberZone');

      // we don't check for existence of confJsonString because of ensureMemberZoneConfiguration
      const conf = JSON.parse(confJsonString!) as ConfigurationProviderMemberZone;

      conf.financialNews!.publications = publications;

      localStorage.setItem('MemberZone', JSON.stringify(conf));

      return resolve();
    });
  }

  getActiveLanguage() {
    return detectLanguage();
  }

  getDeviceId() {
    return '123e4567-e89b-12d3-a456-426614174000';
  }

  getSupportedBiometricMethod(): Promise<SupportedBiometricMethod> {
    return new Promise((resolve) => resolve(SupportedBiometricMethod.None));
  }

  updateDomicile() {
    return new Promise((resolve) =>
      resolve({
        uniqueMessageId: 'mockMessageId',
      })
    );
  }

  updateTermsAccepted() {
    return new Promise((resolve) =>
      resolve({
        uniqueMessageId: 'mockMessageId',
      })
    );
  }

  authenticateDevice(): Promise<AuthenticatorResponse> {
    return new Promise((resolve) =>
      resolve({
        jwt: getJWTFromQueryString(),
        uniqueMessageId: Math.random().toString(32).substring(2), // remove 0.
      })
    );
  }

  getEmCert(): Promise<AuthenticatorResponse> {
    return new Promise((resolve) =>
      resolve({
        jwt: getJWTFromQueryString(),
        uniqueMessageId: Math.random().toString(32).substring(2), // remove 0.
      })
    );
  }

  commitEmCertUpdate(): Promise<void> {
    return new Promise((resolve) => resolve());
  }

  hasEmCertChanged(): Promise<boolean> {
    return new Promise((resolve) => resolve(true));
  }

  async scanQrCode() {
    console.log('Cannot actually scan a QR code with a mock MAP implementation');
    return '';
  }
}
