import { ModuleKeyValueTable } from './TranslationProvider';
import { ResponseBase } from '../Messaging/ResponseBase';

/**
 * Get the respond to a ConfigurationMessage.
 */
export class TranslationResponse extends ResponseBase {
  /**
   * The configuration interface.
   */
  activeLanguage!: string;

  /**
   * The translation for the current languages.
   */
  translations!: ModuleKeyValueTable;
}