import { Symbol, IPluginRegistry, MessengerModule, IPluginProvider } from '@map/core';
import { BrowserService } from './BrowserService';
import { IBrowserService } from './IBrowserService';

export module BrowserModule {
  /**
   * The symbol for the Browser Service to register on the PluginLocator.
   */
  export const serviceSymbol = new Symbol('BrowserService');

  /**
   * The tag used to register a listener for browser-orientation changes.
   */
  export const configureRotationMessage = 'ConfigureRotationMessage';

  export const showPdfMessage = 'ShowPDFMessage';

  /**
   * Gets the IMessenger service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): IBrowserService => {
    return provider.resolve<IBrowserService>(serviceSymbol);
  };

  /**
   * Use the default browser module which gets the configuration from the native part.
   * @param registrar
   */
  export const use = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);
    registrar.registerSingle(serviceSymbol, (p) => new BrowserService(MessengerModule.getService(p)), MessengerModule.serviceSymbol);
  };
}