import {RequestMessage} from '@map/core';

export const closeAllBrowsersMessageTag = 'CloseAllBrowsersMessage';

/**
 * Request to close all open Browsers. Only the browser with the home page will remain after this
 */
export class CloseAllBrowsersMessage extends RequestMessage {
  readonly tag: string = closeAllBrowsersMessageTag;
}
