import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';

import { entitiesInitialState, entitiesReducer } from 'features/entities/entitiesSlice';
import { errorsInitialState, errorsReducer } from 'features/errors/errorsSlice';
import {
  extNavigationInitialState,
  extNavigationReducer,
} from 'features/extNavigation/extNavigationSlice';
import { firstTimeInitialState, firstTimeReducer } from 'features/firstTime/firstTimeSlice';
import { productTourInitialState, productTourReducer } from 'features/productTour/productTourSlice';
import { sessionInitialState, sessionReducer } from 'features/session/sessionSlice';
import { menuInitialState, menuReducer } from 'features/menu/menuSlice';
import {
  paymentScannerInitialState,
  paymentScannerReducer,
} from 'features/paymentScanner/paymentScannerSlice';

export type RootState = ReturnType<typeof appReducer>;

export const appReducer = combineReducers({
  entities: entitiesReducer,
  errors: errorsReducer,
  extNavigation: extNavigationReducer,
  firstTime: firstTimeReducer,
  productTour: productTourReducer,
  session: sessionReducer,
  menu: menuReducer,
  paymentScanner: paymentScannerReducer,
});

export const resetApp = createAction('root/resetApp');

export const rootReducer = (state: RootState | undefined, action: AnyAction) => {
  if (resetApp.match(action)) {
    // This will force all the slices in appReducer to use their default slice state
    state = rootInitialState;
  }

  return appReducer(state, action);
};

export const rootInitialState = {
  entities: entitiesInitialState,
  errors: errorsInitialState,
  extNavigation: extNavigationInitialState,
  firstTime: firstTimeInitialState,
  productTour: productTourInitialState,
  session: sessionInitialState,
  menu: menuInitialState,
  paymentScanner: paymentScannerInitialState,
};
