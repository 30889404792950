import { LogLevel } from './LogLevel';
import { BroadcastMessage } from '../Messaging/BroadcastMessage';
import { LoggingModule } from './LoggingModule';

/**
 * A log message which is used together with the MessageLogger.
 */
export class LogMessage extends BroadcastMessage {
  constructor(public level: LogLevel, public message: string) {
    super();
  }

  readonly tag = LoggingModule.logMessageTag;
}
