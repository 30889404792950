import { MessageBase } from './Messaging/MessageBase';
import { isConfigurationMessage } from './Configuration/ConfigurationMessage';
import { ConfigurationResponse } from './Configuration/ConfigurationResponse';
import { sendResponse, sendMessage } from './Messaging/NativeBridge';
import { isTranslationMessage } from './Translation/TranslationMessage';
import { TranslationResponse } from './Translation/TranslationResponse';
import { isDevicePropertiesMessage } from './Device/DevicePropertiesMessage';
import { DevicePropertiesResponse } from './Device/DevicePropertiesResponse';
import { LogMessage } from './Logging/LogMessage';
import { LogLevel } from './Logging/LogLevel';

/**
 * This is a mock for the native layer of the application. It can be used to
 * run the application in a browser (see map-mdb for demo usage). The mock will
 * only be initialized if Chrome is detected.
 */

export const initializeNativeMock = (configuration: string, translations: string, language: string) => {
  const target = window as any;
  target.native = {
    postMessage: (stringMessage: string) => {
      const message = JSON.parse(stringMessage) as MessageBase;
      if (isConfigurationMessage(message)) {
        const response = new ConfigurationResponse();
        response.configuration = configuration;
        response.uniqueMessageId = message.uniqueMessageId;
        sendResponse(response);
      }
      else if (isTranslationMessage(message)) {
        const response = new TranslationResponse();
        response.uniqueMessageId = message.uniqueMessageId;
        response.activeLanguage = language;
        response.translations = JSON.parse(translations)[language];
        sendResponse(response);
      }
      else if (isDevicePropertiesMessage(message)) {
        const response = new DevicePropertiesResponse();
        response.uniqueMessageId = message.uniqueMessageId;
        const config = JSON.parse(configuration);
        response.hasCamera = config.Core.Facilities.Camera;
        response.isTablet = config.Core.Device.Tablet;
        response.appVersion = '0.0.0';
        response.mapVersion = '0.0.0';
        sendResponse(response);
      }
      else {
        console.log('Unhandled message:');
        console.log(JSON.stringify(JSON.parse(stringMessage), null, 2));
      }
    }
  };

  setTimeout(() => {
    sendMessage(new LogMessage(LogLevel.Debug, 'Native mock is initialized.'));
  }, 50);
};