import { MessageBase } from '../Messaging/MessageBase';
import { RequestMessage } from '../Messaging/RequestMessage';
import { DialogModule } from './DialogModule';

/**
 * Message to display a toast message.
 */
export class ToastMessage extends RequestMessage {
  constructor(public content: string, public duration: number, public styled: boolean) {
    super();
  }
  readonly tag: string = DialogModule.toastMessageTag;
}

export const isToastMessage = (message: MessageBase): message is ToastMessage => {
  return message.tag === DialogModule.toastMessageTag;
};