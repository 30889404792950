import { bottomNavigationActionClasses } from '@mui/material';
import { jbColors } from 'app/theme/jbColors';

export const MuiBottomNavigationAction = {
  styleOverrides: {
    root: {
      color: jbColors.app.greyPrimary,
      flex: '1 0 auto',
      maxWidth: 120,
      '@media (min-width: 481px)': {
        maxWidth: 168,
      },
      padding: '6px 0 8px',
      [`&.${bottomNavigationActionClasses.selected}`]: {
        paddingTop: 6,
        color: jbColors.app.greyPrimary,
      },
      '& >svg': {
        flexShrink: 0,
      },
    },
    /* Styles applied to the label's span element. */
    label: {
      marginTop: 6,
      fontSize: 10,
      transition: 'opacity 0.2s',
      transitionDelay: '0.1s',
      [`&.${bottomNavigationActionClasses.iconOnly}`]: {
        opacity: 0,
        transitionDelay: '0s',
      },
      [`&.${bottomNavigationActionClasses.selected}`]: {
        fontSize: 10,
      },
    },
  },
};
