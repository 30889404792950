import { Symbol } from '../Core/Symbol';
import { MessageLogger } from './MessageLogger';
import { MessengerModule } from '../Messaging/MessengerModule';
import { DebugLogger } from './DebugLogger';
import { IPluginRegistry } from '../Service/IPluginRegistry';
import { IPluginProvider } from '../Service/IPluginProvider';
import { ILogger } from './ILogger';

/**
 * Defines the API with which you can include and obtain logging services.
 */
export module LoggingModule {
  /**
   * The symbol that uniquely identifies the service.
   */
  export const serviceSymbol = new Symbol('Logger');

  /**
   * The tag used to register a listener for logging.
   */
  export const logMessageTag = 'LogMessage';

  /**
   * Gets the ILogger service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): ILogger => {
    return provider.resolve<ILogger>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    registrar.registerSingle(serviceSymbol, (p) => new DebugLogger());
  };

  /**
   * Registers the {@link MessageLogger} instead for the standard {@link DebugLogger}.
   *
   * @param registrar The registrar to use.
   */
  export const useMessageLogger = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);
    registrar.registerSingle(serviceSymbol, (p) => new MessageLogger(MessengerModule.getService(p)), MessengerModule.serviceSymbol);
  };
}
