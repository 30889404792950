import { AnyAction } from '@reduxjs/toolkit';
import { Epic } from 'redux-observable';
import { from, of, zip } from 'rxjs';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import { RootState } from 'app/redux/rootReducer';

import {
  initAuthenticationStart,
  initAuthenticationSuccess,
  initAuthenticationFailure,
  authenticateStart,
  authenticateSuccess,
  authenticateFailure,
  mapDeviceAuthenticationSuccess,
  mapDeviceAuthenticationFailure,
  initCertUpdateStart,
  initCertUpdateFailure,
  initCertUpdateSuccess,
  certUpdateStart,
  certUpdateFailure,
  certUpdateSuccess,
  mapCommitEmCertFailure,
  mapCommitEmCertSuccess,
  mapGetEmCertFailure,
  mapGetEmCertSuccess,
  fetchDeviceSettingsStart,
  fetchDeviceSettingsSuccess,
  fetchDeviceSettingsFailure,
} from './sessionSlice';
import {
  initAuthenticationRequest,
  authenticateRequest,
  initCertUpdateRequest,
  certUpdateRequest,
} from './sessionRequests';
import { selectCertificateRenewalToken, selectInitializationToken } from './sessionSelectors';
import { fetchDashboardDataStart } from 'features/entities/entitiesSlice';
import { getMapBridge } from 'features/map';
import { setLanguage } from 'app/i18n/i18n';
import { ActualSupportedBiometricMethod } from 'features/entities/entitiesTypes';

export const fetchDeviceSettingsEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(fetchDeviceSettingsStart.match),
    withLatestFrom(state$),
    mergeMap(() => {
      const mapBridge = getMapBridge();
      return zip(of(mapBridge.getDeviceSettings()), mapBridge.getSupportedBiometricMethod()).pipe(
        mergeMap(([deviceSettings, supportedBiometricMethod]) => {
          setLanguage(deviceSettings.language.toLowerCase());

          return [
            fetchDeviceSettingsSuccess({
              deviceSettings,
              // version 3.4.99 of @map/biometric-storage defines a numeric enum
              // for SupportedBiometricMethod while the actual returned value is
              // a string. So we need to cast to the actual type
              supportedBiometricMethod:
                supportedBiometricMethod as unknown as ActualSupportedBiometricMethod,
            }),
            initAuthenticationStart(), // initialize authentication
          ];
        }),
        catchError((error: Error) => [fetchDeviceSettingsFailure({ error: error.message })])
      );
    })
  );

export const initAuthenticationEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(initAuthenticationStart.match),
    withLatestFrom(state$),
    mergeMap(() =>
      from(initAuthenticationRequest()).pipe(
        mergeMap((payload) => [initAuthenticationSuccess(payload)]),
        catchError((error: Error) => [initAuthenticationFailure({ error: error.message })])
      )
    )
  );

// Interface with Crealogix's MAP to get the JWT token
// needed for finalizing authentication
export const initAuthenticationSuccessEpic: Epic<AnyAction, AnyAction, RootState> = (
  action$,
  state$
) =>
  action$.pipe(
    filter(initAuthenticationSuccess.match),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const initializationToken = selectInitializationToken(state);
      if (!initializationToken) throw new Error('No initialization token found');
      const mapBridge = getMapBridge();
      return from(mapBridge.authenticateDevice(initializationToken)).pipe(
        mergeMap((payload) => {
          if (!payload)
            return [
              mapDeviceAuthenticationFailure({
                error: 'Undefined response from `mapBridge.authenticateDevice()`',
              }),
            ];
          return [mapDeviceAuthenticationSuccess(payload), authenticateStart(payload.jwt)];
        }),
        // errors from MAP can also be strings
        catchError((error: Error | string) => [
          mapDeviceAuthenticationFailure({
            error: typeof error === 'string' ? error : error.message,
          }),
        ])
      );
    })
  );

export const authenticateEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(authenticateStart.match),
    withLatestFrom(state$),
    mergeMap(([action]) =>
      from(authenticateRequest(action.payload)).pipe(
        mergeMap((payload) => [authenticateSuccess(payload)]),
        catchError((error: Error) => [authenticateFailure({ error: error.message })])
      )
    )
  );

// Interface with Crealogix's MAP to check
// if we need to renew the Certificate
export const authenticateSuccessEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(authenticateSuccess.match),
    withLatestFrom(state$),
    mergeMap(() => {
      const mapBridge = getMapBridge();
      return from(mapBridge.hasEmCertChanged()).pipe(
        mergeMap((hasEmCertChanged) => {
          // Initialize renewal process
          if (hasEmCertChanged) return [initCertUpdateStart()];

          // or continue with hydration of the app
          return [fetchDashboardDataStart({})];
        }),
        // errors from MAP can also be strings
        catchError((error: Error | string) => {
          // if the device is not activated then there is no EmCert.
          // However, there is no API available to check if the device is activated or not.
          // The only way for us to know is that we will get 'InternalError.DeviceNotActivated'
          // or 'PushTANAuthenticatorError.DeviceNotActivated'
          // error when we call `hasEmCertChanged`. In that case we ignore the error and
          // continue with hydrating the app.
          if (
            error === 'InternalError.DeviceNotActivated' ||
            error === 'PushTANAuthenticatorError.DeviceNotActivated'
          ) {
            return [fetchDashboardDataStart({})];
          } else
            return [
              mapDeviceAuthenticationFailure({
                error: typeof error === 'string' ? error : error.message,
              }),
            ];
        })
      );
    })
  );

export const initCertUpdateEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(initCertUpdateStart.match),
    withLatestFrom(state$),
    mergeMap(() =>
      from(initCertUpdateRequest()).pipe(
        mergeMap((payload) => [initCertUpdateSuccess(payload)]),
        catchError((error: Error) => [initCertUpdateFailure({ error: error.message })])
      )
    )
  );

// Interface with Crealogix's MAP to set
// the new certificate
export const initCertUpdateSuccessEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(initCertUpdateSuccess.match),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const certificateRenewalToken = selectCertificateRenewalToken(state);
      if (!certificateRenewalToken) throw new Error('No certificate renewal token found');
      const mapBridge = getMapBridge();
      return from(mapBridge.getEmCert(certificateRenewalToken)).pipe(
        mergeMap((payload) => {
          if (!payload)
            return [
              mapDeviceAuthenticationFailure({
                error: 'Undefined response from `mapBridge.getEmCert()`',
              }),
            ];

          return [mapGetEmCertSuccess(payload), certUpdateStart(payload.jwt)];
        }),
        // errors from MAP can also be strings
        catchError((error: Error | string) => [
          mapGetEmCertFailure({
            error: typeof error === 'string' ? error : error.message,
          }),
        ])
      );
    })
  );

export const certUpdateEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(certUpdateStart.match),
    withLatestFrom(state$),
    mergeMap(([action]) =>
      from(certUpdateRequest(action.payload)).pipe(
        mergeMap((payload) => [certUpdateSuccess(payload)]),
        catchError((error: Error) => [certUpdateFailure({ error: error.message })])
      )
    )
  );

export const certUpdateSuccessEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(certUpdateSuccess.match),
    withLatestFrom(state$),
    mergeMap(() => {
      const mapBridge = getMapBridge();
      return from(mapBridge.commitEmCertUpdate()).pipe(
        mergeMap(() => [
          mapCommitEmCertSuccess(),
          fetchDashboardDataStart({}), // hydrate app
        ]),
        // errors from MAP can also be strings
        catchError((error: Error | string) => [
          mapCommitEmCertFailure({
            error: typeof error === 'string' ? error : error.message,
          }),
        ])
      );
    })
  );
