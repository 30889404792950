import React from 'react';
import { useSelector } from 'react-redux';

import { selectIsClient } from 'features/entities/entitiesSelectors';

import { ClientContactView } from './ClientContactView';
import { ProspectContactView } from './ProspectContactView';

export const ContactView = () => {
  const isClient = useSelector(selectIsClient);

  return isClient ? <ClientContactView /> : <ProspectContactView />;
};
