import {RequestMessage} from '@map/core';

export const openExternalPageMessageTag = 'OpenExternalPageMessage';

/**
 * Request the browser to close itself. Only browsers which are allowing the close button will be closed by this message.
 */
export class OpenExternalPageMessage extends RequestMessage {
  readonly tag: string = openExternalPageMessageTag;

  constructor(url: string) {
    super();

    this.url = url;
  }

  /**
   * The url to be opened.
   */
  url: string;
}
