import React from 'react';
import { Box, IconButton, Theme, Typography } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { motion } from 'framer-motion';

import { ReactComponent as ForwardArrowIcon } from 'common/assets/forward-arrow-icon.svg';
import { ReactComponent as CloseIcon } from 'common/assets/close-icon.svg';
import { useTranslation } from 'react-i18next';
import { EASING_BEZIER } from 'app/config';
import { jbColors } from 'app/theme/jbColors';
import { getMapBridge } from 'features/map';

type Props = {
  onBack: () => void;
  onClose: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      backgroundColor: jbColors.support.royalBlue,
      top: 0,
      left: 0,
      zIndex: 2,
      textAlign: 'initial',
    },

    toolbar: {
      marginTop: theme.spacing(0.5),
      display: 'grid',
      gridTemplateColumns: '60px 1fr 60px',
      alignItems: 'center',
    },

    backIconButton: {
      gridColumn: '1 / 2',
      gridRowStart: 1,
      width: 48,
      justifySelf: 'flex-end',
    },

    backIcon: {
      transform: 'rotate(180deg)',
      justifySelf: 'flex-end',
    },

    menuTitle: {
      gridColumn: '2 / 3',
      gridRowStart: 1,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1.5,
      fontWeight: 'bold',
      width: '100%',
      textAlign: 'center',
    },

    closeIconButton: {
      gridColumn: '3 / 4',
      gridRowStart: 1,
      width: 48,
    },

    title: {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(24),
      lineHeight: 1,
    },

    entryLabel: {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
      marginTop: theme.spacing(4.5),
      '&:first-child': {
        marginTop: theme.spacing(4),
      },
    },

    entryValue: {
      marginTop: theme.spacing(1),
      fontWeight: theme.typography.fontWeightBold,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.71,
      letterSpacing: 0.2,
    },
  })
);

export const DeviceProperties = ({ onBack, onClose }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const mapBridge = getMapBridge();
  const deviceId = mapBridge.getDeviceId();
  const { appVersion } = mapBridge.getDeviceSettings();

  return (
    <motion.div
      className={classes.container}
      key="DeviceProperties"
      initial={{ x: window.innerWidth }}
      animate={{ x: 0 }}
      exit={{ x: window.innerWidth }}
      transition={{ ease: EASING_BEZIER, duration: 0.2 }}
    >
      <div className={classes.toolbar}>
        <IconButton onClick={onBack} className={classes.backIconButton} size="large">
          <ForwardArrowIcon className={classes.backIcon} />
        </IconButton>
        <Typography variant="h1" component="div" className={classes.menuTitle}>
          {t('menu.title')}
        </Typography>
        <IconButton onClick={onClose} className={classes.closeIconButton} size="large">
          <CloseIcon />
        </IconButton>
      </div>

      <Box maxWidth={472} mx="auto" mt={2.5} p={3}>
        <Typography variant="h1" className={classes.title}>
          {t('deviceProperties.title')}
        </Typography>

        <Typography variant="h2" className={classes.entryLabel}>
          {t('deviceProperties.labels.deviceId')}
        </Typography>

        <Typography className={classes.entryValue}>{deviceId}</Typography>

        <Typography variant="h2" className={classes.entryLabel}>
          {t('deviceProperties.labels.appVersion')}
        </Typography>

        <Typography className={classes.entryValue}>{appVersion}</Typography>
      </Box>
    </motion.div>
  );
};
