import { IPluginRegistry } from '../Service/IPluginRegistry';
import { initializeNativeMock } from '../NativeMock';
import { DevicePropertiesModule } from '../Device/DevicePropertiesModule';

/**
 * Provides convenience methods for developers/non-production settings.
 */
export module DeveloperModule {
  export const useDesktopBrowserDebugging = (registrar: IPluginRegistry, getConfiguration: () => string, getTranslations: () => string) => {
    registrar.prependCommitAction((provider) => {
      return new Promise((resolve, reject) => {
        if (DevicePropertiesModule.getService(provider).getPlatform() === 'Browser') {
          initializeNativeMock(getConfiguration(), getTranslations(), 'en');
        }
        resolve();
      });
    });
  };
}