import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { Box, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { motion } from 'framer-motion';

import {
  MainNavigation,
  MainNavigationScreen,
  routesToScreenMap,
  screenToRoutesMap,
} from 'features/navigation/MainNavigation';
import { HomeView } from 'features/home/HomeView';
import { ContactView } from 'features/contact/ContactView';
import { InsightsView } from 'features/insights/InsightsView';
import { OnboardingView } from 'features/onboarding/OnboardingView';
import { EASING_BEZIER } from 'app/config';
import {
  selectEbkServices,
  selectLastVisitedScreen,
  selectShouldDisplayContact,
  selectShouldDisplayInsights,
  selectShouldDisplayOnboarding,
} from 'features/entities/entitiesSelectors';
import { selectIsLastVisitedScreenRestored } from 'features/session/sessionSelectors';
import { setIsLastVisitedScreenRestored } from 'features/session/sessionSlice';
import { InternalNavigationScreens } from 'features/entities/entitiesTypes';
import { ProductTourView } from 'features/productTour/ProductTourView';
import { selectProductTourVersion } from 'features/productTour/productTourSelectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      left: 0,
      top: 0,
      bottom: 0,
      right: 0,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: theme.palette.common.white,
    },
    routes: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
  })
);

export const MainView = () => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shouldDisplayInsights = useSelector(selectShouldDisplayInsights);
  const shouldDisplayContact = useSelector(selectShouldDisplayContact);
  const shouldDisplayOnboarding = useSelector(selectShouldDisplayOnboarding);
  const productTourVersion = useSelector(selectProductTourVersion);

  // leads or Prospects do not have EBK
  const isLeadOrProspect = useSelector(selectEbkServices).length === 0;

  const isLastVisitedScreenRestored = useSelector(selectIsLastVisitedScreenRestored);
  const lastVisitedScreen = useSelector(selectLastVisitedScreen);

  // restore screen
  useEffect(() => {
    const screensWeDontRestore: string[] = [
      InternalNavigationScreens.LANDING_SCREEN,
      InternalNavigationScreens.T_AND_C_SCREEN,
    ];

    if (!isLastVisitedScreenRestored) {
      if (lastVisitedScreen && !screensWeDontRestore.includes(lastVisitedScreen)) {
        const route = screenToRoutesMap[lastVisitedScreen as MainNavigationScreen];

        // do not restore if it's current screen
        const currentRoute = `/${location.pathname.split('/')[1] ?? ''}`;
        const currentScreen = routesToScreenMap[currentRoute];

        if (route && currentScreen !== lastVisitedScreen) {
          navigate(`${route}${location.search}`);
        }
      }
      // default screen for leads/prospects
      // the rest will go to /home
      else if (shouldDisplayInsights && isLeadOrProspect) {
        navigate(`/insights${location.search}`);
      }
      dispatch(setIsLastVisitedScreenRestored(true));
    }
  }, [
    dispatch,
    isLeadOrProspect,
    isLastVisitedScreenRestored,
    lastVisitedScreen,
    navigate,
    location,
    shouldDisplayInsights,
  ]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ ease: EASING_BEZIER, duration: 0.4 }}
      className={classes.container}
    >
      <div className={classes.routes}>
        {/* Product tour */}
        {productTourVersion && <ProductTourView version={productTourVersion} />}

        <Routes>
          <Route path="/" element={<Navigate to={`/home${location.search}`} />} />

          {shouldDisplayContact && <Route path="/contact" element={<ContactView />} />}

          <Route path="/home" element={<HomeView />} />

          {shouldDisplayInsights && <Route path="/insights" element={<InsightsView />} />}

          {shouldDisplayOnboarding && <Route path="/welcome" element={<OnboardingView />} />}
        </Routes>
      </div>
      <Box>
        <MainNavigation />
      </Box>
    </motion.div>
  );
};
