import { LogLevel } from './LogLevel';
import { BaseLogger } from './BaseLogger';

/**
 * A debug logger which logs to the console and saves no output.
 */
export class DebugLogger extends BaseLogger {
  log = (message: string, level: LogLevel) => {
    console.log(level + ': => ' + message);
  }
}
