import {
  handleNotOkResponse,
  getNetworkErrorOrOriginalError,
  encodeBody,
} from 'common/utils/requestUtils';
import { PostQRCodeRequest, PostQRCodeResponse } from './paymentScannerSlice';

export async function postQRCodeRequest(url: string, params: PostQRCodeRequest): Promise<void> {
  try {
    const res = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: encodeBody(params),
    });
    handleNotOkResponse(res);
    const json = (await res.json()) as PostQRCodeResponse;
    const { rc } = json;

    switch (rc) {
      // success
      case 0:
        break;

      // invalid key
      case 1:
        console.error('[Post QR Code] invalid_binding');
        break;

      // invalid codeline
      case 2:
        console.error('[Post QR Code] invalid_codeline');
        break;

      // unhandled
      default:
        console.error('[Post QR Code] general_error');
        break;
    }
  } catch (error) {
    throw getNetworkErrorOrOriginalError(error);
  }
}
