import { ResponseBase } from './ResponseBase';

/**
 * Default response if an error occurred.
 */
export class ErrorResponse extends ResponseBase {
  constructor(public code: string) {
    super();
  }
}

export function isErrorResponse(response: ResponseBase): response is ErrorResponse {
  return typeof (response as ErrorResponse).code === 'string';
}
