import React, { ReactNode } from 'react';
import { render, RenderResult } from '@testing-library/react';
import { BrowserRouter } from 'react-router-dom';
import { createStore, AnyAction, Action, Store } from 'redux';
import { Provider } from 'react-redux';

import { i18n } from 'app/i18n/i18n';
import { rootReducer, rootInitialState } from 'app/redux/rootReducer';

interface RenderWithRedux<S = any, A extends Action = AnyAction, I extends S = any> {
  (
    ui: ReactNode,
    options: {
      store?: Store<S, A>;
      initialState?: I;
    }
  ): RenderResult;
}

export const renderWithRedux: RenderWithRedux = (
  ui: any,
  { initialState = rootInitialState, store = createStore(rootReducer, initialState) } = {}
) => {
  function Wrapper({ children }: { children?: ReactNode }) {
    return <Provider store={store}>{children}</Provider>;
  }
  return render(ui, { wrapper: Wrapper });
};

interface RenderWithReduxAndRouter<S = any, A extends Action = AnyAction, I extends S = any> {
  (
    ui: ReactNode,
    options: {
      store?: Store<S, A>;
      initialState?: I;
      route?: string;
      initialEntries?: [string];
    }
  ): RenderResult;
}

export const renderWithReduxAndRouter: RenderWithReduxAndRouter = (
  ui: any,
  {
    initialState = rootInitialState,
    store = createStore(rootReducer, initialState),
    route = '/',
    initialEntries = [route],
  } = {}
) => {
  window.history.pushState({}, 'Test page', route);

  function Wrapper({ children }: { children?: ReactNode }) {
    return (
      <Provider store={store}>
        <BrowserRouter>{children}</BrowserRouter>
      </Provider>
    );
  }
  return render(ui, { wrapper: Wrapper });
};

interface RenderWithRouter {
  (
    ui: ReactNode,
    options: {
      route?: string;
      initialEntries?: [string];
    }
  ): RenderResult;
}

export const renderWithRouter: RenderWithRouter = (
  ui: any,
  { route = '/', initialEntries = [route] } = {}
) => {
  window.history.pushState({}, 'Test page', route);

  function Wrapper({ children }: { children?: ReactNode }) {
    return <BrowserRouter>{children}</BrowserRouter>;
  }

  return render(ui, { wrapper: Wrapper });
};

// t is a method so we need to bind it
export const t = i18n.t.bind(i18n);

// re-export everything
export * from '@testing-library/react';
