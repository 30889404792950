/**
 * Legacy wrapper message which can be sent to window.postMessage to inject it into all open iFrames.
 */
export class LegacyWrapperMessage {
  /**
   * The script to inject.
   */
  script?: string;

  /**
   * Marker to identify the type.
   */
  readonly legacyInject = true;
}

export const isLegacyWrapperMessage = (message: any): message is LegacyWrapperMessage => {
  return typeof message.legacyInject === 'boolean' && typeof message.script === 'string';
};