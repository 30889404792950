import { IMessenger } from '../Messaging/IMessenger';
import { CheckNetworkMessage } from './CheckNetworkMessage';
import { CheckNetworkResponse } from './CheckNetworkResponse';
import { Symbol } from '../Core/Symbol';
import { INetworkService } from './INetworkService';
import { NetworkModule } from './NetworkModule';

/**
 * A service which retrieves information about the network.
 */
export class NetworkService implements INetworkService {
  constructor(private messenger: IMessenger) {
  }

  /**
   * Scan the QR code.
   *
   * @return the promise, on what the callbacks about the scan results are delivered.
   */
  readonly check = (): Promise<CheckNetworkResponse> => {
    return this.messenger.send(new CheckNetworkMessage());
  }

  readonly symbol: Symbol = NetworkModule.serviceSymbol;
}
