import { combineEpics } from 'redux-observable';

import * as entitiesEpics from 'features/entities/entitiesEpics';
import * as extNavigationEpics from 'features/extNavigation/extNavigationEpics';
import * as firstTimeEpics from 'features/firstTime/firstTimeEpics';
import * as menuEpics from 'features/menu/menuEpics';
import * as productTourEpics from 'features/productTour/productTourEpics';
import * as sessionEpics from 'features/session/sessionEpics';
import * as paymentScannerEpics from 'features/paymentScanner/paymentScannerEpics';

const rootEpic = combineEpics(
  ...Object.values(entitiesEpics),
  ...Object.values(extNavigationEpics),
  ...Object.values(firstTimeEpics),
  ...Object.values(menuEpics),
  ...Object.values(productTourEpics),
  ...Object.values(sessionEpics),
  ...Object.values(paymentScannerEpics)
);

export { rootEpic };
