import { useEffect, useState } from 'react';
import { Slideshow } from '@el2/product-tour';

import { i18n } from 'app/i18n/i18n';

export type ProductTourReturnType = {
  slideData?: Slideshow;
  error?: any;
};

export const useProductTour = (version: string): ProductTourReturnType => {
  const [slideData, setSlideData] = useState<Slideshow>();
  const [error, setError] = useState<any>();

  useEffect(() => {
    const loadResources = async () => {
      try {
        const productTourInfo = await import(`features/productTour/${version}`);

        // add localisations
        i18n.addResourceBundle('de', 'productTour', productTourInfo.locales.de.productTour);
        i18n.addResourceBundle('en', 'productTour', productTourInfo.locales.en.productTour);
        i18n.addResourceBundle('es', 'productTour', productTourInfo.locales.es.productTour);
        i18n.addResourceBundle('fr', 'productTour', productTourInfo.locales.fr.productTour);
        i18n.addResourceBundle('it', 'productTour', productTourInfo.locales.it.productTour);

        // get localised slide data
        const slideData = productTourInfo.getSlideData(i18n);
        setSlideData(slideData);
      } catch (err) {
        setError(err);
        console.error(err);
      }
    };

    loadResources();
  }, [version]);

  return { slideData, error };
};
