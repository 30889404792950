import { API_URL } from 'app/config';
import {
  createCredentials,
  createHeaders,
  getNetworkErrorOrOriginalError,
  handleNotOkResponse,
} from 'common/utils/requestUtils';
import { AuthenticateResponse, CertUpdateResponse, ResponseResults } from './sessionSlice';

export async function initAuthenticationRequest(): Promise<string> {
  try {
    const res = await fetch(`${API_URL}/device/initAuthentication`, {
      method: 'POST',
      headers: createHeaders(),
      credentials: createCredentials(),
    });
    handleNotOkResponse(res);
    return (await res.json()) as string;
  } catch (error) {
    throw getNetworkErrorOrOriginalError(error);
  }
}

export async function authenticateRequest(asmaToken: string): Promise<AuthenticateResponse> {
  try {
    const res = await fetch(`${API_URL}/device/authenticate`, {
      method: 'POST',
      headers: createHeaders(),
      credentials: createCredentials(),
      body: JSON.stringify(asmaToken),
    });

    handleNotOkResponse(res);
    const json = (await res.json()) as AuthenticateResponse;
    if (json.result !== ResponseResults.SUCCESS) {
      throw new Error(json.message);
    }
    return json;
  } catch (error) {
    throw getNetworkErrorOrOriginalError(error);
  }
}

export async function initCertUpdateRequest(): Promise<string> {
  try {
    const res = await fetch(`${API_URL}/device/initCertUpdate`, {
      method: 'POST',
      headers: createHeaders(),
      credentials: createCredentials(),
    });
    handleNotOkResponse(res);
    return (await res.json()) as string;
  } catch (error) {
    throw getNetworkErrorOrOriginalError(error);
  }
}

export async function certUpdateRequest(emCertToken: string): Promise<CertUpdateResponse> {
  try {
    const res = await fetch(`${API_URL}/device/certUpdate`, {
      method: 'POST',
      headers: createHeaders(),
      credentials: createCredentials(),
      body: JSON.stringify(emCertToken),
    });

    handleNotOkResponse(res);
    const json = (await res.json()) as CertUpdateResponse;
    if (json.result !== ResponseResults.SUCCESS) {
      throw new Error(json.message);
    }
    return json;
  } catch (error) {
    throw getNetworkErrorOrOriginalError(error);
  }
}
