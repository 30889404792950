import { Action, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ProductTourActivity, ServiceProvider } from 'features/entities/entitiesTypes';
import { fetchDashboardDataSuccess } from 'features/entities/entitiesSlice';
import { ErrorPayload } from 'common/types';

export type ProductTourState = {
  shouldDisplayProductTour: boolean;
  productTourVersion?: string;
  serviceToOpenOnHide?: ServiceProvider;
};
const initialState: ProductTourState = {
  shouldDisplayProductTour: false,
};

export const productTour = createSlice({
  name: 'productTour',
  initialState,
  reducers: {
    displayProductTour: (state, action: PayloadAction<ServiceProvider>) => {
      state.shouldDisplayProductTour = true;
      state.serviceToOpenOnHide = action.payload;
    },
    hideProductTour: (state, action: Action) => {
      state.shouldDisplayProductTour = false;
      state.serviceToOpenOnHide = undefined;
      // clear productTourVersion so we don't display it again
      state.productTourVersion = undefined;
    },
    updateProductTourViewedStart: (state, action: PayloadAction<ProductTourActivity>) => {},
    updateProductTourViewedSuccess: (state, action: Action) => {},
    // we ignore errors that happen when we try to save the product tour viewed state
    updateProductTourViewedFailure: (state, action: PayloadAction<ErrorPayload>) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDashboardDataSuccess, (state, action) => {
      state.productTourVersion = action.payload.productTourVersion;
    });
  },
});

export const {
  displayProductTour,
  hideProductTour,
  updateProductTourViewedFailure,
  updateProductTourViewedStart,
  updateProductTourViewedSuccess,
} = productTour.actions;

export const productTourReducer = productTour.reducer;

export { initialState as productTourInitialState };
