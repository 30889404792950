import { Platform } from '@map/core';
import { EnumLiteralsOf } from 'common/types';

export type DeviceType = EnumLiteralsOf<typeof DeviceTypes>;

// adapted from https://gist.github.com/kyranjamie/646386d5edc174e8b549111572897f81
export const DeviceTypes = Object.freeze({
  MOBILE: 'MOBILE',
  TABLET: 'TABLET',
} as const);

export type ServiceCode = EnumLiteralsOf<typeof ServiceCodes>;

// adapted from https://gist.github.com/kyranjamie/646386d5edc174e8b549111572897f81
export const ServiceCodes = Object.freeze({
  // E-Services tab shows link to E-Banking Hong Kong
  DIG_HK: 'DIG_HK',
  // E-Services tab shows link to E-Banking Singapore
  DIG_SG: 'DIG_SG',
  // E-Services tab shows link to legacy R19 E-Banking Hong Kong
  DIG_HK_LEGACY: 'DIG_HK_LEGACY',
  // E-Services tab shows link to legacy R19 E-Banking Singapore
  DIG_SG_LEGACY: 'DIG_SG_LEGACY',
  // E-Services tab shows link to E-Banking Switzerland
  EBK: 'EBK',
  // E-Services tab shows R20 link to E-Banking Switzerland
  EBK_R20: 'EBK_R20',
  // E-Services tab shows link to Payment Scanner of E-Banking Switzerland
  EBK_PAYMENT: 'EBK_PAYMENT',
  // E-Services tab shows link to E-Banking Luxembourg
  EL2_LU: 'EL2_LU',
  // E-Services tab shows link to E-Banking Monaco
  EL2_MC: 'EL2_MC',
  // E-Services tab shows link to new E-Banking Switzerland
  EBP_CH: 'EBP_CH',
  // E-Services tab shows link to new E-Banking Guernsey
  EBG_GG: 'EBG_GG',
  // E-Services tab shows link to E-Services Luxembourg
  ESE_LU: 'ESE_LU',
  // E-Services tab shows link to E-Services Monaco
  ESE_MC: 'ESE_MC',
  // IDP to manage face-id/fingerprint
  IDP_BIO: 'IDP_BIO',
  // IDP to manage bounded contracts
  IDP_BOUND_CONTRACTS: 'IDP_BOUND_CONTRACTS',
  // IDP to register E-Service
  IDP_REG_ESERV: 'IDP_REG_ESERV',
  // IDP for member zone prospect activation
  IDP_REG_MEM_PROSPECT: 'IDP_REG_MEM_PROSPECT',
  // IDP for  member zone trial activation
  IDP_REG_MEM_TRIAL: 'IDP_REG_MEM_TRIAL',
  // E-Services tab shows link to E-Services India (India Online 2.0)
  INO2: 'INO2',
  // JB-WEBSITE = Julius Baer website
  JB_WEBSITE: 'JB_WEBSITE',
  // E-Services tab shows link to JB Market Link (aka. Market Access)
  JMA: 'JMA',
  // E-Services tab shows link to KOSMOS services (Germany)
  KOS: 'KOS',
  // E-Services tile shows link to APAC Know Your Money app (aka BetaApps)
  LSB_APAC_MOBILE: 'LSB_APAC_MOBILE',
  // Insights tab links to the Member Zone landing page
  MEM: 'MEM',
  // Settings tab shows link to change own password
  SS1_SSP: 'SS1_SSP',
  // Markets Toolbox
  TOO: 'TOO',
} as const);

/**
 * Contains relevant information about the service provider that are to be shown to the user on the dashboard.
 */
export type ServiceProvider = {
  /**
   * The service provider code to be used to send to backend to store last invoked service.
   */
  code: ServiceCode;

  /**
   * The service provider name to be used to display on the service link.
   */
  name: string;

  /**
   * An additional more descriptive name for the service provider to be used to display on the service link.
   */
  subname?: string;

  /**
   * The URL to the service provider.
   */
  url: string;
};

/**
 * Contains the terms and conditions of the app in the corresponding language.
 */
type Terms = {
  /**
   *  The actual text of the terms and conditions that should be shown to the app user for confirmation.
   */
  text: string;

  /**
   *  The ID of the terms that the user needs to confirm before being able to use the app. The ID is unique for a particular version of the terms and language and is used to post back the user confirmation which is stored in the backend for audit purposes.
   */
  termsId: string;
};

/**
 * Contains information about a country's name and ISO code.
 */
type Country = {
  /**
   * The country code according to ISO 3361 alpha-2.
   */
  code: string;

  /**
   * The country name in the corresponding language as should be shown to the user.
   */
  name: string;
};

/**
 * Contains the data for the domicile selection screen.
 */
type DomicileSelection = {
  /**
   * List of countries that are available for domicile selection on that device
   */
  countries: Country[];
};

export type ServiceInfoGroupType = EnumLiteralsOf<typeof ServiceInfoGroupTypes>;

export const ServiceInfoGroupTypes = Object.freeze({
  // Contacts for BOI, Markets and Epic
  BOI: 'BOI',
  // Contacts for E-Banking Switzerland
  EBK: 'EBK',
  // Contacts for E-Banking Asia (Singapore & Hong Kong)
  EBK_ASIA: 'EBK_ASIA',
  // Contacts for E-Banking Europe
  EL2_LU: 'EL2_LU',
  // Contacts for E-Banking Monaco
  EL2_MC: 'EL2_MC',
  // Contacts for new E-Banking Switzerland
  EBP_CH: 'EBP_CH',
  // Contacts for E-Banking Guernsey
  EBG_GG: 'EBG_GG',
  // Contacts for E-Services Europe
  ESE_LU: 'ESE_LU',
  // Contacts for E-Services Monaco
  ESE_MC: 'ESE_MC',
  // General contact details
  GENERAL: 'GENERAL',
  // Contacts for India Online 2.0
  INO2: 'INO2',
  // Contacts for Market Link
  JMA: 'JMA',
  // Contacts for KOSMOS (Germany)
  KOS: 'KOS',
  // Contacts for APAC Know Your Money app (aka BetaApps)
  LSB_APAC_MOBILE: 'LSB_APAC_MOBILE',
} as const);

export type SupportNumberName = EnumLiteralsOf<typeof SupportNumberNames>;

export const SupportNumberNames = Object.freeze({
  // Toll-free number
  TOLL_FREE: 'TOLL_FREE',
  // Chargeable number
  CHARGEABLE: 'CHARGEABLE',
} as const);

/**
 * Contains information about a support number.
 */
export type ServiceInfoEntry = {
  /**
   * The name of the contact number group that is to be shown to the user. Several numbers can belong to one group.
   */
  group: ServiceInfoGroupType;

  /**
   * The name of the contact number that should be shown to the user.
   */
  name: SupportNumberName;

  /**
   * The telephone number of that contact.
   */
  number: string;

  /**
   * Localised string of the opening hours for this number.
   * Could contain new-line characters.
   */
  openingHours?: string;

  /**
   * Localised URL of imprint.
   */
  imprintUrl?: string;

  /**
   * Localised URL of privacy policy.
   */
  privacyPolicyUrl?: string;
};

export type ServiceInfoGroup = {
  groupType: ServiceInfoGroupType;
  openingHours?: string;
  imprintUrl?: string;
  privacyPolicyUrl?: string;
  serviceInfo: Pick<ServiceInfoEntry, 'name' | 'number'>[];
};

/**
 * 	Contains information about a relationship manager contact details.
 */
type RelationshipManagerContact = {
  /**
   * The name of the relationship manager that is to be shown to the user.
   */
  name: string;

  /**
   * The email adress of the relationship manager.
   */
  email: string;

  /**
   * The office telephone number of the relationship manager.
   */
  phone: string;

  /**
   * The mobile telephone number of the relationship manager.
   */
  mobile: string;
};

/**
 * 	Contains information for the contact screen that is relevant for that device.
 */
type ContactDetails = {
  /**
   * List of general support number details that are relevant for that device.
   */
  serviceInfo?: ServiceInfoEntry[];

  /**
   * List of relevant relationship manager contact details for that device.
   */
  relationshipManagers?: RelationshipManagerContact[];
};

export type UserType = EnumLiteralsOf<typeof UserTypes>;

export const UserTypes = Object.freeze({
  PROSPECT: 'PROSPECT',
  LEAD: 'LEAD',
} as const);

/**
 * 	Contains information for the contact screen that is relevant for that device.
 */
type OnboardingDetails = {
  /**
   * Contains the user type. Currently `PROSPECT` or `LEAD`.
   */
  userType: UserType;
};

export type DashboardData = {
  /**
   * The minimum version for that device that the app should have to operate properly. Otherwise the frontend should redirect to the corresponding app store location.
   */
  minRequiredVersion: string;

  /**
   * The last e-banking service the user has accessed. May be not set if the user has not clicked on any service yet.
   */
  lastAccessedEBankingService?: ServiceCode;

  /**
   * The last screen that the user viewed within the Dashboard.
   */
  lastVisitedScreen?: InternalNavigationScreen;

  /**
   * The product tour version to show on the current device
   */
  productTourVersion?: string;

  molTerms?: Terms;
  molLanding?: boolean;
  molDomicile?: DomicileSelection;
  molContacts?: ContactDetails;
  molOnboarding?: OnboardingDetails;
  molInsights?: boolean;
  molInsightsNotificationCount?: number;
  services?: ServiceProvider[];
};

export type FirstTimeFeatures = Extract<
  keyof DashboardData,
  'molDomicile' | 'molLanding' | 'molTerms'
>;

// order by screen order
export const orderedFirstTimeFeatures: FirstTimeFeatures[] = [
  'molDomicile',
  'molLanding',
  'molTerms',
];

export type DeviceSettings = {
  /**
   * The country code (ISO 3361 alpha-2) of the user's main domicile as selected in the JB Mobile App.
   */
  selectedDomicile?: string;

  /**
   * 	States if the terms have been accepted.
   */
  isTermsAccepted?: boolean;

  /**
   * States if the keystore/keychain has an entry that MemberZone service has been activated on that device before.
   */
  isMemServiceActivated?: boolean;

  /**
   * The language two-letter code (ISO 639-1) as set on the device operating system.
   */
  language: string;

  /**
   * The device's operating system type.
   */
  osType: Platform;

  /**
   * The device make.
   */
  deviceMake?: string;

  /**
   * The device model.
   */
  deviceModel: string;

  /**
   * The type of the device, e.g. MOBILE, TABLET
   */
  deviceType: DeviceType;

  /**
   * The version of the JB Mobile App that is installed on the device.
   */
  appVersion: string;

  /**
   *  States if the service providers should be fetched from the IDM, instead of using the last cached response. Useful after a new service registration/update.
   */
  updateServiceProviders?: boolean;

  /**
   * Navigator user agent string
   */
  userAgent: string;
};

export type ActivityType = EnumLiteralsOf<typeof ActivityTypes>;

export const ActivityTypes = Object.freeze({
  /**
   * Switching screens/views within the Mobile Dashboard.
   */
  INTERNAL_NAVIGATION: 'INTERNAL_NAVIGATION',

  /**
   * Clicking on a link of an external service provider.
   */
  EXTERNAL_NAVIGATION: 'EXTERNAL_NAVIGATION',

  /**
   * User accepts the terms and conditions of the app.
   */
  TERMS_CONFIRMATION: 'TERMS_CONFIRMATION',

  /**
   * User selects the domicile.
   */
  DOMICILE_SELECTION: 'DOMICILE_SELECTION',

  /**
   * User completes a product tour.
   */
  PRODUCT_TOUR: 'PRODUCT_TOUR',

  /**
   * Something relevant happened in the SPA that should be logged in the backend.
   */
  LOG: 'LOG',
} as const);

export type LogSeverity = EnumLiteralsOf<typeof LogSeverities>;

const LogSeverities = Object.freeze({
  ERROR: 'ERROR',
  WARNING: 'WARNING',
  INFO: 'INFO',
} as const);

/**
 * Contains information about device settings.
 */
export type LogDetails = {
  /**
   * Holds a unique id of the type of error. Primary purpose of this code is to allow the calling application to differentiate between error types (e.g. the error requires different presentation to the business user).
   */
  code: string;

  /**
   * Technical description of the error/remark to be used in log statements. This message is not intend to use in messages shown in a GUI.
   */
  message: string;

  /**
   * Indicates the severity of the occurred error/remark..
   */
  severity: LogSeverity;

  /**
   * List of parameters to more precisely describe the error. Based on the 'code' the calling application "knows" how to present the parameters in log files or on the screen.
   */
  parameters?: any[];
};

/**
 * 	Contains information about an activity that occured on the Mobile Dashboard frontend.
 */
export type Activity = {
  activityType: ActivityType;

  /**
   * 	The name of the internal navigation screen, e.g., Contact tab, Landing screen, etc. in case activityType is INTERNAL_NAVIGATION.
   */
  screenName?: InternalNavigationScreen;

  /**
   * The service provider code in case activityType is EXTERNAL_NAVIGATION
   */
  serviceCode?: ServiceCode;

  /**
   * The URL of the service provider in case activityType is EXTERNAL_NAVIGATION
   */
  serviceUrl?: string;

  /**
   * The ID of the terms that the user has accepted in case activityType is TERMS_CONFIRMATION
   */
  acceptedTermsId?: string;

  /**
   * The country code (ISO 3361 alpha-2) that the user has selected in case activityType is DOMICILE_SELECTION
   */
  selectedDomicile?: string;

  /**
   * The version of the product tour that has been viewed is case activityType is PRODUCT_TOUR
   */
  productTourVersion?: string;

  logDetails?: LogDetails;
};

export type AcceptTAndCActivity = {
  activityType: Extract<ActivityType, 'TERMS_CONFIRMATION'>;
  acceptedTermsId: string;
};

export type DomicileActivity = {
  activityType: Extract<ActivityType, 'DOMICILE_SELECTION'>;
  selectedDomicile: string;
};

export type ProductTourActivity = {
  activityType: Extract<ActivityType, 'PRODUCT_TOUR'>;
  productTourVersion: string;
};

export type InternalNavigationScreen = EnumLiteralsOf<typeof InternalNavigationScreens>;

export const InternalNavigationScreens = Object.freeze({
  CONTACT_SCREEN: 'CONTACT_SCREEN',
  DOMICILE_SELECTION_SCREEN: 'DOMICILE_SELECTION_SCREEN',
  HOME_SCREEN: 'HOME_SCREEN',
  INSIGHTS_SCREEN: 'INSIGHTS_SCREEN',
  ONBOARDING_SCREEN: 'ONBOARDING_SCREEN',
  T_AND_C_SCREEN: 'T_AND_C_SCREEN',
  LANDING_SCREEN: 'LANDING_SCREEN',
} as const);

export const OperationResultTypes = Object.freeze({
  SUCCESS: 'SUCCESS',
  FAILED: 'FAILED',
} as const);

export type OperationResult = {
  result: OperationResultType;
  message?: string;
};

export type EntitiesState = {
  dashboardData?: DashboardData;
  deviceSettings?: DeviceSettings;
  isFetching: boolean;
  error?: string;
};

export type FetchDashboardDataStartPayload = {
  shouldUpdateServiceProviders?: boolean;
};

export type OperationResultType = EnumLiteralsOf<typeof OperationResultTypes>;

// version 3.4.99 of @map/biometric-storage defines a numeric enum
// for SupportedBiometricMethod while the actual returned value is
// a string. So we need to define the actual type and cast
// related objects to it
export const ActualSupportedBiometricMethods = Object.freeze({
  None: 'None',
  TouchId: 'TouchId',
  FaceId: 'FaceId',
} as const);

export type ActualSupportedBiometricMethod = EnumLiteralsOf<typeof ActualSupportedBiometricMethods>;
