import {RequestMessage} from '@map/core';

export const openPageMessageTag = 'OpenPageMessage';

/**
 * Request the browser to close itself. Only browsers which are allowing the close button will be closed by this message.
 */
export class OpenPageMessage extends RequestMessage {
  readonly tag: string = openPageMessageTag;

  constructor(url: string) {
    super();

    this.url = url;
  }

  /**
   * The url to be opened.
   */
  url: string;
}
