import { ResponseBase } from '../Messaging/ResponseBase';

export class OperationResponse extends ResponseBase {
  constructor(public success: boolean) {
    super();
  }
}

export class ValueResponse<TValue> extends ResponseBase {
  constructor(public value: TValue) {
    super();
  }
}

export class EmptyResponse extends ResponseBase {
}