import { RequestMessage } from '../Messaging/RequestMessage';

export const changeConfigurationMessageTag = 'ChangeConfigurationMessage';

/**
 * Request the native part to change a part of the dynamic configuration.
 */
export class ChangeConfigurationMessage extends RequestMessage {
  readonly tag: string = changeConfigurationMessageTag;

  /**
   * The node to change.
   */
  //noinspection JSUnusedGlobalSymbols
  nodeName!: string;

  /**
   * The content to override the node with.
   */
  //noinspection JSUnusedGlobalSymbols
  content!: string;
}
