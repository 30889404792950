import { Symbol } from '../Core/Symbol';
import { ILegacyService, LegacyHandler } from './ILegacyService';
import { IPluginProvider } from '../Service/IPluginProvider';
import {extract, parse} from 'query-string';
import { LegacyModule } from './LegacyModule';

export class LegacyService implements ILegacyService {
  readonly symbol: Symbol = LegacyModule.serviceSymbol;

  constructor(private provider: IPluginProvider) {
  }

  /**
   * Register a new handler. The target url is checked with contains.
   *
   * @param legacyAction - the action to invoke.
   */
  readonly registerHandler = (handler: LegacyHandler) => {
    this.handlers.push(handler);
  }

  /**
   * Calls the given URL, triggering associated handlers.
   *
   * @param url - the url to handle.
   */
  readonly call = (url: string) => {
    if (!url) {
      return;
    }
    let searchParams = parse(extract(url));
    if (url.indexOf('navigation/openWebPage?') >= 0) {
      const arrUrl = url.split('navigation/openWebPage?');
      searchParams = parse(arrUrl[1]);
    }
    let handled = false;

    for (const handler of this.handlers) {
      if (url.indexOf(handler.targetUrl) >= 0) {
        handler.action(this.provider, searchParams);
        handled = true;
        break;
      }
    }

    if (!handled) {
      throw new Error('Legacy action '.concat(url, ' was not handled.'));
    }
  }

  private readonly handlers: LegacyHandler[] = [];
}
