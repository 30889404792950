import { AnyAction } from '@reduxjs/toolkit';
import { Epic } from 'redux-observable';
import { catchError, filter, mergeMap, withLatestFrom } from 'rxjs/operators';

import { RootState } from 'app/redux/rootReducer';
import { getMapBridge } from 'features/map';

import {
  PostQRCodeRequestServices,
  scanCancelledOrTimedOut,
  scanConnectQRStart,
  scanConnectQRSuccess,
  scanConnectQRFailure,
  scanPaymentQRStart,
  scanPaymentQRSuccess,
  scanPaymentQRFailure,
} from './paymentScannerSlice';
import { from } from 'rxjs';
import { postQRCodeRequest } from './paymentScannerRequests';
import { selectScanKey } from './paymentScannerSelectors';
import { selectPaymentScannerService } from 'features/entities/entitiesSelectors';

export const scanConnectQREpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(scanConnectQRStart.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const mapBridge = getMapBridge();

      return from(mapBridge.scanQrCode()).pipe(
        mergeMap((qrCode) => {
          const url = selectPaymentScannerService(state)?.url;

          if (!url) {
            throw new Error(`Expected paymentScanner 'url' to exist`);
          }

          const deviceId = mapBridge.getDeviceId();
          const [withoutBillingInfo] = qrCode.split('//S1/');
          const scanKey = JSON.parse(qrCode).scanKey as string;

          const params = {
            service: PostQRCodeRequestServices.connect,
            scanKey,
            appInstallKey: deviceId,
            codeline: withoutBillingInfo,
          };

          return from(postQRCodeRequest(url, params)).pipe(
            mergeMap(() => [scanConnectQRSuccess(scanKey)])
          );
        }),
        catchError((error: Error | string) => {
          if (typeof error === 'string') {
            if (error === 'ScannerError.Canceled') {
              console.log('QR Scanning cancelled');
              return [scanCancelledOrTimedOut()];
              // Ignore timeout
            } else if (error === 'ScannerError.TimedOut') {
              console.log('QR Scanning timed out');
              return [scanCancelledOrTimedOut()];
            }
            return [scanConnectQRFailure({ error: error })];
          } else {
            return [scanConnectQRFailure({ error: error.message })];
          }
        })
      );
    })
  );

export const scanPaymentQREpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(scanPaymentQRStart.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const scanKey = selectScanKey(state);
      if (!scanKey) {
        throw new Error('Expected  QR `scanKey` to exist');
      }

      const url = selectPaymentScannerService(state)?.url;

      if (!url) {
        throw new Error(`Expected paymentScanner 'url' to exist`);
      }

      const mapBridge = getMapBridge();

      return from(mapBridge.scanQrCode()).pipe(
        mergeMap((qrCode) => {
          const deviceId = mapBridge.getDeviceId();
          const [withoutBillingInfo] = qrCode.split('//S1/');

          const params = {
            service: PostQRCodeRequestServices.savePaymentScan,
            scanKey,
            appInstallKey: deviceId,
            codeline: withoutBillingInfo,
          };

          return from(postQRCodeRequest(url, params)).pipe(
            mergeMap(() => [scanPaymentQRSuccess()])
          );
        }),
        catchError((error: Error | string) => {
          if (typeof error === 'string') {
            if (error === 'ScannerError.Canceled') {
              console.log('QR Scanning cancelled');
              return [scanCancelledOrTimedOut()];
              // Ignore timeout
            } else if (error === 'ScannerError.TimedOut') {
              console.log('QR Scanning timed out');
              return [scanCancelledOrTimedOut()];
            }
            console.log(error);
            return [scanPaymentQRFailure({ error: error })];
          } else {
            return [scanPaymentQRFailure({ error: error.message })];
          }
        })
      );
    })
  );
