import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Theme, Typography } from '@mui/material';

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

import { useTranslation } from 'react-i18next';

import { scanPaymentQRStart } from './paymentScannerSlice';
import { selectNumScans } from './paymentScannerSelectors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontStyle: 'normal',
      fontWeight: theme.typography.fontWeightLight,
      fontSize: 24,
      lineHeight: 1.17,
      letterSpacing: '-0.2px',
    },

    instructions: {
      marginTop: theme.spacing(4),
    },

    numScans: {
      marginTop: theme.spacing(3),
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.43,
    },

    button: {
      marginTop: theme.spacing(8),
      color: theme.palette.common.white,
      borderColor: theme.palette.common.white,
      minWidth: theme.spacing(8),
    },
  })
);

export const PaymentScannerScanPayment = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const numScans = useSelector(selectNumScans);

  const handleScanQRCode = () => {
    dispatch(scanPaymentQRStart());
  };

  return (
    <Box maxWidth={472} mx="auto" mt={2.5} p={3}>
      <Typography variant="h1" className={classes.title}>
        {t('paymentScanner.title')}
      </Typography>

      <Typography variant="body1" className={classes.instructions}>
        {t('paymentScanner.labels.tapScanPayment', {
          buttonName: t('paymentScanner.actions.scanPayment'),
        })}
      </Typography>

      <Button variant="outlined" className={classes.button} onClick={handleScanQRCode}>
        {t('paymentScanner.actions.scanPayment')}
      </Button>

      {numScans > 0 && (
        <Typography variant="body1" className={classes.numScans}>
          <span>{t('paymentScanner.labels.numberOfScans')}</span> <strong>{numScans}</strong>
        </Typography>
      )}
    </Box>
  );
};
