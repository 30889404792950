import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MenuGroup, simpleMenuGroups } from './menuGroups';

type MenuState = {
  settingsGroups: MenuGroup[];
  servicesGroups?: MenuGroup[];
};

const initialState: MenuState = {
  settingsGroups: simpleMenuGroups(),
};

export const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    menuGroupsCreated: (state, action: PayloadAction<MenuState>) => {
      const { settingsGroups, servicesGroups } = action.payload;
      state.settingsGroups = settingsGroups;
      state.servicesGroups = servicesGroups;
    },
  },
});

export const { menuGroupsCreated } = menu.actions;

export const menuReducer = menu.reducer;

export { initialState as menuInitialState };
