import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Theme, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { EASING_BEZIER } from 'app/config';

import { ReactComponent as JBLogo } from 'common/assets/jb-logo.svg';

const SHOW_LOADER_MS = 1200;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      width: '100vw',
      height: '100%',
      left: 0,
      top: 0,
      display: 'grid',
      gridTemplateRows: '1fr 40px 1fr',
      overflow: 'hidden',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
    },

    loaderContainer: {
      gridRow: '3 / 4',
      gridColumnStart: 1,
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: theme.spacing(4),
      alignItems: 'center',
    },

    logoContainer: {
      gridRow: '2 / 3',
      gridColumnStart: 1,
      textAlign: 'center',
    },

    logo: {
      height: 40,
      width: 'auto',
    },
  })
);

export const SplashScreen = () => {
  const classes = useStyles();

  const [shouldDisplayLoader, setShouldDisplayLoader] = useState(false);

  // Splash screen should be displayed for at least MIN_SPLASH_MS
  useEffect(() => {
    const timeoutId = setTimeout(() => setShouldDisplayLoader(true), SHOW_LOADER_MS);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 1, transition: { ease: EASING_BEZIER, duration: 0.9 } }}
      transition={{ ease: EASING_BEZIER, duration: 0.4 }}
      className={classes.container}
    >
      <div className={classes.logoContainer}>
        <JBLogo title="Julius Bär logo" className={classes.logo} />
      </div>

      {shouldDisplayLoader && (
        <div className={classes.loaderContainer}>
          <CircularProgress color="secondary" size={24} />
        </div>
      )}
    </motion.div>
  );
};
