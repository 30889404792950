import {RequestMessage} from '@map/core';

export const closeBrowserMessageTag = 'CloseBrowserMessage';

/**
 * Request the browser to close itself. Only browsers which are allowing the close button will be closed by this message.
 */
export class CloseBrowserMessage extends RequestMessage {
  readonly tag: string = closeBrowserMessageTag;
}
