import React, { useState } from 'react';
import { IconButton } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { MenuDialog } from 'features/menu/MenuDialog';

import { ReactComponent as JBLogo } from 'common/assets/jb-logo.svg';

import { ReactComponent as MenuIcon } from './menu-icon.svg';

const useStyles = makeStyles((theme) =>
  createStyles({
    toolbar: {
      display: 'grid',
      gridTemplateColumns: '60px 1fr 60px',
      paddingTop: theme.spacing(0.75),
      '@media (min-width: 481px)': {
        gridTemplateColumns: '66px 1fr 66px',
        paddingTop: theme.spacing(3.5),
      },
    },

    logoContainer: {
      gridColumn: '2 / 3',
      gridRowStart: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },

    logo: {
      display: 'block',
      height: 20,
      width: 'auto',
      '@media (min-width: 481px)': {
        height: 24,
      },
      color: 'inherit',
    },

    menuButton: {
      gridColumn: '3 / 4',
      gridRowStart: 1,
      width: 48,
      color: 'inherit',
    },
  })
);

export const Toolbar = () => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <>
      <div className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <JBLogo className={classes.logo} />
        </div>

        <IconButton className={classes.menuButton} onClick={() => setIsMenuOpen(true)} size="large">
          <MenuIcon />
        </IconButton>
      </div>
      <MenuDialog open={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
    </>
  );
};
