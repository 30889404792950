import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ErrorState {
  error?: string;
}

const initialState: ErrorState = {};

export const errors = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder.addDefaultCase((state, action) => {
      state.error = action.payload?.error;
    }),
});

export const { setError } = errors.actions;

export const errorsReducer = errors.reducer;

export { initialState as errorsInitialState };
