import { Symbol, MessengerModule, ConfigurationModule, IPluginProvider, IPluginRegistry } from '@map/core';
import { QRScannerService } from './QRScannerService';
import { IQRScannerService } from './IQRScannerService';

/**
 * Defines the API with which you can include and obtain a QR-code scanner.
 */
export module QRScannerModule {
  /**
   * The error codes that can occur in the service.
   */
  export module Errors {
    const prefix = 'ScannerError.';

    /**
     * The user or device canceled the operation.
     */
    export const canceled = prefix + 'Canceled';

    /**
     * The operation timed out before completion.
     */
    export const timedOut = prefix + 'TimedOut';

    /**
     * There is already another operation active. Only one scan at a time is supported.
     */
    export const alreadyActive = prefix + 'AlreadyActive';

    /**
     * The device does not support scanning.
     */
    export const unsupportedDevice = prefix + 'UnsupportedDevice';

    /**
     * An unknown/unexpected error occurred.
     */
    export const unknown = prefix + 'Unknown';
  }

  /**
   * The symbol that uniquely identifies this service.
   */
  export const serviceSymbol = new Symbol('QRScannerService');

  /**
   * The tag used to register a listener for scan requests.
   */
  export const scanRequestMessageTag = 'QRScanRequestMessage';

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): IQRScannerService => {
    return provider.resolve<IQRScannerService>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);
    ConfigurationModule.use(registrar);

    registrar.registerSingle(
      serviceSymbol,
      (p) => new QRScannerService(MessengerModule.getService(p)),
      MessengerModule.serviceSymbol,
      ConfigurationModule.serviceSymbol
    );
  };
}
