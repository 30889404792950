import { BroadcastMessage } from '@map/core';
import { BrowserModule } from './BrowserModule';
import { ScreenRotation } from './ScreenRotation';

/**
 * Request the device to only allow specific rotations.
 */
export class ConfigureRotationMessage extends BroadcastMessage {
  constructor(public rotation: ScreenRotation) {
    super();
  }

  readonly tag: string = BrowserModule.configureRotationMessage;
}
