import React from 'react';

import { FullScreenDialog } from 'common/FullscreenDialog';
import { Menu } from './Menu';

type Props = {
  open?: boolean;
  onClose: () => void;
};

export const MenuDialog = ({ open = false, onClose }: Props) => {
  return (
    <FullScreenDialog open={open}>
      <Menu onClose={onClose} />
    </FullScreenDialog>
  );
};
