(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@map/core"));
	else if(typeof define === 'function' && define.amd)
		define(["@map/core"], factory);
	else if(typeof exports === 'object')
		exports["MapQrScanner"] = factory(require("@map/core"));
	else
		root["MapQrScanner"] = factory(root["@map/core"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__1__) {
return 