import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CssBaseline, ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';

import 'app/i18n/i18n';
import { theme } from 'app/theme';

import {
  selectDeviceSettings,
  selectShouldDisplayUpdateScreen,
} from 'features/session/sessionSelectors';
import { fetchDeviceSettingsStart } from 'features/session/sessionSlice';
import { selectError } from 'features/errors/errorsSelectors';
import { ErrorScreen } from 'features/errors/ErrorScreen';
import { MainViewOrchestrator } from 'features/mainViewOrchestration/MainViewOrchestrator';
import { getMapBridge } from 'features/map';
import { UpdateScreen } from 'features/update/UpdateScreen';
import { ErrorBoundary } from 'features/errors/ErrorBoundary';
import { setError } from 'features/errors/errorsSlice';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export const App = () => {
  const deviceSettings = useSelector(selectDeviceSettings);
  const dispatch = useDispatch();
  const error = useSelector(selectError);
  const shouldDisplayUpdateScreen = useSelector(selectShouldDisplayUpdateScreen);

  useEffect(() => {
    const fetchDeviceSettings = async () => {
      try {
        const mapBridge = getMapBridge();
        await mapBridge.ready();
        await mapBridge.ensureMemberZoneConfiguration();
        dispatch(fetchDeviceSettingsStart());
      } catch (error: any) {
        const errorMessage =
          typeof error === 'string'
            ? error
            : (typeof 'message') in error && error.message === 'string'
              ? (error.message as string)
              : 'Unknown error';
        dispatch(setError(errorMessage));
      }
    };

    //kick start app by fetching the device settings
    if (!deviceSettings) {
      fetchDeviceSettings();
    }
  }, [dispatch, deviceSettings]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box height="100vh" display="flex" flexDirection="column">
          <ErrorBoundary>
            {error ? (
              <ErrorScreen />
            ) : shouldDisplayUpdateScreen ? (
              <UpdateScreen />
            ) : (
              <Router>
                <MainViewOrchestrator />
              </Router>
            )}
          </ErrorBoundary>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
