import React from 'react';
import { motion } from 'framer-motion';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button, alpha, Typography } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

import { Toolbar } from 'features/toolbar/Toolbar';
import {
  selectGeneralContactNumber,
  selectOnboardingUserType,
} from 'features/entities/entitiesSelectors';
import { UserTypes } from 'features/entities/entitiesTypes';
import { contactFormLinks } from 'app/config';
import { jbColors } from 'app/theme/jbColors';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      flex: 1,
      backgroundColor: theme.palette.common.white,
    },

    toolbarContainer: {
      flex: '0 0 auto',
      color: jbColors.core.reflexBlue,
    },
    content: {
      marginTop: theme.spacing(2.5),
      padding: theme.spacing(3),
    },

    title: {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(20),
      lineHeight: 1.4,
      letterSpacing: -0.2,
    },

    list: {
      counterReset: 'onboardingStep',
      marginTop: theme.spacing(5),
      marginLeft: theme.spacing(6.5),
      padding: 0,
      listStyle: 'none',
    },

    item: {
      position: 'relative',
      marginTop: theme.spacing(1),
      '&::before': {
        fontWeight: 900,
        fontSize: theme.typography.pxToRem(28),
        lineHeight: 1,
        position: 'absolute',
        left: theme.spacing(-6.5),
        top: -2,
        counterIncrement: 'onboardingStep',
        content: '"0"counter(onboardingStep)',
        color: jbColors.core.royalBlue30,
      },
      '&:last-child $stepDescription': {
        borderLeft: 'none',
        minHeight: theme.spacing(8),
      },
    },

    stepTitle: {
      fontWeight: 900,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.37,
      letterSpacing: -0.002,
    },

    stepDescription: {
      fontWeight: theme.typography.fontWeightLight,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.57,
      letterSpacing: -0.2,
      minHeight: theme.spacing(10),
      borderLeft: `1px solid ${jbColors.core.royalBlue30}`,
      paddingLeft: theme.spacing(4),
      marginLeft: theme.spacing(-4),
      marginTop: theme.spacing(1),
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
        borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
      },
    },

    buttonContainer: {
      marginTop: theme.spacing(6),
      textAlign: 'center',
    },

    button: {
      color: jbColors.core.reflexBlue,
      border: `1px solid ${alpha(jbColors.core.reflexBlue, 0.5)}`,
      '&:hover': {
        border: `1px solid ${jbColors.core.reflexBlue}`,
      },
    },
  })
);

const listVariants = {
  open: {
    transition: { staggerChildren: 0.35, delayChildren: 0.3 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const variants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1, velocity: 100 },
    },
  },
  closed: {
    y: 20,
    opacity: 0,
    transition: {
      y: { stiffness: 1 },
    },
  },
};

export const OnboardingView = () => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const formLink = contactFormLinks[i18n.language] || contactFormLinks.en;

  const userType = useSelector(selectOnboardingUserType);
  const contactNumber = useSelector(selectGeneralContactNumber);

  return (
    <div className={classes.container}>
      <div className={classes.toolbarContainer}>
        <Toolbar />
      </div>

      <div className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {t('onboarding.title')}
        </Typography>

        <motion.div variants={listVariants} initial={'closed'} animate="open">
          <ol className={classes.list}>
            <motion.li className={classes.item} variants={variants}>
              <div className={classes.stepTitle}>{t('onboarding.steps.one.title')}</div>
              {userType === UserTypes.LEAD ? (
                <Trans
                  parent="div"
                  className={classes.stepDescription}
                  i18nKey="onboarding.steps.one.lead.description"
                  values={{ formLink, contactNumber }}
                  // if we don't eslint-disable the next line it
                  // will complain that <a /> is missing the href property
                  // it is defined in the translation files though
                  // eslint-disable-next-line
                  components={{ anchor: <a /> }}
                />
              ) : (
                <div className={classes.stepDescription}>
                  {t('onboarding.steps.one.prospect.description')}
                </div>
              )}
            </motion.li>
            <motion.li className={classes.item} variants={variants}>
              <div className={classes.stepTitle}>{t('onboarding.steps.two.title')}</div>
              <Trans
                parent="div"
                className={classes.stepDescription}
                i18nKey="onboarding.steps.two.description"
                components={{ bold: <strong /> }}
              />
            </motion.li>
            <motion.li className={classes.item} variants={variants}>
              <div className={classes.stepTitle}>{t('onboarding.steps.three.title')}</div>
              <Trans
                parent="div"
                className={classes.stepDescription}
                i18nKey="onboarding.steps.three.description"
                components={{ bold: <strong /> }}
              />
            </motion.li>
            <motion.li className={classes.item} variants={variants}>
              <div className={classes.stepTitle}>{t('onboarding.steps.four.title')}</div>
              <div className={classes.stepDescription}>
                {t('onboarding.steps.four.description')}
              </div>
            </motion.li>
          </ol>

          {userType === UserTypes.LEAD && (
            <motion.div className={classes.buttonContainer} variants={variants}>
              <Button
                className={classes.button}
                variant="outlined"
                color="primary"
                href={formLink}
                target="_blank"
              >
                {t('onboarding.actions.getInTouch')}
              </Button>
            </motion.div>
          )}
        </motion.div>
      </div>
    </div>
  );
};
