import { Symbol } from '../Core/Symbol';
import { IPluginProviderAware } from './IPluginProviderAware';
import { IPluginProvider } from './IPluginProvider';

export class PluginRegistration {
  constructor(public symbol: Symbol, public createInstance: ((p: IPluginProvider) => IPluginProviderAware) | undefined, public dependencies: PluginRegistration[]) {
  }

  readonly getInstance = (provider: IPluginProvider): IPluginProviderAware => {
    if (this.createInstance === undefined) {
      throw new Error(`Symbol [${this.symbol}] was referenced as a dependency, but never registered.`);
    }

    if (this.instance === undefined) {
      for (const dependency of this.dependencies) {
        dependency.getInstance(provider);
      }
      this.instance = this.createInstance(provider);
    }
    return this.instance;
  }

  private instance?: IPluginProviderAware;
  action: ((provider: IPluginProvider) => Promise<void>) | undefined;
}