import React from 'react';
import { useSelector } from 'react-redux';

import { selectError } from 'features/errors/errorsSelectors';
import { ConnectionErrorScreen } from './ConnectionErrorScreen';
import { GeneralErrorScreen } from './GeneralErrorScreen';

export const ErrorScreen = () => {
  const error = useSelector(selectError);

  console.error(error);

  return error === 'Network request failed' || error === 'Failed to fetch' ? (
    <ConnectionErrorScreen />
  ) : (
    <GeneralErrorScreen />
  );
};
