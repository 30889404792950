import React from 'react';
import { GeneralErrorScreen } from './GeneralErrorScreen';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

type ErrorBoundaryState = {
  hasError: boolean;
};

const initialState = { hasError: false };

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = initialState;
    this.reset = this.reset.bind(this);
  }

  reset() {
    this.setState(initialState);
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <GeneralErrorScreen onReset={this.reset} />;
    }

    return this.props.children;
  }
}
