import { Symbol } from '../Core/Symbol';
import { IPluginProvider } from './IPluginProvider';
import { IPluginProviderAware } from './IPluginProviderAware';
import { PluginRegistration } from './PluginRegistration';

export class PluginProvider implements IPluginProvider {
  constructor(private registrations: PluginRegistration[]) {
  }

  /**
   * Gets the single instance associated with the given symbol.
   *
   * @param symbol The symbol for which to retrieve an instance.
   */
  resolve = <TInstance extends IPluginProviderAware>(symbol: Symbol): TInstance => {
    for (const registration of this.registrations) {
      if (registration.symbol === symbol) {
        return registration.getInstance(this) as TInstance;
      }
    }

    throw new Error(`Symbol [${symbol}] was not registered.`);
  }
}