import { RequestMessage } from '@map/core';

export const openInternalPageMessageTag = 'OpenLocalPageMessage';

/**
 * Request the browser to close itself. Only browsers which are allowing the close button will be closed by this message.
 */
export class OpenLocalPageMessage extends RequestMessage {
  readonly tag: string = openInternalPageMessageTag;

  /**
   * Default constructor.
   * @param path - the path relative to the root of the web content directory..
   */
  constructor(path: string) {
    super();

    this.path = path;
  }

  /**
   * The url to be opened.
   */
  path: string;
}