import { ResponseBase } from '@map/core';

/**
 * Scanner response to a QRScanRequestMessage.
 */
export class QRScanResponse extends ResponseBase {
  constructor(public qrCode: string) {
    super();
  }
}
