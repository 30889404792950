import { BroadcastMessage } from '../Messaging/BroadcastMessage';
import { ConfigurationModule } from './ConfigurationModule';

/**
 * A message which is raised if the configuration on the native side changed.
 */
export class ConfigurationChangedMessage extends BroadcastMessage {
  readonly tag: string = ConfigurationModule.configurationChangedMessageTag;

  /**
   * The node name that changed.
   *
   * This can be helpful if a module needs to reload its content only if a specific node changed.
   */
  nodeName!: string;

  /**
   * The node content that changed.
   *
   * This can be helpful if a module needs to reload its content only if a specific node changed.
   */
  nodeContent!: string;
}
