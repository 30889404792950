import { jbColors } from './jbColors';

export const palette = {
  text: {
    primary: jbColors.app.greyPrimary,
  },
  primary: {
    main: jbColors.support.royalBlue,
  },
  secondary: {
    main: jbColors.common.white,
    contrastText: jbColors.common.white,
  },
  error: {
    main: jbColors.technical.carmineRed,
  },
  background: {
    default: '#FFFFFF',
  },
};
