import { Symbol } from '../Core/Symbol';
import { IPluginRegistry } from '../Service/IPluginRegistry';
import { IPluginProvider } from '../Service/IPluginProvider';
import { MessengerModule } from '../Messaging/MessengerModule';
import { ITranslationProvider } from './ITranslationProvider';
import { TranslationProvider } from './TranslationProvider';
import { TranslationMessage } from './TranslationMessage';
import { TranslationResponse } from './TranslationResponse';

/**
 * Defines the API with which you can include and obtain a translation provider.
 */
export module TranslationModule {
  /**
   * The symbol that uniquely identifies this service.
   */
  export const serviceSymbol = new Symbol('TranslationProvider');

  /**
   * The tag used to register a listener for when translations are loaded.
   */
  export const translationMessageTag = 'TranslationMessage';

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): ITranslationProvider => {
    return provider.resolve<ITranslationProvider>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);

    registrar
      .registerSingle(serviceSymbol, (p) => new TranslationProvider())
      .addCommitAction(serviceSymbol, async (provider) => {
        const messenger = MessengerModule.getService(provider);
        const translationProvider = TranslationModule.getService(provider);

        const response: TranslationResponse = await messenger.send(new TranslationMessage(), -1);
        translationProvider.set(response.translations, response.activeLanguage);
      });
  };
}