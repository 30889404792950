import { Symbol } from '../Core/Symbol';
import { IPluginRegistry } from '../Service/IPluginRegistry';
import { ILegacyService } from './ILegacyService';
import { LegacyService } from './LegacyService';
import { IPluginProvider } from '../Service/IPluginProvider';
import { isLegacyWrapperMessage } from './LegacyWrapperMessage';
import { DialogModule } from '../Dialogs/DialogModule';

/**
 * Defines the API with which you can include and obtain the legacy service.
 *
 * The legacy service is only needed as a bridging API for web applications that
 * were written against the older PortalApp API.
 */
export module LegacyModule {
  /**
   * The symbol that uniquely identifies this service.
   */
  export const serviceSymbol = new Symbol('LegacyService');

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): ILegacyService => {
    return provider.resolve<ILegacyService>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    registrar.registerSingle<ILegacyService>(LegacyModule.serviceSymbol, (provider) => new LegacyService(provider));

    registrar.addCommitAction(LegacyModule.serviceSymbol, (p) => {
      return new Promise((resolve, reject) => {
        legacyService = getService(p);

        window.addEventListener('message', onMessage);

        // TODO Register core urls which are not project-specific. maybe in an other file? Or at the specific module?

        registerLegacyNotification(getService(p));
        resolve();
      });
    });
  };

  export const removeListener = () => {
    window.removeEventListener('message', onMessage);
  };

  const onMessage = (event: any) => {
    if (isLegacyWrapperMessage(event.data)) {
      const frames = document.getElementsByTagName('iframe');

      // tslint:disable-next-line
      for (let i = 0; i < frames.length; i++) {
        frames[i].contentWindow!.postMessage(event.data, '*');
      }
    } else {
      if (typeof event.data === 'string') {
        const legacyUrl = event.data.toString();
        legacyService.call(legacyUrl);
      }
    }
  };

  const registerLegacyNotification = (service: ILegacyService) => {
    service.registerHandler({
      targetUrl: 'crealogix.com://notification/showAlert', action: (provider, queryParameters) => {
        const title: string = queryParameters['title'];
        const text: string = queryParameters['text'];

        const dialogService = DialogModule.getService(provider);
        dialogService.notify(title, text);
      }
    });
  };

  let legacyService: ILegacyService;
}