import React from 'react';
import { useSelector } from 'react-redux';

import { HomeNoEBankingActivated } from './HomeNoEBankingActivated';
import { HomeEBankingActivated } from './HomeEBankingActivated';
import { selectEbkServices } from 'features/entities/entitiesSelectors';

export const HomeView = () => {
  const ebkServices = useSelector(selectEbkServices);

  return ebkServices.length > 0 ? <HomeEBankingActivated /> : <HomeNoEBankingActivated />;
};
