import { ResponseBase } from '../Messaging/ResponseBase';

/**
 * The response to a ConfirmationMessage.
 */
export class ConfirmationResponse extends ResponseBase {
  /**
   * Get the response value.
   */
  public success!: boolean;
}
