import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from 'locales/de.json';
import en from 'locales/en.json';
import es from 'locales/es.json';
import fr from 'locales/fr.json';
import it from 'locales/it.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  // debug: true,
  fallbackLng: 'en',
  ns: ['dashboard'],
  defaultNS: 'dashboard',
  nsSeparator: '.',
  returnNull: false,
  lng: 'en',
  resources: {
    de,
    en,
    es,
    fr,
    it,
  },
});

export const supportedLanguages = ['de', 'es', 'en', 'fr', 'it'];

export const setLanguage = (lang: string) => {
  if (supportedLanguages.includes(lang)) {
    i18n.changeLanguage(lang);
  }
};

export { i18n };
