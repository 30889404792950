export const typography = {
  fontFamily: 'Verlag SSM for Julius Baer, Helvetica, Arial, sans-serif',
  body1: {
    fontWeight: 300,
  },
  body2: {
    fontWeight: 300,
  },
  button: {
    fontWeight: 900,
    fontSize: '12px',
    letterSpacing: '0.1em',
    lineHeight: 2,
  },
  h1: {
    fontSize: '28px',
    lineHeight: '36px',
  },
  h2: {
    fontSize: '22px',
    lineHeight: '28px',
  },
};
