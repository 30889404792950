import { AnyAction } from '@reduxjs/toolkit';
import { Epic } from 'redux-observable';
import { from } from 'rxjs';
import { filter, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

import { RootState } from 'app/redux/rootReducer';
import { updateDashboardActivityRequest } from 'features/entities/entitiesRequests';
import { fetchDashboardDataStart } from 'features/entities/entitiesSlice';
import {
  InternalNavigationScreens,
  ActivityTypes,
  InternalNavigationScreen,
} from 'features/entities/entitiesTypes';

import {
  updateDomicileStart,
  updateDomicileSuccess,
  updateDomicileFailure,
  updateTermsStart,
  updateTermsSuccess,
  updateTermsFailure,
  nextFTScreen,
  clearPostponedAction,
} from './firstTimeSlice';
import { selectCurrentFirstTimeScreen, selectPostponedAction } from './firstTimeSelectors';
import { Dictionary } from 'common/types';

export type FirstTimeScreen = Extract<
  InternalNavigationScreen,
  'DOMICILE_SELECTION_SCREEN' | 'T_AND_C_SCREEN' | 'LANDING_SCREEN'
>;

const screenComponentNameToScreenMap: Dictionary<FirstTimeScreen> = {
  SelectDomicile: InternalNavigationScreens.DOMICILE_SELECTION_SCREEN,
  TermsAndConditions: InternalNavigationScreens.T_AND_C_SCREEN,
  LandingScreen: InternalNavigationScreens.LANDING_SCREEN,
};

export const updateDomicileEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(updateDomicileStart.match),
    mergeMap((action) =>
      from(updateDashboardActivityRequest(action.payload)).pipe(
        mergeMap((payload) => [
          updateDomicileSuccess(),
          // we need to fetch data again as it may affect
          // the features we need to display
          fetchDashboardDataStart({}),
        ]),
        catchError((error: Error) => [updateDomicileFailure({ error: error.message })])
      )
    )
  );
// postponedActionExecution
export const updateTermsEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(updateTermsStart.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      from(updateDashboardActivityRequest(action.payload)).pipe(
        mergeMap((payload) => {
          // restore action that was postponed to sign terms and conditions
          const postponedAction = selectPostponedAction(state);
          return postponedAction
            ? [updateTermsSuccess(), postponedAction, clearPostponedAction()]
            : // or fetch data again and continue flow based on them
              [updateTermsSuccess(), fetchDashboardDataStart({})];
        }),
        catchError((error: Error) => [updateTermsFailure({ error: error.message })])
      )
    )
  );

export const nextFTScreenEpic: Epic<AnyAction, AnyAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(nextFTScreen.match),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      // we notify the backend of the screen navigation but we do not
      // track whether this request was successful
      const currentFirstTimeScreen = selectCurrentFirstTimeScreen(state);
      if (
        currentFirstTimeScreen &&
        screenComponentNameToScreenMap[currentFirstTimeScreen.screenType]
      ) {
        updateDashboardActivityRequest({
          activityType: ActivityTypes.INTERNAL_NAVIGATION,
          screenName: screenComponentNameToScreenMap[currentFirstTimeScreen.screenType],
        });
      }

      return [];
    })
  );
