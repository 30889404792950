import { ResponseBase } from '../Messaging/ResponseBase';

/**
 * Get the response to a ConfigurationMessage.
 */
export class ConfigurationResponse extends ResponseBase {
  /**
   * The configuration interface.
   */
  configuration!: string;
}
