import { Symbol } from '../Core/Symbol';
import { IMessenger } from '../Messaging/IMessenger';
import { DialogModule } from './DialogModule';
import { IDialogService } from './IDialogService';
import { ConfirmationMessage } from './ConfirmationMessage';
import { NotificationMessage } from './NotificationMessage';
import { ConfirmationResponse } from './ConfirmationResponse';
import { NotificationResponse } from './NotificationResponse';
import { ToastResponse } from './ToastResponse';
import { ToastMessage } from './ToastMessage';

/**
 * A service which can be used to send notifications to the user.
 */
export class DialogService implements IDialogService {
  constructor(private messenger: IMessenger) {
  }

  /**
   * Show a notification which can only be accepted. No callback possible.
   * @param title - the title of the message.
   * @param message - the message to display.
   */
  readonly notify = (title: string, message: string): Promise<NotificationResponse> => {
    return this.messenger.send(new NotificationMessage(title, message));
  }

  /**
   * Show a confirmation message to the user. The response can be positive or negative.
   * @param title - the title of the message.
   * @param message - the message to display.
   */
  readonly confirm = (title: string, message: string): Promise<ConfirmationResponse> => {
    return this.messenger.send(new ConfirmationMessage(title, message));
  }

  /**
   * Show a toast notification
   * @param message - the message to display.
   * @param duration - the display duration in seconds.
   */
  readonly toast = (message: string, duration: number): Promise<ToastResponse> => {
    return this.messenger.send(new ToastMessage(message, duration, false));
  }

  /**
   * Show a styled toast notification
   * @param message - the message to display.
   * @param duration - the display duration in seconds.
   */
  readonly styledToast = (message: string, duration: number): Promise<ToastResponse> => {
    return this.messenger.send(new ToastMessage(message, duration, true));
  }

  readonly symbol: Symbol = DialogModule.serviceSymbol;
}