import { Symbol, IPluginRegistry, IPluginProvider, MessengerModule } from '@map/core';
import { DeviceAuthenticator } from './DeviceAuthenticator';
import { IDeviceAuthenticator } from './IDeviceAuthenticator';

export module DeviceAuthenticatorModule {

  export module Errors {
    const prefix = 'DeviceAuthenticatorError.';

    /**
     * Raised if authentication message wasn't verified with the available public key - probably the server key changed.
     */
    export const invalidMessageSignature = prefix + 'InvalidMessageSignature';
  }

  /**
   * The symbol that uniquely identifies the ILogger.
   */
  export const serviceSymbol = new Symbol('DeviceAuthenticator');

  /**
   * The tag used to register a listener for authentication requests.
   */
  export const authenticationRequestMessageTag = 'DeviceAuthenticationMessage';

  /**
   * The tag which is used to send a message to delete all bindings.
   */
  export const deleteBindingsMessageTag = 'DeleteBindingsMessage';

  /**
   * The tag used to register a listener for activation request.
   */
  export const activationRequestMessageTag = 'DeviceActivationMessage';

  /**
   * Whether the current authenticator has a binding.
   */
  export const deviceAuthenticatorHasBindingMessageTag = 'DeviceAuthenticatorHasBindingMessage';

  /**
   * The tag used to register a listener for authentication requests.
   */
  export const serverKeyRenewMessageTag = 'ServerKeyRenewMessage';

  /**
   * The tag used to register a listener for bindings changes.
   */
  export const bindingsChangedMessageTag = 'BindingsChangedMessage';

  /**
   * Gets the service from the given provider.
   *
   * @param provider The provider to use.
   */
  export const getService = (provider: IPluginProvider): IDeviceAuthenticator => {
    return provider.resolve<IDeviceAuthenticator>(serviceSymbol);
  };

  /**
   * Applies all service-relevant registration to the given registrar.
   *
   * @param registrar The registrar to use.
   */
  export const use = (registrar: IPluginRegistry) => {
    MessengerModule.use(registrar);

    registrar.registerSingle(
      serviceSymbol,
      (p: IPluginProvider) => new DeviceAuthenticator(MessengerModule.getService(p)),
      MessengerModule.serviceSymbol
    );
  };
}